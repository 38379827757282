import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import { clearError } from '../actions/ErrorActions'

const styles = theme => ({
    li: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        listStyleType: 'none',
    },

    ul: {
        marginBottom: theme.spacing.unit * 2,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },

    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
})

class Error extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showStack: false,
        }
    }

    onClose = () => {
        this.props.clearError()
    }

    render() {
        const { classes } = this.props
        const open = this.props.errors.currentError.length > 0
        const errors = this.props.errors.currentError.map((item, index) => {
            const message = item.message.split(/\r?\n/).map((item, index) => (
                <li key={index} className={classes.li}>
                    {item}
                </li>
            ))

            let stack = ''

            if (item.stack) {
                stack = item.stack.split(/\r?\n/).map((item, index) => (
                    <li key={index} className={classes.li}>
                        {item}
                    </li>
                ))
            }

            return (
                <div key={index}>
                    <Typography>{item.type}</Typography>
                    <ul className={classes.ul}>{message}</ul>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>
                                Stack trace
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ul className={classes.ul}>{stack}</ul>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            )
        })

        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={this.onClose}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>Błąd</DialogTitle>
                    <DialogContent>{errors}</DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.onClose}>
                            Zamknij
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        errors: state.presto.errors,
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, { clearError })(Error)
)
