/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { Async as AsyncSelect } from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

const styles = theme => {
    return {
        root: {
            flexGrow: 1,
        },

        input: {
            display: 'flex',
            padding: '8px 14px',
        },

        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },

        chip: {
            margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        },

        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
                0.08
            ),
        },

        noOptionsMessage: {
            padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        },

        singleValue: {
            fontSize: 16,
        },

        placeholder: {
            position: 'absolute',
            fontSize: 16,
        },

        paper: {
            position: 'absolute',
            zIndex: 1000,
            marginTop: theme.spacing.unit,
            left: 0,
            right: 0,
        },

        divider: {
            height: theme.spacing.unit * 2,
        },
    }
}

const styles2 = theme => {
    return {
        root: {
            flexGrow: 1,
        },

        input: {
            display: 'flex',
            padding: '10px 14px',
        },

        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },

        chip: {
            margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
        },

        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
                0.08
            ),
        },

        noOptionsMessage: {
            padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        },

        singleValue: {
            fontSize: 16,
        },

        placeholder: {
            position: 'absolute',
            fontSize: 16,
        },

        paper: {
            position: 'absolute',
            zIndex: 1000,
            marginTop: theme.spacing.unit,
            left: 0,
            right: 0,
        },

        divider: {
            height: theme.spacing.unit * 2,
        },
    }
}

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            Brak wyników
        </Typography>
    )
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    )
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function SingleValue(props) {
    return (
        <Typography
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    )
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    )
}

function Menu(props) {
    return (
        <Paper
            square
            className={props.selectProps.classes.paper}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    )
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

class MultiSelect extends React.Component {
    onChange = values => this.props.onChange(values.map(({ value }) => value))

    render() {
        const {
            classes,
            theme,
            options,
            value,
            onInputChange,
            label,
            helperText,
            required,
        } = this.props

        let { placeholder } = this.props

        if (!placeholder) {
            placeholder = 'Wybierz...'
        }

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        }

        return (
            <div className={classes.root}>
                <NoSsr>
                    <Select
                        classes={classes}
                        styles={selectStyles}
                        textFieldProps={{
                            label,
                            helperText,
                            InputLabelProps: {
                                shrink: true,
                            },
                        }}
                        options={options}
                        components={components}
                        value={value}
                        onChange={this.onChange}
                        onInputChange={onInputChange}
                        placeholder={placeholder}
                        required={required}
                        isMulti
                    />
                </NoSsr>
            </div>
        )
    }
}

class SingleSelect extends React.Component {
    onChange = ({ value }) => this.props.onChange(value)

    render() {
        const {
            classes,
            theme,
            options,
            value,
            label,
            helperText,
            required,
            fullWidth,
            className,
        } = this.props

        let { placeholder } = this.props

        if (!placeholder) {
            placeholder = 'Wybierz...'
        }

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        }

        const filter = input => {
            if (!input) {
                return options
            }

            return options.filter(i =>
                i.label.toLowerCase().includes(input.toLowerCase())
            )
        }

        const loadOptions = (inputValue, callback) => {
            setTimeout(() => {
                callback(filter(inputValue))
            }, 400)
        }

        const styles = {}

        if (fullWidth) {
            styles.width = '100%'
        }

        return (
            <div className={className} style={styles}>
                <div className={classes.root}>
                    <NoSsr>
                        <AsyncSelect
                            defaultOptions={options}
                            cacheOptions
                            loadOptions={loadOptions}
                            classes={classes}
                            styles={selectStyles}
                            textFieldProps={{
                                label,
                                helperText,
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            components={components}
                            value={value}
                            onChange={this.onChange}
                            // placeholder={placeholder}
                            required={required}
                        />
                    </NoSsr>
                </div>
            </div>
        )
    }
}

MultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    placeholder: PropTypes.string,
    theme: PropTypes.object.isRequired,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    required: PropTypes.bool,
}

SingleSelect.defaultProps = {
    options: [],
    value: { value: '', label: 'Wybierz wartość' },
    required: false,
}

SingleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    placeholder: PropTypes.string,
    theme: PropTypes.object.isRequired,
    value: PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
    }),
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string.isRequired,
}

MultiSelect.defaultProps = {
    options: [],
    value: [],
    required: false,
    fullWidth: false,
    className: '',
}

export default withStyles(styles, { withTheme: true })(MultiSelect)
const singleSelect = withStyles(styles2, { withTheme: true })(SingleSelect)

export { singleSelect as SingleSelect }
