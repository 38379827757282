import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Icon,
    IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import PropTypes from 'prop-types'
import Api from '../../../Api'

class AllegroProduct extends React.Component {
    constructor(props) {
        super(props)

        let phrase = ''

        if (props.phrase) {
            phrase = props.phrase
        }

        this.state = {
            loading: false,
            phrase, // string
            products: [], // item = { category {id, path, similar}, id, images, name, parameters}
            responses: [],
        }

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        // this.findProduct()
    }

    findProduct = () => {
        const phrase = this.state.phrase

        if (phrase.trim() !== '') {
            this.setState({ loading: true })

            Api.findAllegroProduct(this.state.phrase)
                .then(response => {
                    const products = response.data
                    this.setState({
                        products: products.products,
                        loading: false,
                    })
                })
                .catch(error => {
                    this.setState({ loading: false })
                    console.error(error)
                })
        }
    }

    onChange = event => {
        this.setState({ phrase: event.target.value })
    }

    onClick = id => {
        const { productId } = this.props

        const product = this.state.products.find(product => product.id == id)

        if (this.state.responses.length > 3) {
            this.setState({ responses: [] })
        }

        if (product) {
            const products = product.category.similar.map(category => ({
                allegroProductId: product.id,
                productId,
                categoryId: parseInt(category.id),
            }))

            products.push({
                allegroProductId: product.id,
                productId,
                categoryId: parseInt(product.category.id),
            })

            Api.saveAllAllegroProducts(products)
                .then(_ => {
                    const responses = this.state.responses
                    responses.push({
                        type: 'success',
                        message: `Sparowano produkt ${product.name}`,
                    })
                    this.setState({ responses })
                })
                .catch(error => {
                    console.error(error)
                    const responses = this.state.responses
                    responses.push({
                        type: 'error',
                        message: `Nie udało się sparować produktu ${product.name}`,
                    })
                    this.setState({ responses })
                })
        }
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.findProduct()
        }
    }

    render() {
        const { loading, phrase } = this.state

        const products = this.state.products.map(product => {
            const image = product.images[0]
            const reportAnErrorUrl = `https://allegro.pl/moje-allegro/sprzedaz/zglos-blad-w-produkcie/${product.id}`
            // const reportAnErrorUrl = `https://allegro.pl.allegrosandbox.pl/moje-allegro/sprzedaz/zglos-blad-w-produkcie/${product.id}`

            const categoryPath = product.category.path
                ? product.category.path
                : `Kategoria nie dodana do Presto id: ${product.category.id}`

            const similar = product.category.similar.map((category, index) => {
                const categoryPath = category.path
                    ? category.path
                    : `Kategoria nie dodana do Presto id: ${category.id}`
                return (
                    <Typography
                        variant={'body2'}
                        color="textSecondary"
                        key={index}
                    >
                        {categoryPath}
                    </Typography>
                )
            })

            const attributes = product.parameters.map(param => {
                return (
                    <Typography
                        key={param.id}
                        variant={'body2'}
                        color="textSecondary"
                    >
                        {param.name}: {param.value}
                    </Typography>
                )
            })

            return (
                <Grid item xs={12} key={product.id}>
                    <Card style={{ display: 'flex' }}>
                        <div
                            style={{
                                height: '240px',
                                width: '600px',
                                maxWidth: '600px',
                                flex: '1 0 auto',
                            }}
                        >
                            {image && (
                                <CardMedia
                                    component="img"
                                    image={image}
                                    alt={product.name}
                                    style={{
                                        display: 'block',
                                        width: 'auto',
                                        height: 'auto',
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                />
                            )}
                        </div>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <CardContent style={{ flex: '1 0 auto' }}>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component={'div'}
                                    title={product.name}
                                >
                                    {product.name}
                                </Typography>
                                <Typography
                                    variant={'title'}
                                    color="textSecondary"
                                >
                                    Kategorie
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    color="textSecondary"
                                >
                                    {categoryPath}
                                </Typography>
                                {similar}
                                <Typography
                                    variant={'title'}
                                    color="textSecondary"
                                >
                                    Atrybuty
                                </Typography>
                                {attributes}
                            </CardContent>
                            <CardActions style={{ flex: '1 0 auto' }}>
                                <Button
                                    onClick={() => this.onClick(product.id)}
                                    color="primary"
                                    variant="contained"
                                >
                                    Wybierz
                                </Button>
                                <Button
                                    href={reportAnErrorUrl}
                                    target="_blank"
                                    rel="noopener"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Zgłoś błąd w produkcie
                                </Button>
                            </CardActions>
                        </div>
                    </Card>
                </Grid>
            )
        })

        const alerts = this.state.responses.map(response => {
            const color = response.type === 'error' ? 'error' : 'primary'
            return <Typography color={color}>{response.message}</Typography>
        })

        return (
            <Dialog
                open={this.props.open}
                fullWidth
                maxWidth={'lg'}
                onClose={this.props.onClose}
            >
                <DialogTitle>
                    Wyszukaj produkt w Allegro
                    <IconButton
                        onClick={this.props.onClose}
                        style={{ position: 'absolute', right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {alerts}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        className="mr-8 mb-8"
                        placeholder="Fraza wyszukiwania: nazwa, ean itp."
                        onChange={this.onChange}
                        value={this.state.phrase}
                        label={'Szukaj'}
                        margin={'dense'}
                        variant={'standard'}
                        fullWidth
                        autoFocus
                        onKeyPress={this.onKeyPress}
                        InputProps={{
                            endAdornment: <Icon>search</Icon>,
                        }}
                    />
                    <Grid container spacing={16}>
                        {loading && (
                            <Grid item xs={12}>
                                <Typography>Szukam...</Typography>
                            </Grid>
                        )}
                        {!loading && products.length === 0 && (
                            <Grid item xs={12}>
                                <Typography>
                                    Nie znaleziono żadnych wyników dla frazy{' '}
                                    {phrase}
                                </Typography>
                            </Grid>
                        )}
                        {products}
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

AllegroProduct.propTypes = {
    open: PropTypes.bool.isRequired,
    phrase: PropTypes.string,
    productId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default AllegroProduct
