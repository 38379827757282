import {
    DESCRIPTION_ITEM_TYPES_FETCHING,
    DESCRIPTION_ITEM_TYPES_FETCHING_SUCCESS,
    DESCRIPTION_ITEM_TYPES_FETCHING_FAILURE,
} from '../actions/types'

import Api from '../Api'


export const getDescriptionItemTypes = () => dispatch => {
    dispatch({ type: DESCRIPTION_ITEM_TYPES_FETCHING })

    Api.getDescriptionItemTypes()
        .then(response => {
            dispatch({
                type: DESCRIPTION_ITEM_TYPES_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: DESCRIPTION_ITEM_TYPES_FETCHING_FAILURE,
                payload: error
            })
        })
}
