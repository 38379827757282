import {
    DELIVERIES_FETCHING,
    DELIVERIES_FETCHING_SUCCESS,
    DELIVERIES_FETCHING_FAILURE,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: []
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELIVERIES_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case DELIVERIES_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload)
            }

        case DELIVERIES_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}

const prepareItems = (items) => {
    const result = {}

    items.forEach(({ id, name }) => {
        result[id] = { id, name }
    })

    return result
}

export default reducer
