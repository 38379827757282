import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Page from '../../../components/Page'

import {
    Icon,
    Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { getCompetitorOffer } from '../../../actions/IncuboActions'


const nl2br = text => text.split('\n').map((value, index) => {
    return (
        <React.Fragment key={index}>
            {value}
            <br />
            <br />
        </React.Fragment>
    )
})

const diff = (from, to) => {
    return to.split('\n').map((text, toIndex) => {
        const fromIndex = from.split('\n').findIndex(fromText => fromText == text)
        let newText = <Typography>{text}</Typography>

        if (fromIndex === -1) {
            newText = <Typography color="error">{text}</Typography>
        } else if (fromIndex === toIndex) {
            newText = <Typography color="secondary">{text}</Typography>
        }

        return (
            <React.Fragment key={toIndex}>
                {newText}
                <br />
                <br />
            </React.Fragment>
        )
    })
}

const styles = theme => ({
})

const mapStateToProps = ({ presto }) => {
    return {
        ...presto.incuboCompetitorOffers,
    }
}

class Offer extends React.Component {
    componentDidMount() {
        const offerId = this.props.match.params.offerId
        const { getCompetitorOffer } = this.props
        getCompetitorOffer(offerId)
    }

    render() {
        const { items } = this.props
        const offerId = this.props.match.params.offerId

        const offer = items[offerId]

        return (
            <Page
                pageTitle="Oferta konkurenta"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">local_shipping</Icon>
                )}
                content={(
                    <>
                        {offer ? (
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Cena</Typography>
                                    <Typography>
                                        {offer.price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Sparowane aukcje</Typography>
                                    <Typography>
                                        {offer.pairedOffers}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5">Stary opis</Typography>
                                    <Typography>
                                        {nl2br(offer.descriptionOld)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h5">Nowy opis</Typography>
                                    <Typography>
                                        {diff(offer.descriptionOld, offer.description)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <p>laduje</p>
                        )}
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles)(connect(
    mapStateToProps, {
        getCompetitorOffer
    }
)(Offer))
