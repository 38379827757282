import React from 'react'


function IFrame(props) {
    let { width, height, link, className, allowFullScreen } = props

    if (!width) {
        width = 560
    }

    if (!height) {
        height  = 315
    }

    if (!className) {
        className = ''
    }

    if (!allowFullScreen) {
        allowFullScreen = true
    }

    return link ? (
        <iframe
            className={className}
            width={width}
            height={height}
            src={link}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            align="middle"
            allowFullScreen={allowFullScreen}
            title="iframe"
        />
    ) : null
}

export default IFrame
