import React from 'react'

import {Button, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import Page from '../../../components/Page'
import List from '../../../components/List'


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },
})


class DeliveryList extends React.Component {
    componentDidMount() {
        this.props.getDeliveries()
    }

    edit = id => {
        this.props.redirect(`/delivery/${id}`)
    }

    delete = id => id

    addDelivery = () => {
        this.props.redirect('delivery')
    }

    render() {
        const { classes } = this.props

        const values = this.props.items.map(item => {
            return {
                actions: [],
                columns: [item.name],
                onClick: () => this.props.redirect(`/delivery/${item.id}`),
            }
        })

        const columns = [{ name: 'Nazwa' }]

        return (
            <Page
                pageTitle="Koszty dostawy"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">local_shipping</Icon>
                )}

                content={(
                    <>
                        <List
                            values={values}
                            columns={columns}
                            loading={this.props.fetching}
                        />
                        <div>
                            <Button
                                className={classes.button}
                                onClick={this.addDelivery}
                                variant="contained"
                                color="primary"
                            >
                                <Icon className={classes.leftIcon}>add</Icon>
                                Dodaj nowy
                            </Button>
                        </div>
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles)(DeliveryList)
