import React, {Component} from 'react'
import axios from 'axios'

class DragAndDrop extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dragging: false,
        }
    }

    dropRef = React.createRef()

    componentDidMount() {
        this.dragCounter = 0

        const div = this.dropRef.current

        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }

    componentWillUnmount() {
        const div = this.dropRef.current

        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    handleDrag = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = e => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    handleDragOut = e => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter--

        if (this.dragCounter > 0) return

        this.setState({dragging: false})
    }

    handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({dragging: false})

        const url = e.dataTransfer.getData('URL')

        if (url) {
            let extension = 'jpg'

            if (url.includes('.png')) {
                extension = 'png'
            } else if (url.includes('.jpeg') || url.includes('.jpg')) {
                extension = 'jpg'
            }

            axios.get(url, {responseType: 'blob'})
                .then(response => {
                    let file = response.data
                    file.name = `tmp.${extension}`
                    file.filename = `tmp.${extension}`

                    this.props.handleFile(file)
                })

            return
        }

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                const file = e.dataTransfer.files[i]
                this.props.handleFile(file)
            }

            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    render() {
        return (
            <div
                ref={this.dropRef}
                style={{display: 'inline-block', position: 'relative'}}
            >
                {this.state.dragging &&
                    <div
                        style={{
                            border: 'dashed grey 4px',
                            backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                left: 0,
                                textAlign: 'center',
                                color: 'grey',
                                fontSize: 36
                            }}
                        >
                            <div>drop here :)</div>
                        </div>
                    </div>
                }
                {this.props.children}
            </div>
        )
    }
}

export default DragAndDrop