import React from 'react'

import DeleteIcon from '@material-ui/icons/Delete'
import {
    Checkbox,
    IconButton,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    withStyles,
} from '@material-ui/core'
import IconButton2 from '../../../components/IconButton'
import ImageUpload from '../../../components/ImageUpload'

const styles = theme => ({
    root: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },

    urlColumn: {
        width: '100%',
    },

    tabRoot: {
        marginBottom: theme.spacing.unit * 3,
    },

    margin: {
        margin: theme.spacing.unit,
        marginTop: theme.spacing.unit * 1.5,
        marginRight: 0,
    },

    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})

class Images extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newImage: '',
        }
    }

    addImage = () => {
        this.props.addImage(this.props.product.id, this.state.newImage)
        this.setState({ newImage: '' })
        this.newUrlInput.focus()
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.addImage()
        }
    }

    handleFile = data => {
        const { id, name, sku } = this.props.product
        this.props.uploadImage(id, sku, name, data)
    }

    render() {
        const {
            classes,
            toggleNotForGallery,
            removeImage,
            setMainImage,
            setAllegroMini,
            setImageSort,
        } = this.props

        const rows = this.props.images.map(
            (
                { id, isMain, notForGallery, url, sort, isAllegroMini },
                index
            ) => {
                return (
                    <TableRow key={index}>
                        <TableCell
                            className="w-52"
                            component="th"
                            scope="row"
                            padding="none"
                        >
                            <img
                                className="w-full block rounded"
                                src={url}
                                alt={id}
                            />
                        </TableCell>
                        <TableCell className={classes.urlColumn}>
                            <TextField
                                className="mt-8 mb-16"
                                name="url"
                                value={url}
                                fullWidth
                            />
                        </TableCell>
                        <TableCell>
                            <Radio
                                checked={isMain}
                                name="isMain"
                                color="primary"
                                onChange={setMainImage.bind(this, id, index)}
                            />
                        </TableCell>
                        <TableCell>
                            <Radio
                                checked={isAllegroMini}
                                name="isAllegroMini"
                                color="primary"
                                onChange={setAllegroMini.bind(this, id, index)}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={notForGallery}
                                name="notForGallery"
                                onChange={() => toggleNotForGallery(id, index)}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                type="number"
                                className="mt-8 mb-16"
                                name="sort"
                                value={String(sort)}
                                onChange={e => setImageSort(id, e.target.value)}
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                className={classes.button}
                                aria-label="Delete"
                                onClick={removeImage.bind(
                                    this,
                                    this.props.product.id,
                                    id
                                )}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            }
        )

        const additionalRows = this.props.additionalImages
            .filter(image => {
                return !this.props.images.map(({ url }) => url).includes(image)
            })
            .map((url, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell
                            className="w-52"
                            component="th"
                            scope="row"
                            padding="none"
                        >
                            <img className="w-full block rounded" src={url} />
                        </TableCell>
                        <TableCell className={classes.urlColumn}>
                            <TextField
                                className="mt-8 mb-16"
                                name="url"
                                value={url}
                                fullWidth
                            />
                        </TableCell>
                    </TableRow>
                )
            })

        const countRows = rows.length + additionalRows.length
        let countImages = <span>{countRows}</span>

        if (countRows > 16) {
            countImages = <span style={{ color: 'red' }}>{countRows}</span>
        }

        return (
            <div>
                <div>
                    <h3 className={classes.h}>
                        Zdjęcia (ilość: {countImages})
                    </h3>
                    <ImageUpload handleFile={this.handleFile} />
                    <div className="flex pt-8 pb-16">
                        <TextField
                            className="mr-8"
                            fullWidth
                            id="url"
                            label="Adres nowego zdjęcia"
                            name="url"
                            onChange={event =>
                                this.setState({ newImage: event.target.value })
                            }
                            onKeyPress={this.onKeyPress}
                            value={this.state.newImage}
                            variant="outlined"
                            inputRef={i => (this.newUrlInput = i)}
                        />
                        <IconButton2
                            disabled={this.state.newImage.length === 0}
                            onClick={this.addImage}
                            icon="add_photo_alternate"
                        />
                    </div>
                </div>
                {this.props.images.length > 0 && (
                    <div className={classes.root}>
                        <Table classes={{ root: classes.tabRoot }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Zdjęcie</TableCell>
                                    <TableCell>Url</TableCell>
                                    <TableCell>Zdjęcie główne</TableCell>
                                    <TableCell>Miniatura Allegro</TableCell>
                                    <TableCell>
                                        Nie wyświetlaj w galerii sklepu
                                    </TableCell>
                                    <TableCell>Kolejność</TableCell>
                                    <TableCell>Usuń</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{rows}</TableBody>
                        </Table>
                    </div>
                )}

                <div>
                    <h3 className={classes.h}>
                        Zdjęcia dodatkowe (elementy zestawu, bloki statyczne)
                    </h3>
                </div>
                {additionalRows.length > 0 && (
                    <div className={classes.root}>
                        <Table classes={{ root: classes.tabRoot }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Zdjęcie</TableCell>
                                    <TableCell>Url</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{additionalRows}</TableBody>
                        </Table>
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Images)
