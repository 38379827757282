import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    MAGENTO_CATEGORIES_CHANGE,
    PRODUCT_ADD_MAGENTO_CATEGORY,
    PRODUCT_REMOVE_MAGENTO_CATEGORY,
} from '../../actions/types'


const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    const items = state.slice()
    let categoriesToAdd = []

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareCategories(action.payload)

        case MAGENTO_CATEGORIES_CHANGE:
            return action.categories.slice()

        case PRODUCT_ADD_MAGENTO_CATEGORY:
            categoriesToAdd = action.category.path.split('/').map(id => Number(id))
            categoriesToAdd.forEach(categoryId => {
                if (categoryId !== 1 && categoryId !== 2 && categoryId !== null && categoryId !== undefined) {
                    items.push(categoryId)
                }
            })

            return Array.from(
                new Set(items.filter(id => id !== null && id !== undefined)))

        case PRODUCT_REMOVE_MAGENTO_CATEGORY:
            return state.filter(categoryId => categoryId !== action.categoryId)

        default:
            return state
    }
}

const prepareCategories = ({ magentoCategories }) => {
    return magentoCategories
}

export default reducer
