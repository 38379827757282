import {
    PRODUCT_CATEGORY_ADDING,
    PRODUCT_CATEGORY_ADDING_SUCCESS,
    PRODUCT_CATEGORY_ADDING_FAILURE,
    PRODUCT_CATEGORY_REMOVE,
    PRODUCT_ATTRIBUTES_FETCHING,
    PRODUCT_ATTRIBUTES_FETCHING_SUCCESS,
    PRODUCT_ATTRIBUTES_FETCHING_FAILURE,
} from '../../actions/types'

import Api from '../../Api'


export const addCategoryToProduct = (categoryId, productId) => dispatch => {
    dispatch({ type: PRODUCT_CATEGORY_ADDING })

    Api.addProductCategory(productId, categoryId)
        .then(response => {
            dispatch({
                type: PRODUCT_CATEGORY_ADDING_SUCCESS,
                payload: response.data
            })

            dispatch({
                type: PRODUCT_ATTRIBUTES_FETCHING,
            })

            Api.getProduct(productId)
                .then(response => {
                    dispatch({
                        type: PRODUCT_ATTRIBUTES_FETCHING_SUCCESS,
                        payload: response.data.attributes
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PRODUCT_ATTRIBUTES_FETCHING_FAILURE,
                        error,
                    })
                })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_CATEGORY_ADDING_FAILURE,
                error,
            })
        })
}

export const removeCategoryFromProduct = (categoryId, productId) => dispatch => {
    Api.removeProductCategory(productId, categoryId)
        .then(() => {
            dispatch({
                type: PRODUCT_CATEGORY_REMOVE,
                id: categoryId,
            })
        })
}
