import {
    PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE,
    PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE,
    PRODUCT_DELIVERY_QUANTITY_CHANGE,
} from '../../actions/types'


export const updateDeliveryFirstPiece = (id, value) => dispatch => {
    dispatch({
        type: PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE,
        id,
        value,
    })
}

export const updateDeliveryNextPieces = (id, value) => dispatch => {
    dispatch({
        type: PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE,
        id,
        value,
    })
}

export const updateDeliveryQuantity = (id, value) => dispatch => {
    dispatch({
        type: PRODUCT_DELIVERY_QUANTITY_CHANGE,
        id,
        value,
    })
}
