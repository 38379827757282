import React, {Component} from 'react'
import Page from '../../../components/Page'
import List from '../../../components/List'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Icon, Typography} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import _ from 'lodash'


const styles = theme => ({
    list: {
        marginBottom: theme.spacing.unit * 6,
    },
})

class ActionsMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            anchor: null,
        }
    }

    closeMenu = () => this.setState({ anchor: null })
    openMenu = event => this.setState({ anchor: event.currentTarget })
    onClick = action => {
        this.setState({ anchor: null })
        action()
    }

    render() {
        const open = Boolean(this.state.anchor)
        const loading = this.props.loading

        const {
            productId,
            auctionNumber,
            isActive,
        } = this.props

        const toAllegroButton = (
            <MenuItem
                key={5}
                onClick={() => window.open(`https://allegro.pl/listing?string=${auctionNumber}`)}
            >
                Przejdź do Allegro
            </MenuItem>
        )

        const toProductButton = (
            <MenuItem
                key={5}
                onClick={() => this.props.redirect(`product/${productId}`)}
            >
                Przejdź do edycji Produktu
            </MenuItem>
        )

        return (
            <div key={1}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <IconButton
                            onClick={this.openMenu}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            open={open}
                            onClose={this.closeMenu}
                            anchorEl={this.state.anchor}
                        >
                            {auctionNumber && isActive ? toAllegroButton : null}
                            {toProductButton}
                        </Menu>
                    </div>
                )}
            </div>
        )
    }
}

class AuctionList extends Component {
    componentDidMount() {
        const { page, pageSize } = this.props
        const filters = []
        this.props.findAuctions(filters, page, pageSize)
    }

    getAuctions = (event, page) => {
        const { filters, pageSize } = this.props
        page += 1

        this.props.findAuctions(filters, page, pageSize)
    }

    onChangePageSize = pageSize => {
        const { filters } = this.props
        const page = 0
        this.props.findAuctions(filters, page, pageSize)
    }

    onFilter = values => {
        const filters = Object.entries(values)
            .filter(entry => entry[1])
            .map(entry => {
                const filter = entry[0]
                let value = entry[1]
                let operator = 'eq'

                if (filter === 'auction_number') {
                    value = Number(value)
                }

                if (_.isString(value)) {
                    operator = 'like'
                }

                return {
                    filter,
                    value,
                    operator,
                }
            })

        this.props.findAuctions(filters, 0, this.props.pageSize)
    }

    render() {
        const { classes } = this.props

        const inputs = [
            { name: 'title.title', label: 'Tytuł' },
            { name: 'account.name', label: 'Konto' },
            { name: 'auction_number', label: 'Numer aukcji' },
        ]

        const values = this.props.items.map(item => {
            return {
                actions: [
                    <ActionsMenu
                        key={1}
                        redirect={this.props.redirect}
                        productId={item.productId}
                        auctionNumber={item.auctionNumber}
                        id={item.id}
                        isActive={item.isActive}
                        loading={false}
                    />
                ],
                columns: [
                    item.title.title,
                    item.product.sku,
                    item.product.price,
                    item.product.price2,
                    item.account.name,
                    item.category.name,
                    item.auctionNumber,
                ],
            }
        })

        const columns = [
            { name: 'Tytuł' },
            { name: 'Sku' },
            { name: 'Cena' },
            { name: 'Cena 2' },
            { name: 'Konto' },
            { name: 'Kategoria' },
            { name: 'Numer aukcji' },
        ]

        return (
            <Page
                pageTitle='Aukcje'
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">shopping_basket</Icon>
                )}
                content={(
                    <>
                        <Typography variant="h6">Lista Aukcji</Typography>
                        <div
                            className={classes.list}
                        >
                            <List
                                columns={columns}
                                inputs={inputs}
                                loading={this.props.fetching}
                                onChangePage={this.getAuctions}
                                onChangeRowsPerPage={event => this.onChangePageSize(event.target.value)}
                                onSearch={this.onFilter}
                                page={this.props.page - 1}
                                paginated={true}
                                rowsPerPage={this.props.pageSize}
                                values={values}
                            />
                        </div>
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(AuctionList)
