import { connect } from 'react-redux'
import AuctionList from './components/AuctionList'
import {
    getAuctions,
    findAuctions,
    auctionsFilterChange,
} from '../../actions/AuctionActions'
import { redirect } from '../../actions/RouterActions'


const mapStateToProps = state => {
    const { items, fetching, page, pageSize, filters } = state.presto.auctions

    return {
        items,
        fetching,
        page,
        pageSize,
        filters,
    }
}

const Auctions = connect(
    mapStateToProps, {
        getAuctions,
        findAuctions,
        auctionsFilterChange,
        redirect,
    }
)(AuctionList)

export default Auctions

