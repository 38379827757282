import { ADD_ERROR, CLEAR_ERROR } from '../actions/types'

const INITIAL_STATE = {
    currentError: [],
    errors: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    const errors = state.errors.slice()

    switch (action.type) {
        case ADD_ERROR:
            errors.push(action.payload)

            return {
                currentError: action.payload,
                errors,
            }

        case CLEAR_ERROR:
            return {
                ...state,
                currentError: [],
            }

        default:
            return state
    }
}

export default reducer
