import React from 'react'
import DataInput from './DataInput'
import DragAndDrop from './DragAndDrop'
import PropTypes from 'prop-types'


const ACCEPTED_FILES = ['jpg', 'png', 'gif'].map(ext => `.${ext}`).join(',')

const ImageUpload = props => {
    return (
        <div>
            <DragAndDrop  handleFile={props.handleFile}>
                <DataInput handleFile={props.handleFile} label="Upload zdjęcia" accept={ACCEPTED_FILES}/>
            </DragAndDrop>
        </div>
    )
}

ImageUpload.propTypes = {
    handleFile: PropTypes.func.isRequired,
}

export default ImageUpload
