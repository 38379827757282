import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import CategoryList from './magentoCategories/CategoryList'

const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})

const Categories = ({
    addCategory,
    categories,
    classes,
    onSearch,
    removeCategory,
    selectedCategories,
    title,
}) => {
    if (!title) {
        title = 'Lista kategorii'
    }

    return (
        <div>
            <h3 className={classes.h}>{title}</h3>
            <CategoryList
                onSearch={onSearch}
                categories={categories}
                selectedCategories={selectedCategories}
                removeCategory={removeCategory}
                addCategory={addCategory}
            />
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(Categories)
