import React from 'react'
import { TextField, Button } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '../../../components/IconButton'
import AllegroProduct from '../components/AllegroProduct'

import { objectToArray } from '../../../helpers'

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
})

class BasicInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newTitle: '',
        }
    }

    addNewTitle = () => {
        this.props.addTitle(this.props.product.id, this.state.newTitle)
        this.setState({ newTitle: '' })
        this.newTitleInput.focus()
    }

    handleTitleChange = (titleId, index, event) => {
        const value = event.target.value
        this.props.handleTitleChange(value, titleId, index)
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.addNewTitle()
        }
    }

    render() {
        const {
            price,
            price2,
            quantity,
            deliveryId,
            manageAuctionsActivation,
            minimalStock,
            activateAuctionAboveStock,
        } = this.props.product

        const deliveries = objectToArray(
            this.props.deliveries.items,
            ({ name }, id, index) => (
                <MenuItem key={index} value={id}>
                    {name}
                </MenuItem>
            )
        )

        deliveries.unshift(
            <MenuItem key={deliveries.length} value={0}>
                Wybierz
            </MenuItem>
        )

        const titles = this.props.product.titles.map((item, index) => (
            <div key={index} className="flex pt-8 pb-16">
                <TextField
                    className="mr-8"
                    fullWidth
                    id={`title-${item.id}`}
                    titleid={item.id}
                    label={`Tytuł ${index + 1}`}
                    onChange={this.handleTitleChange.bind(this, item.id, index)}
                    value={item.title}
                    variant="outlined"
                />
                <IconButton
                    disabled={this.state.newTitle === 0}
                    onClick={this.props.removeTitle.bind(
                        this,
                        this.props.product.id,
                        item.id
                    )}
                    icon="delete"
                />
            </div>
        ))

        return (
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={manageAuctionsActivation}
                            name="manageAuctionsActivation"
                            color="primary"
                            onChange={this.props.handleCheckboxChange}
                        />
                    }
                    label="Zarządzaj aktywnością aukcji na podstawie stanów magazynowych"
                />
                <TextField
                    className="mt-8 mb-16"
                    fullWidth
                    id="price"
                    label="Cena eltrox"
                    name="price"
                    type="number"
                    variant="outlined"
                    value={price}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                PLN
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className="mt-8 mb-16"
                    fullWidth
                    id="price2"
                    label="Cena eltro24"
                    name="price2"
                    type="number"
                    variant="outlined"
                    value={price2}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                PLN
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className="mt-8 mb-16"
                    fullWidth
                    id="quantity"
                    label="Ilość"
                    name="quantity"
                    type="number"
                    step="1"
                    variant="outlined"
                    value={quantity}
                    onChange={this.props.handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                szt.
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className="mt-8 mb-16"
                    fullWidth
                    id="minimalStock"
                    label="Minimalny stan magazynowy (Poniżej tego stanu aukcje zostaną automatycznie zamknięte)"
                    name="minimalStock"
                    type="number"
                    variant="outlined"
                    value={minimalStock}
                    onChange={this.props.handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                szt.
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className="mt-8 mb-16"
                    fullWidth
                    id="activateAuctionAboveStock"
                    label="Stan magazynowy od którego aukcje bedą wznawiane"
                    name="activateAuctionAboveStock"
                    type="number"
                    variant="outlined"
                    value={activateAuctionAboveStock}
                    onChange={this.props.handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                szt.
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    className="mt-8 mb-16"
                    id="delivery"
                    name="deliveryId"
                    select
                    label="Cennik dostawy"
                    value={deliveryId || 0}
                    fullWidth
                    variant="outlined"
                    onChange={this.props.handleChange}
                >
                    {deliveries}
                </TextField>
                <div className="flex pt-8 pb-16">
                    <TextField
                        error={`${this.state.newTitle}`.length > 75}
                        className="mr-8"
                        fullWidth
                        id="new-title"
                        label="Tytuł"
                        name="newTitle"
                        onChange={event =>
                            this.setState({ newTitle: event.target.value })
                        }
                        onKeyPress={this.onKeyPress}
                        value={this.state.newTitle}
                        variant="outlined"
                        inputRef={i => (this.newTitleInput = i)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {`${this.state.newTitle}`.length}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton
                        disabled={this.state.newTitle === 0}
                        onClick={this.addNewTitle}
                        icon="playlist_add"
                    />
                </div>
                {titles}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(BasicInfo)
