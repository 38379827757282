import React from 'react'
import PropTypes from 'prop-types'
import Categories from './CategoryList'
import { connect } from 'react-redux'
import {
    addCategoryForEltrox,
    removeCategoryForEltrox,
} from '../../../../actions/product/MagentoCategoryActions'

const Context = ({
    addCategory,
    categories,
    removeCategory,
    selectedCategories,
    title,
    storeId,
}) => {
    return (
        <>
            {Object.values(categories).length > 0 && (
                <Categories
                    title={title}
                    addCategory={categoryPath =>
                        addCategory(categoryPath, storeId)
                    }
                    removeCategory={categoryId =>
                        removeCategory(categoryId, storeId)
                    }
                    selectedCategories={selectedCategories.filter(
                        i => i.storeId === storeId
                    )}
                    categories={categories}
                />
            )}
        </>
    )
}

Context.propTypes = {
    addCategory: PropTypes.func.isRequired,
    categories: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        })
    ).isRequired,
    removeCategory: PropTypes.func.isRequired,
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.number,
            storeId: PropTypes.number,
        })
    ).isRequired,
    title: PropTypes.string,
    storeId: PropTypes.number.isRequired,
}

const mapStateToProps = ({ presto }) => {
    const { magentoCategoriesForEltrox } = presto
    const { magentoCategoriesPerStore: selectedCategories } = presto.product

    return {
        categories: magentoCategoriesForEltrox.items,
        selectedCategories,
    }
}

export default connect(mapStateToProps, {
    addCategory: addCategoryForEltrox,
    removeCategory: removeCategoryForEltrox,
})(Context)
