import {
    USERS_FETCHING,
    USERS_FETCHING_SUCCESS,
    USERS_FETCHING_FAILURE,
} from './types'

import Api from '../Api'


export const getUsers = () => dispatch => {
    dispatch({
        type: USERS_FETCHING,
    })

    Api.getUsers()
        .then(response => {
            dispatch({
                type: USERS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: USERS_FETCHING_FAILURE,
                error,
            })
        })
}
