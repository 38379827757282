import {
    SEARCH_CATEGORIES_FETCHING,
    SEARCH_CATEGORIES_FETCHING_SUCCESS,
    SEARCH_CATEGORIES_FETCHING_FAILURE,
    CATEGORY_IMPORT_IN_PROGRESS,
    CATEGORY_IMPORT_FAILURE,
    CATEGORY_IMPORT_SUCCESS,
} from '../actions/types'

import Api from '../Api'


export const searchCategories = ({ name, id }) => dispatch => {
    dispatch({ type: SEARCH_CATEGORIES_FETCHING })

    let filters = []

    if (name) {
        filters.push({
            filter: 'name',
            operator: 'like',
            value: name
        })
    }

    if (id) {
        filters.push({
            filter: 'id',
            operator: 'eq',
            value: id
        })
    }

    filters.push({
        filter: 'is_leaf',
        operator: 'eq',
        value: true
    })

    Api.findCategories({ filters })
        .then(response => {
            dispatch({
                type: SEARCH_CATEGORIES_FETCHING_SUCCESS,
                payload: response.data
            })

            if (response.data.items.length === 0 && id) {
                Api.importCategory(id)
                    .then(response => {
                        dispatch({
                            type: CATEGORY_IMPORT_SUCCESS,
                            payload: response.data,
                        })
                    })
                    .catch(() => {
                        dispatch({ type: CATEGORY_IMPORT_FAILURE })
                    })
            }
        })
        .catch(error => {
            dispatch({
                type: SEARCH_CATEGORIES_FETCHING_FAILURE,
                error
            })
        })
}

export const importCategory = id => dispatch => {
    dispatch({ type: CATEGORY_IMPORT_IN_PROGRESS })

    Api.importCategory(id)
        .then(response => {
            dispatch({
                type: CATEGORY_IMPORT_SUCCESS,
                payload: response.data,
            })
        })
        .catch(() => {
            dispatch({ type: CATEGORY_IMPORT_FAILURE })
        })
}
