import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'


const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node)
                    inputRef(node)
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    )
}

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) =>
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    ),
                )}
            </div>
        </MenuItem>
    )
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 16,
    },

    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
})

class IntegrationAutosuggest extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
        }

        this.handleSuggenstionsFetch = debounce(this.handleSuggenstionsFetch, 400)
    }

    handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue
        })
    }

    handleSuggenstionsFetch = ({ value }) => this.props.getSuggestions(value)

    handleOnSuggestionSelected = (event, { suggestion }) => {
        const {
            onSelect,
            clearAfterSelect,
        } = this.props

        onSelect(suggestion)

        if (clearAfterSelect) {
            this.setState({ value: '' })
        }
    }

    render() {

        const {
            classes,
            suggestions,
            placeholder,
            clearSuggestions,
        } = this.props

        const { value } = this.state

        const autosuggestProps = {
            getSuggestionValue: item => item.label,
            onSuggestionsClearRequested: clearSuggestions,
            onSuggestionsFetchRequested: this.handleSuggenstionsFetch,
            onSuggestionSelected: this.handleOnSuggestionSelected,
            renderInputComponent,
            renderSuggestion,
            suggestions,
        }

        return (
            <div className={classes.root}>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        placeholder,
                        value,
                        onChange: this.handleChange('value'),
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
            </div>
        )
    }
}

IntegrationAutosuggest.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(IntegrationAutosuggest)

