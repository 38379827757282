import { Typography, withStyles } from '@material-ui/core'
import Progress from '@material-ui/core/LinearProgress'
import React from 'react'


const styles = theme => ({
    container: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999,
        pointerEvents: 'none',
        margin: 'auto',
    },

    center: {
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },

    text: {
        margin: '0 auto',
        marginBottom: theme.spacing.unit * 2,
    }
})


const Loading = ({ classes }) => (
    <div className={classes.container}>
        <div className={classes.center}>
            <Typography className={classes.text}>Pobieranie danych...</Typography>
            <Progress />
        </div>
    </div>
)


export default withStyles(styles, {withTheme: true})(Loading)
