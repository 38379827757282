import React from 'react'
import { SvgIcon } from '@material-ui/core'


const MagentoIcon = props => (
    <SvgIcon {...props}>
        <path d="m 21.524313,6.4946283 0,11.0021457 -2.723691,1.568058 0,-10.9892562 -6.809219,-3.9351675 -6.8135133,3.9351675 0.017188,10.9935562 -2.7193906,-1.572358 0,-10.9935474 L 12.00859,1 21.524313,6.4946283 Z m -8.166769,12.5702037 -1.357547,0.79046 -1.366141,-0.781862 0,-10.9978542 -2.7193903,1.5723492 0.0043,10.993547 L 11.995712,23 l 4.085531,-2.358528 0,-10.997856 -2.723687,-1.5723394 0,10.9935554 z" />
    </SvgIcon>
)

export default MagentoIcon
