import React from 'react'

import { Button, Icon, IconButton } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { objectToArray } from '../../helpers'
import Page from '../../components/Page'
import List from '../../components/List'
import NotificationModal from './NotificationModal'

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },
})

const UnreadList = ({
    items,
    classes,
    markAsRead,
    showNotification,
    fetching,
}) => {
    const values = items
        .filter(item => !item.isRead)
        .map(item => {
            const date = new Date(item.date)
            const dateTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`

            return {
                actions: [
                    <Button
                        key={1}
                        variant="contained"
                        color="primary"
                        onClick={() => showNotification(item.id)}
                        className={classes.button}
                    >
                        Szczegóły
                    </Button>,
                    <Button
                        key={2}
                        onClick={() => markAsRead(item.id)}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                    >
                        Oznacz jako przeczytaną
                    </Button>,
                ],
                columns: [item.title, dateTime],
            }
        })

    const columns = [{ name: 'Tytuł' }, { name: 'Data' }]

    return <List values={values} columns={columns} loading={fetching} />
}

class NotificationList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showNotification: null,
            page: 1,
        }
    }

    componentDidMount() {
        this.props.getUnreadNotifications()
    }

    showNotification = id => {
        this.setState({ showNotification: id })
    }

    closeNotification = () => {
        this.setState({ showNotification: null })
    }

    nextPage = () => {
        const page = this.state.page + 1
        this.props.getUnreadNotifications(page)
        this.setState({ page })
    }

    previousPage = () => {
        const oldPage = this.state.page
        const page = oldPage > 1 ? oldPage - 1 : 1
        this.props.getUnreadNotifications(page)
        this.setState({ page })
    }

    render() {
        const {
            classes,
            items,
            fetching,
            markNotificationAsRead: markAsRead,
        } = this.props
        const item = this.state.showNotification
            ? items[this.state.showNotification]
            : null

        return (
            <Page
                pageTitle="Powiadomienia"
                headerIcon={
                    <Icon className="text-32 mr-0 sm:mr-12">message</Icon>
                }
                content={
                    <>
                        <UnreadList
                            items={objectToArray(items, value => value)}
                            classes={classes}
                            markAsRead={markAsRead}
                            showNotification={this.showNotification}
                            fetching={fetching}
                        />
                        <div class="mt-16 ml-8">
                            {this.state.page > 1 && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={this.previousPage}
                                    className="mr-8"
                                >
                                    <ChevronLeft
                                        className={classNames(classes.leftIcon)}
                                    />
                                    Nowsze
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                onClick={this.nextPage}
                            >
                                <ChevronRight
                                    className={classNames(classes.rightIcon)}
                                />
                                Starsze
                            </Button>
                        </div>
                        <NotificationModal
                            item={item}
                            open={!!item}
                            onClose={this.closeNotification}
                            markAsRead={markAsRead}
                        />
                    </>
                }
            />
        )
    }
}

export default withStyles(styles)(NotificationList)
