import React from 'react'
import { connect } from 'react-redux'

import {Button, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import Page from '../../components/Page'
import List from '../../components/List'
import { getIncuboEvents } from '../../actions/IncuboActions'
import { redirect } from '../../actions/RouterActions'
import MonitorPrice from './components/MonitorPrice'


const mapStateToProps = ({ presto }) => {
    return {
        ...presto.incuboEvents,
    }
}

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },
})

class IncuboList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            openModal: false
        }
    }

    openModal = () => this.setState({ openModal: true })
    closeModal = () => this.setState({ openModal: false })

    componentDidMount() {
        const { getIncuboEvents, page, pageSize } = this.props
        getIncuboEvents(page, pageSize)
    }

    render() {
        const { items, classes } = this.props

        const values = items.map(({ offerId, eventType }) => {
            return {
                actions: [],
                columns: [offerId, eventType],
                onClick: () => this.props.redirect(`/incubo/${offerId}`),
            }
        })

        const columns = [
            { name: 'Numer aukcji' },
            { name: 'Typ' },
        ]

        return (
            <Page
                pageTitle="Zdarzenia"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">local_shipping</Icon>
                )}
                content={(
                    <>
                        <List
                            values={values}
                            columns={columns}
                            loading={this.props.fetching}
                        />
                        <div>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={this.openModal}
                            >
                                <Icon>add</Icon>
                                Dodaj nowy
                            </Button>
                        </div>
                        <MonitorPrice
                            onClose={this.closeModal}
                            open={this.state.openModal}
                        />
                    </>
                )}
            />
        )
    }
}


export default withStyles(styles)(connect(
    mapStateToProps, {
        getIncuboEvents,
        redirect,
    }
)(IncuboList))
