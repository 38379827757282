import { connect } from 'react-redux'
import { getUnreadNotifications, markNotificationAsRead, getNotifications } from '../../actions/NotificationActions'
import NotificationList from './NotificationList'


const mapStateToProps = state => {
    const { items, fetching } = state.presto.notifications

    return {
        items,
        fetching
    }
}

export default connect(
    mapStateToProps, {
        getUnreadNotifications,
        markNotificationAsRead,
        getNotifications,
    }
)(NotificationList)
