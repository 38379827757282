import {
    ICONS_FETCHING,
    ICONS_FETCHING_SUCCESS,
    ICONS_FETCHING_FAILURE,
} from './types'

import Api from '../Api'

export const getIcons = () => dispatch => {
    dispatch({ type: ICONS_FETCHING })

    Api.getAllIcons()
        .then(response => {
            dispatch({
                type: ICONS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: ICONS_FETCHING_FAILURE,
                error,
            })
        })
}
