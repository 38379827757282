import { ALLEGRO_TAB_CHANGE, PRODUCT_TAB_CHANGE } from '../actions/types'


const INITIAL_STATE = {
    productTab: 0,
    allegroTab: 0,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALLEGRO_TAB_CHANGE:
            return {
                ...state,
                allegroTab: action.index,
            }

        case PRODUCT_TAB_CHANGE:
            return {
                ...state,
                productTab: action.index,
            }

        default:
            return state
    }
}

export default reducer
