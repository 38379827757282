import {
    PRODUCT_ADD_MAGENTO_CATEGORY,
    PRODUCT_REMOVE_MAGENTO_CATEGORY,
    PRODUCT_ADD_MAGENTO_CATEGORY_FOR_ELTROX,
    PRODUCT_REMOVE_MAGENTO_CATEGORY_FOR_ELTROX,
} from '../types'

export const addCategory = category => dispatch => {
    dispatch({
        type: PRODUCT_ADD_MAGENTO_CATEGORY,
        category,
    })
}

export const removeCategory = categoryId => dispatch => {
    dispatch({
        type: PRODUCT_REMOVE_MAGENTO_CATEGORY,
        categoryId,
    })
}

export const addCategoryForEltrox = (category, storeId) => dispatch => {
    dispatch({
        type: PRODUCT_ADD_MAGENTO_CATEGORY_FOR_ELTROX,
        category,
        storeId,
    })
}

export const removeCategoryForEltrox = (categoryId, storeId) => dispatch => {
    dispatch({
        type: PRODUCT_REMOVE_MAGENTO_CATEGORY_FOR_ELTROX,
        categoryId,
        storeId,
    })
}
