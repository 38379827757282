import {
    BUNDLE_ITEMS_FETCHING,
    BUNDLE_ITEMS_FETCHING_SUCCESS,
    BUNDLE_ITEMS_FETCHING_FAILURE,
    BUNDLE_ITEM_CHANGE_QUANTITY,
    PRODUCT_BUNDLE_ITEM_ADD,
    PRODUCT_BUNDLE_ITEM_REMOVE,
} from '../actions/types'

import Api from '../Api'

export const removeBundleItem = (itemId) => dispatch => {
    dispatch({
        type: PRODUCT_BUNDLE_ITEM_REMOVE,
        id: itemId,
    })
}

export const addBundleItem = (data) => dispatch => {
    dispatch({
        type: PRODUCT_BUNDLE_ITEM_ADD,
        payload: { item: data, quantity: 1 }
    })
}

export const changeBundleItemQuantity = (id, quantity) => dispatch => {
    dispatch({
        type: BUNDLE_ITEM_CHANGE_QUANTITY,
        id,
        quantity: Number(quantity),
    })
}

export const findBundleItems = data => dispatch => {
    dispatch({ type: BUNDLE_ITEMS_FETCHING })

    let filters = []

    if (data.name) {
        filters.push({
            filter: 'name',
            operator: 'like',
            value: data.name
        })
    }

    if (data.sku) {
        filters.push({
            filter: 'sku',
            operator: 'eq',
            value: data.sku
        })
    }

    Api.findProducts({ filters })
        .then(response => {
            dispatch({
                type: BUNDLE_ITEMS_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: BUNDLE_ITEMS_FETCHING_FAILURE,
                error,
            })
        })
}
