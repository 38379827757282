import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_CATEGORY_ADDING_SUCCESS,
    PRODUCT_CATEGORY_REMOVE,
    PRODUCT_CATEGORIES_CHANGE_SUCCESS,
    CATEGORY_IMPORT_SUCCESS,
} from '../../actions/types'


const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    let categories = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareCategories(action.payload)

        case PRODUCT_CATEGORIES_CHANGE_SUCCESS:
            return prepareCategories({
                categories: action.payload.items
            })

        case PRODUCT_CATEGORY_ADDING_SUCCESS:
            if (categories.find(item => item.id === action.payload.id)) {
                return state
            }

            categories.push(prepareCategory(action.payload))
            return categories

        case PRODUCT_CATEGORY_REMOVE:
            return state.filter(({ id }) => id !== action.id)

        case CATEGORY_IMPORT_SUCCESS:
            const index = categories.findIndex(item => item.id === action.payload.id)

            if (index !== -1) {
                categories[index] = prepareCategory(action.payload)
            }

            return categories

        default:
            return state
    }
}

const prepareCategories = ({ categories }) => {
    if (!categories) {
        return []
    }

    categories = categories.map(prepareCategory)

    return categories
}

const prepareCategory = category => {
    return {
        ...category,
        attributes: prepareAttributes(category.attributes),
        name: category.path ? category.path : category.name,
    }
}

const prepareAttributes = attributes => {
    if (!attributes) {
        return []
    }

    return attributes.map(attribute => {
        const values = attribute.values.split('|').map(item => {
            const splittedItem = item.split(':')
            const label = splittedItem[1]
            const value = splittedItem[0]

            return { label, value }
        })

        return {
            ...attribute,
            values,
        }
    })
}

export default reducer
