import { toCamelCase } from '../../helpers'

import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_ADD_TITLE,
    PRODUCT_FORM_TITLE_UPDATE,
    PRODUCT_REMOVE_TITLE,
} from '../../actions/types'


const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    let titles = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareTitles(action.payload)

        case PRODUCT_REMOVE_TITLE:
            if (action.titleId === null) {
                return state
            }

            return state.filter(title => title.id !== action.titleId)

        case PRODUCT_ADD_TITLE:
            titles.push({
                id: action.payload.id,
                title: action.payload.title,
            })
            return titles

        case PRODUCT_FORM_TITLE_UPDATE:
            if (action.titleId === null && action.index === null) {
                titles.push({ title: action.value })
                return titles
            }

            return state.map((item, index) => {
                if (action.titleId && item.id === action.titleId) {
                    return {
                        ...item,
                        title: action.value
                    }
                } else if (action.index === index) {
                    return {
                        ...item,
                        title: action.value
                    }
                } else {
                    return item
                }
            })

        default:
            return state
    }
}

const prepareTitles = ({ titles }) => {
    if (!titles) {
        return []
    }

    return toCamelCase(titles.map(({ id, title }) => ({ id, title })))
}

export default reducer
