import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {TextField} from '@material-ui/core'
import {
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    Fab,
    Icon,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import List from '../../../../components/List'


const styles = theme => ({
    margin: {
        margin: theme.spacing.unit
    },

    root: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },

    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    }
})


const SwapBundleItemDialog = props => {
    const { item, open, onClose, searchItems, onSave, onSearch } = props

    const inputs = [{name: 'sku', label: 'Numer katalogowy'}]

    const values = searchItems.map(product => {
        return {
            actions: [],
            columns: [product.sku, product.name],
            onClick: () => onSave(item.id, product.id)
        }
    })

    const columns = [
        { name: 'Numer katalogowy' },
        { name: 'Produkt' },
    ]

    let title = ''

    if (item !== null) {
        title = item.name
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>Zamień: {title}</DialogTitle>
            <DialogContent>
                <List
                    columns={columns}
                    values={values}
                    inputs={inputs}
                    onSearch={onSearch}
                />
            </DialogContent>
        </Dialog>
    )
}

SwapBundleItemDialog.propTypes = {
    item: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

SwapBundleItemDialog.defaultProps = {
    item: {},
}


class BundleItemsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editedItemId: null
        }
    }

    openDialog = id => this.setState({editedItemId: id})
    closeDialog = () => this.setState({editedItemId: null})

    removeItem = id => {
        this.props.removeItem(id)
    }

    swapBundleItem = (oldItemId, newItemId) => {
        const productId = this.props.product.id
        this.props.swapBundleItem(productId, oldItemId, newItemId)
    }

    render() {
        let editedItem = null

        if (this.state.editedItemId !== null) {
            editedItem = this.props.items.find(item => item.id === this.state.editedItemId)
        }

        const { classes } = this.props

        const columns = [
            { name: 'Numer katalogowy' },
            { name: 'Produkt' },
            { name: 'Ilość' },
        ]

        const values = this.props.items.map(item => {
            return {
                actions: [
                    <Tooltip
                        title="Zamień"
                        key={1}
                    >
                        <Fab
                            size="medium"
                            color="secondary"
                            aria-label="Swap item"
                            className={classes.margin}
                            onClick={() => this.openDialog(item.id)}
                        >
                            <Icon>autorenew</Icon>
                        </Fab>
                    </Tooltip>,
                    <Tooltip
                        title="Usuń"
                        key={2}
                    >
                        <Fab
                            size="medium"
                            color="secondary"
                            aria-label="Remove item"
                            className={classes.margin}
                            onClick={() => this.removeItem(item.id)}
                        >
                            <Icon>delete</Icon>
                        </Fab>
                    </Tooltip>,
                ],

                columns: [item.sku, item.name, (
                    <TextField
                        key={3}
                        className="mt-8 mb-16"
                        required
                        id={`item-${item.id}-quantity`}
                        name="quantity"
                        value={item.quantity}
                        onChange={event => this.props.changeQuantity(item.id, event.target.value)}
                    />
                )]
            }
        })

        return (
            <div>
                <h3 className={classes.h}>Elementy zestawu</h3>
                <div className={classes.root}>
                    <List
                        columns={columns}
                        values={values}
                    />
                    <SwapBundleItemDialog
                        item={editedItem}
                        open={this.state.editedItemId !== null}
                        onClose={this.closeDialog}
                        onSave={this.swapBundleItem}
                        onSearch={this.props.onSearch}
                        searchItems={this.props.searchItems}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(BundleItemsList)
