import React from 'react'
import { SvgIcon } from '@material-ui/core'


const Icon = props => (
    <SvgIcon {...props}>
        <path d="m 9.7666667,6.7888872 c 0,-1.088886 0.9999993,-1.6444444 2.4666663,-1.6444444 1.822223,0 4.8,0.9777791 7.155555,2.3555558 L 21.522222,3.0111088 C 19.233333,1.5000018 15.944444,0.5 12.7,0.5 7.3222229,0.5 3.7888897,3.1444469 3.7888897,7.2999998 c 0,8.0000012 11.8222213,5.5999992 11.8222213,9.5999992 0,1.288893 -1.111111,1.955558 -2.911111,1.955558 -2.333333,0 -5.7777772,-1.355553 -8.0888882,-3.377781 L 2.4111121,19.899997 C 5.0555562,22.100001 8.9000001,23.5 12.633334,23.5 c 4.977776,0 8.955554,-2.377779 8.955554,-6.888891 0,-8.155554 -11.8222213,-5.933328 -11.8222213,-9.8222218" />
    </SvgIcon>
)

export default Icon
