import {
    PRODUCT_LIST_FETCHING,
    PRODUCT_LIST_FETCHING_FAILURE,
    PRODUCT_LIST_FETCHING_SUCCESS,
} from '../actions/types'

const INITIAL_STATE = {
    items: [],
    query: null,
    page: 1,
    pageSize: 10,
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_LIST_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case PRODUCT_LIST_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case PRODUCT_LIST_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload.items),
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                query: action.query,
            }

        default:
            return state
    }
}

const prepareItems = data => {
    return data
}

export default reducer
