import { combineReducers } from 'redux'
import allegroAccounts from './AllegroAccountsReducer'
import attribute from './AttributeReducer'
import auctions from './AuctionsReducer'
import auth from './AuthReducer'
import authorizeAccount from './AuthorizeAccountReducer'
import bundleItemsSearch from './BundleItemsSearchReducer'
import categories from './CategoryReducer'
import deliveries from './DeliveriesReducer'
import delivery from './DeliveryReducer'
import deliveryOptionTypes from './DeliveryOptionTypesReducer'
import descriptionItemTypes from './DescriptionItemTypesReducer'
import errors from './ErrorsReducer'
import magentoCategories from './MagentoCategoriesReducer'
import magentoCategoriesForEltrox from './MagentoCategoriesReducerForEltrox'
import newDescription from './NewDescriptionReducer'
import operationRegistry from './OperationRegistryReducer'
import product from './product'
import productErrors from './ProductErrorsReducer'
import productList from './ProductListReducer'
import searchVertoProduct from './VertoSearchProductReducer'
import staticBlock from './StaticBlockReducer'
import staticBlocks from './StaticBlocksReducer'
import tabs from './TabsReducer'
import users from './UsersReducer'
import vertoAttribute from './VertoAttributeReducer'
import vertoAttributes from './VertoAttributesReducer'
import warranties from './WarrantiesReducer'
import cloningProduct from './CloneProductReducer'
import notifications from './NotificationsReducer'
import incuboEvents from './IncuboEventsReducer'
import incuboCompetitorOffers from './IncuboCompetitorOffersReducer'
import realStockInCategories from './RealStockInCategoriesReducer'
import popups from './PopupReducer'
import prestaShopCategories from './PrestaShopCategoriesReducer'
import baselinker from './BaselinkerReducer'
import icons from './IconReducer'

const reducers = combineReducers({
    allegroAccounts,
    attribute,
    auctions,
    auth,
    authorizeAccount,
    bundleItemsSearch,
    categories,
    cloningProduct,
    deliveries,
    delivery,
    deliveryOptionTypes,
    descriptionItemTypes,
    errors,
    incuboEvents,
    incuboCompetitorOffers,
    magentoCategories,
    magentoCategoriesForEltrox,
    newDescription,
    notifications,
    operationRegistry,
    product,
    productErrors,
    products: productList,
    realStockInCategories,
    searchVertoProduct,
    staticBlock,
    staticBlocks,
    tabs,
    users,
    vertoAttribute,
    vertoAttributes,
    warranties,
    popups,
    prestaShopCategories,
    baselinker,
    icons,
})

export default reducers
