import { connect } from 'react-redux'
import { getDeliveries } from '../../actions/DeliveryActions'
import { redirect } from '../../actions/RouterActions'
import DeliveryList from './components/DeliveryList'
import { objectToArray } from '../../helpers'


const mapStateToProps = (state) => {
    const { items, fetching } = state.presto.deliveries

    return {
        items: objectToArray(items, value => value),
        fetching
    }
}

export default connect(
    mapStateToProps, {
        getDeliveries,
        redirect
    }
)(DeliveryList)

