import * as Actions from '../actions'

const getInitialUser = () => {
    const defaultUser = {
        'displayName': 'Wylogowany',
        'photoURL'   : 'assets/images/avatars/profile.jpg',
        'email'      : '',
    }

    if (localStorage.hasOwnProperty('user')) {
        try {
            return JSON.parse(localStorage.getItem('user'))
        } catch(Excepction) {
            return defaultUser
        }
    } else {
        return defaultUser
    }
}

const initialState = {
    role: 'guest',
    data: getInitialUser()
}

const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                ...action.payload
            }
        }
        case Actions.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            }
        }
        case Actions.USER_LOGGED_OUT:
        {
            return initialState
        }
        default:
        {
            return state
        }
    }
}

export default user
