import React, { Component } from 'react'
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    Checkbox,
    ListItemIcon,
    Icon,
    Divider,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { ExpandLess, ExpandMore } from '@material-ui/icons'

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginLeft: theme.spacing.unit * -4,
    },

    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },

    listItem: {
        padding: 0,
    },

    checkbox: {
        padding: 0,
        paddingBottom: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
    },

    listItemText: {
        paddingBottom: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
    },

    listItemIcon: {
        margin: 0,
    },
})

class Tree extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: [],
        }
    }

    onClick = id => {
        let expanded = this.state.expanded

        if (!expanded.includes(id)) {
            expanded.push(id)
        } else {
            expanded = expanded.filter(expandedId => expandedId !== id)
        }

        this.setState({ expanded })
    }

    renderList(data, root) {
        if (root === undefined || root === null) {
            root = this.props.rootId
        }

        const { classes, selected } = this.props
        const open = this.state.expanded.includes(root)
        const node = data[root]

        if (!node) {
            return null
        }

        let children = null

        if (node.children !== null) {
            children = []

            node.children.forEach(id => {
                if (data.hasOwnProperty(id)) {
                    children.push(data[id])
                }
            })
        }

        const childrenList =
            children === null
                ? null
                : children.map(item => this.renderList(data, item.id))

        const onClick = () => this.onClick(node.id)

        if (node.id === this.props.rootId) {
            return (
                <List component="div" disablePadding className={classes.nested}>
                    {childrenList}
                </List>
            )
        } else {
            return (
                <React.Fragment key={node.id}>
                    <ListItem className={classes.listItem} button key={node.id}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <Icon>subdirectory_arrow_right</Icon>
                        </ListItemIcon>
                        <Checkbox
                            checked={selected.includes(node.id)}
                            onClick={() => this.props.onCheck(node.id)}
                            disableRipple
                            className={classes.checkbox}
                        />
                        <ListItemText
                            className={classes.listItemText}
                            onClick={onClick}
                        >
                            {node.name}
                        </ListItemText>
                        {children &&
                            (open ? (
                                <ExpandLess onClick={onClick} />
                            ) : (
                                <ExpandMore onClick={onClick} />
                            ))}
                    </ListItem>
                    <Divider />
                    {children && (
                        <Collapse in={open} unmountOnExit>
                            <List
                                component="div"
                                disablePadding
                                className={classes.nested}
                            >
                                {childrenList}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            )
        }
    }

    render() {
        const { data, classes } = this.props

        return (
            <List component="nav" className={classes.root}>
                {this.renderList(data)}
            </List>
        )
    }
}

Tree.propTypes = {
    rootId: PropTypes.number.isRequired,
    onCheck: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    data: PropTypes.object.isRequired,
    classes: PropTypes.object,
}

Tree.defaultProps = {
    classes: {},
}

export default withStyles(styles)(Tree)
