import {
    REAL_STOCK_IN_CATEGORIES_FETCHING,
    REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS,
    REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE,

    SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS,
    SAVE_REAL_STOCK_IN_CATEGORY_FAILURE,
} from './types'

import Api from '../Api'


export const getCategoriesWithRealStock = () => dispatch => {
    dispatch({
        type: REAL_STOCK_IN_CATEGORIES_FETCHING
    })

    Api.getAllCategoriesWithRealStock()
        .then(response => {
            dispatch({
                type: REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE,
                error
            })

            console.error(error)
        })
}

export const saveRealStockInCategory = data => dispatch => {
    Api.saveRealStockInCategory(data)
        .then(_ => {
            dispatch({
                type: SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS,
                payload: data
            })
        })
        .catch(error => {
            dispatch({
                type: SAVE_REAL_STOCK_IN_CATEGORY_FAILURE,
                error
            })

            console.error(error)
        })
}
