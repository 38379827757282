import {
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING,
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS,
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING:
            return {
                fetching: true,
            }

        case ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS:
            window.open(action.url, '_blank')

            return {
                fetching: false,
            }

        case ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE:
            return {
                fetching: false,
            }

        default:
            return state
    }
}

export default reducer
