import React from 'react'
import { connect } from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import { cloneProduct, closeCloneForm } from '../../../actions/ProductActions'
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Typography,
} from '@material-ui/core'


const styles = theme => ({
})


class CloneProductForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sku: '',
        }
    }

    handleChange = event => {
        this.setState({
            sku: event.target.value
        })
    }

    canBeSubmitted = () => {
        const { sku } = this.state
        const { inProgress } = this.props
        return !inProgress && sku.length > 0
    }

    onSubmit = () => {
        if (this.canBeSubmitted()) {
            const { sku } = this.state
            this.props.cloneProduct(this.props.sourceId, sku)
        }
    }

    render() {
        const { open, closeCloneForm, inProgress, productId } = this.props
        const { sku } = this.state

        return (
            <Dialog
                open={open}
                onClose={closeCloneForm}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Klonowanie produktu</DialogTitle>
                {inProgress ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ): (
                    productId ? (
                        <>
                        <DialogContent>
                            <Typography>Produkt sklonowany</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    closeCloneForm()
                                    window.open(`/product/${productId}`)
                                }}
                            >
                                Przejdz do kopii produktu
                            </Button>
                        </DialogActions>
                        </>
                    ) : (
                        <>
                        <DialogContent>
                            <TextField
                                className="mt-8"
                                label="Numer katalogowy nowego produktu"
                                name="sku"
                                value={sku}
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.canBeSubmitted()}
                                onClick={this.onSubmit}
                            >
                                Klonuj
                            </Button>
                        </DialogActions>
                        </>
                    )
                )}
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    const { cloningProduct } = state.presto

    return {
        ...cloningProduct
    }
}


export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {
    cloneProduct,
    closeCloneForm,
})(CloneProductForm))
