import {
    DELIVERY_FETCHING,
    DELIVERY_FETCHING_SUCCESS,
    DELIVERY_FETCHING_FAILURE,
    DELIVERY_UPDATED,
    DELIVERY_CLEAR,
} from '../actions/types'


const INITIAL_STATE = {
    data: {
        id: null,
        name: '',
        deliveryOptions: {}
    },
    fetching: false,
    changed: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELIVERY_CLEAR:
            return {
                ...INITIAL_STATE
            }

        case DELIVERY_FETCHING:
            return {
                ...state,
                fetching: true
            }

        case DELIVERY_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: prepare(action.payload),
                changed: false,
            }

        case DELIVERY_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case DELIVERY_UPDATED:
            if (action.name === 'deliveryOptions') {
                return {
                    ...state,
                    changed: true,
                    data: {
                        ...state.data,
                        deliveryOptions: {
                            ...state.data.deliveryOptions,
                            [action.value.id]: {
                                ...state.data.deliveryOptions[action.value.id],
                                ...action.value.data
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                changed: true,
                data: {
                    ...state.data,
                    [action.name]: action.value
                }
            }

        default:
            return state
    }
}

const prepare = ({ id, name, deliveryOptions }) => {
    const options = {}

    deliveryOptions.forEach(item => {
        options[item.deliveryOptionTypeId] = item
    })

    return { id, name, deliveryOptions: options }
}

export default reducer
