import {
    VERTO_ATTRIBUTES_FETCHING,
    VERTO_ATTRIBUTES_FETCHING_FAILURE,
    VERTO_ATTRIBUTES_FETCHING_SUCCESS,
} from '../actions/types'

const INITIAL_STATE = {
    items: [],
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VERTO_ATTRIBUTES_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case VERTO_ATTRIBUTES_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case VERTO_ATTRIBUTES_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload),
            }

        default:
            return state
    }
}

const prepareItems = data => {
    return data
}

export default reducer
