import React from 'react'
import { connect } from 'react-redux'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
    monitorPrice
} from '../../../actions/IncuboActions'

const styles = theme => ({
    textField: {
        marginBottom: theme.spacing.unit * 2,
    }
})

class MonitorPrice extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sku: '',
            competitorOffer: ''
        }
    }

    render() {
        const { onClose, open, monitorPrice, classes } = this.props
        const { sku, competitorOffer } = this.state
        const pmId = 1

        const isSkuValid = ![undefined, null, ''].includes(sku) && sku.trim().length > 2
        const isOfferValid = ![undefined, null, ''].includes(competitorOffer) && competitorOffer.trim().length >= 8
        const canAdd = isOfferValid && isSkuValid

        return (
            <Dialog onClose={onClose} open={open} maxWidth="md">
                <DialogTitle>
                    Podaj numer aukcji naszej i konkurencji
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label="Nr katalogowy naszego produktu"
                        value={sku}
                        onChange={e => this.setState({ sku: e.target.value })}
                        fullWidth
                        className={classes.textField}
                    />
                    <TextField
                        variant="outlined"
                        label="Aukcja konkurencji"
                        value={competitorOffer}
                        onChange={e => this.setState({ competitorOffer: e.target.value })}
                        fullWidth
                        className={classes.button}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                    >
                        Zamknij
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!canAdd}
                        onClick={() => monitorPrice(sku, competitorOffer, pmId)}
                    >
                        Dodaj
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default connect(null, {
    monitorPrice
})(withStyles(styles)(MonitorPrice))
