import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS,
} from '../../actions/types'

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareCategories(action.payload)

        case PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS:
            return action.payload

        default:
            return state
    }
}

const prepareCategories = ({ prestashopCategories }) => {
    return prestashopCategories.map(({ categoryId }) => ({
        categoryId,
    }))
}

export default reducer
