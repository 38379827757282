import React from 'react'
import { connect } from 'react-redux'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '../../../../components/IconButton'
import AttributeMappingDialog from './AttributeMappingDialog'

import MultiSelect from '../../../../components/Select'
import { SingleSelect } from '../../../../components/Select'
import Input from '../../../../components/Input'
import RangeInput from '../../../../components/RangeInput'
import { updateAttributeCustomValue } from '../../../../actions/ProductActions'

class Attribute extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.value !== this.props.value ||
            nextProps.customValue !== this.props.customValue
        )
    }

    onMultiChange = (id, data) => {
        const { values } = this.props
        let result = []

        data.forEach(value => {
            const item = values.find(item => item.value === value)

            if (item) {
                result.push(item)
            }
        })

        this.onChange(id, result)
    }

    onChange = (id, value) => {
        this.props.onChange(id, value)
    }

    findValueInValues = (needle, values) => {
        const items = values.filter(({ value }) => needle === value)

        if (items.length) {
            return items[0]
        }

        return { value: '', label: '' }
    }

    render() {
        let {
            id,
            isRange,
            isRequired,
            max,
            maxLength,
            min,
            minLength,
            multipleChoices,
            name,
            precision,
            type,
            unit,
            value,
            values,
            allowedNumberOfValues,
            ambigiousValueId,
            customValuesEnabled,
            customValue,
            onCustomValueChange,
        } = this.props

        let adornment = null

        if (unit) {
            adornment = <InputAdornment position="start">{unit}</InputAdornment>
        }

        let input = null
        const sid = String(id)

        allowedNumberOfValues = Number(allowedNumberOfValues)

        const isMultiline = allowedNumberOfValues > 1

        switch (type) {
            case 'dictionary':
                if (multipleChoices) {
                    input = (
                        <MultiSelect
                            id={sid}
                            required={isRequired}
                            key={id}
                            label={name}
                            select
                            name={name}
                            onChange={value => this.onMultiChange(id, value)}
                            options={values}
                            value={value}
                            fullWidth
                        />
                    )
                } else {
                    const showCustomValueInput =
                        !!customValuesEnabled && value === ambigiousValueId
                    input = (
                        <div className="flex">
                            {name === 'Marka' ? (
                                <SingleSelect
                                    id={sid}
                                    className="mr-8"
                                    required={isRequired}
                                    key={id}
                                    label={name}
                                    name={name}
                                    onChange={value => this.onChange(id, value)}
                                    options={values}
                                    value={this.findValueInValues(
                                        value,
                                        values
                                    )}
                                    fullWidth
                                />
                            ) : (
                                <Input
                                    className="mr-8"
                                    id={sid}
                                    required={isRequired}
                                    key={id}
                                    label={name}
                                    select
                                    name={name}
                                    onChange={event =>
                                        this.onChange(id, event.target.value)
                                    }
                                    value={value}
                                    variant="outlined"
                                    fullWidth
                                >
                                    {values.map(({ label, value }) => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {label}
                                            </MenuItem>
                                        )
                                    })}
                                </Input>
                            )}
                            {showCustomValueInput && (
                                <Input
                                    required={true}
                                    key={ambigiousValueId}
                                    label={'Wartość'}
                                    name={'customValue'}
                                    onChange={event => {
                                        onCustomValueChange(
                                            id,
                                            event.target.value
                                        )
                                    }}
                                    value={customValue}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        </div>
                    )
                }
                break

            case 'integer':
            case 'float':
                if (isRange) {
                    input = (
                        <RangeInput
                            id={id}
                            required={isRequired}
                            key={id}
                            label={name}
                            max={max}
                            min={min}
                            name={name}
                            onChange={this.props.onChange}
                            precision={precision}
                            type={type}
                            InputProps={{
                                startAdornment: adornment,
                            }}
                            value={value}
                            fullWidth
                            variant="outlined"
                        />
                    )
                } else {
                    input = (
                        <Input
                            id={sid}
                            required={isRequired}
                            key={id}
                            label={name}
                            max={max}
                            min={min}
                            name={name}
                            onChange={event =>
                                this.onChange(id, event.target.value)
                            }
                            precision={precision}
                            type={type}
                            InputProps={{
                                startAdornment: adornment,
                            }}
                            value={value}
                            fullWidth
                            variant="outlined"
                        />
                    )
                }
                break

            case 'string':
                value = String(value).trim()

                if (!isMultiline) {
                    input = (
                        <Input
                            id={sid}
                            required={isRequired}
                            key={id}
                            minLength={minLength}
                            maxLength={maxLength}
                            label={name}
                            name={name}
                            onChange={event =>
                                this.onChange(id, event.target.value)
                            }
                            InputProps={{
                                startAdornment: adornment,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {value.length}
                                    </InputAdornment>
                                ),
                            }}
                            error={value.length > maxLength ? true : false}
                            value={value}
                            fullWidth
                            variant="outlined"
                        />
                    )
                } else {
                    input = []
                    const valueList = value.split('\n')

                    for (let i = 0; i < allowedNumberOfValues; i++) {
                        value = ''

                        if (valueList.length > i) {
                            value = valueList[i]
                        }

                        const iid = `${sid}-${i}`
                        const key = `${id}-${i}`

                        input.push(
                            <Input
                                className="pb-8"
                                id={iid}
                                required={isRequired}
                                key={key}
                                minLength={minLength}
                                maxLength={maxLength}
                                label={name}
                                name={name}
                                onChange={event => {
                                    valueList[i] = event.target.value
                                    const newValue = valueList
                                        .filter(
                                            item =>
                                                ![null, undefined, ''].includes(
                                                    String(item).trim()
                                                )
                                        )
                                        .join('\n')
                                    this.onChange(id, newValue)
                                }}
                                InputProps={{
                                    startAdornment: adornment,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {value.length}
                                        </InputAdornment>
                                    ),
                                }}
                                error={value.length > maxLength ? true : false}
                                value={value}
                                fullWidth
                                variant="outlined"
                            />
                        )
                    }
                }
                break

            default:
                input = (
                    <Input
                        id={sid}
                        required={isRequired}
                        key={id}
                        minLength={minLength}
                        maxLength={maxLength}
                        label={name}
                        name={name}
                        onChange={event =>
                            this.onChange(id, event.target.value)
                        }
                        InputProps={{
                            startAdornment: adornment,
                        }}
                        value={value}
                        fullWidth
                        variant="outlined"
                        multiline={isMultiline}
                    />
                )
        }

        return (
            <div className="pb-16 pt-8">
                <div className="flex">
                    <div className="mr-8" style={{ width: '100%' }}>
                        {input}
                    </div>
                    <IconButton
                        onClick={() => this.onChange(id, null)}
                        icon="remove"
                    >
                        Wyczyść
                    </IconButton>
                </div>
            </div>
        )
    }
}

class AttributeList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isMappingModalOpen: false,
        }
    }

    closeMappingModal = () => this.setState({ isMappingModalOpen: false })
    openMappingModal = id => {
        this.props.getAttribute(id)
        this.setState({ isMappingModalOpen: true })
    }

    render() {
        const { categories, attributes: attributeValues } = this.props.product
        const { isMappingModalOpen } = this.state

        const panels = categories.map(
            ({ name, attributes, id: categoryId }, index) => {
                const details = attributes
                    .sort((a, b) => {
                        if (b.isRequired === a.isRequired) {
                            return a.name.localeCompare(b.name)
                        } else {
                            return b.isRequired - a.isRequired
                        }
                    })
                    .map(
                        ({
                            id,
                            isRange,
                            isRequired,
                            max,
                            maxLength,
                            min,
                            minLength,
                            multipleChoices,
                            name,
                            precision,
                            type,
                            unit,
                            values,
                            allowedNumberOfValues,
                            ambigiousValueId,
                            customValuesEnabled,
                        }) => {
                            let value = ''
                            let customValue = ''

                            if (multipleChoices) {
                                value = []
                            }

                            const attributeValue = attributeValues.find(
                                item => item.id === id
                            )

                            if (attributeValue) {
                                value = attributeValue.value
                                customValue = attributeValue.customValue
                            }

                            return (
                                <Attribute
                                    id={id}
                                    isRange={isRange}
                                    isRequired={isRequired}
                                    key={id}
                                    max={max}
                                    maxLength={maxLength}
                                    min={min}
                                    minLength={minLength}
                                    multipleChoices={multipleChoices}
                                    name={name}
                                    onChange={this.props.onAttributeChange}
                                    onCustomValueChange={
                                        this.props.onCustomValueChange
                                    }
                                    precision={precision}
                                    type={type}
                                    unit={unit}
                                    value={value}
                                    values={values}
                                    allowedNumberOfValues={
                                        allowedNumberOfValues
                                    }
                                    ambigiousValueId={ambigiousValueId}
                                    customValuesEnabled={customValuesEnabled}
                                    customValue={customValue}
                                    openMappingModal={this.openMappingModal}
                                />
                            )
                        }
                    )

                return (
                    <ExpansionPanel key={index}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: 'block' }}>
                            <IconButton
                                style={{
                                    marginBottom: '2rem',
                                    marginRight: '1rem',
                                }}
                                onClick={() =>
                                    this.props.importCategory(categoryId)
                                }
                                icon="autorenew"
                            >
                                Aktualizuj
                            </IconButton>
                            {details}
                        </ExpansionPanelDetails>
                        {isMappingModalOpen && (
                            <AttributeMappingDialog
                                open={isMappingModalOpen}
                                onClose={this.closeMappingModal}
                                attribute={this.props.attribute}
                                vertoAttributes={
                                    this.props.product.vertoAttributes
                                }
                                mapAttribute={this.props.addAttributeMapping}
                                removeMapping={
                                    this.props.removeAttributeMapping
                                }
                            />
                        )}
                    </ExpansionPanel>
                )
            }
        )

        return <div>{panels}</div>
    }
}

export default connect(null, {
    onCustomValueChange: updateAttributeCustomValue,
})(AttributeList)
