import {
    NOTIFICATIONS_FETCHING,
    NOTIFICATIONS_FETCHING_SUCCESS,
    NOTIFICATIONS_FETCHING_FAILURE,
    NOTIFICATIONS_MARK_AS_READ,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: {},
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFICATIONS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case NOTIFICATIONS_FETCHING_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                fetching: false,
            }

        case NOTIFICATIONS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case NOTIFICATIONS_MARK_AS_READ:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.id]: {
                        ...state.items[action.id],
                        isRead: true,
                    }
                }
            }

        default:
            return state
    }
}

export default reducer
