import { combineReducers } from 'redux'
import productTitles from './TitlesReducer'
import imagesReducer from './ImagesReducer'
import descriptionReducer from './DescriptionReducer'
import attributesReducer from './AttributesReducer'
import categoriesReducer from './CategoriesReducer'
import productReducer from './ProductReducer'
import deliveryReducer from './DeliveryReducer'
import auctionsReducer from './AuctionsReducer'
import bundleItemsReducer from './BundleItemsReducer'
import vertoAttributesReducer from './VertoAttributesReducer'
import staticBlocksReducer from './StaticBlocksReducer'
import magentoCategoriesReducer from './MagentoCategoriesReducer'
import historyReducer from './HistoryReducer'
import magentoCategoriesForEltroxReducer from './MagentoCategoriesForEltroxReducer'
import prestashopCategories from './PrestashopCategoriesReducer'

const reducer = combineReducers({
    attributes: attributesReducer,
    auctions: auctionsReducer,
    bundleItems: bundleItemsReducer,
    categories: categoriesReducer,
    data: productReducer,
    delivery: deliveryReducer,
    description: descriptionReducer,
    productHistory: historyReducer,
    images: imagesReducer,
    magentoCategories: magentoCategoriesReducer,
    prestashopCategories: prestashopCategories,
    magentoCategoriesPerStore: magentoCategoriesForEltroxReducer,
    staticBlocks: staticBlocksReducer,
    titles: productTitles,
    vertoAttributes: vertoAttributesReducer,
})

export default reducer
