import {
    INCUBO_EVENTS_FETCHING,
    INCUBO_EVENTS_FETCHING_SUCCESS,
    INCUBO_EVENTS_FETCHING_FAILURE,

    INCUBO_OFFER_FETCHING,
    INCUBO_OFFER_FETCHING_SUCCESS,
    INCUBO_OFFER_FETCHING_FAILURE,

    INCUBO_OFFER_PAIRING_SUCCESS,
    INCUBO_OFFER_PAIRING_FAILURE,
} from '../actions/types'

import Api from '../Api'


export const getIncuboEvents = (page = 1, pageSize = 10) => dispatch => {
    dispatch({ type: INCUBO_EVENTS_FETCHING })

    Api.getIncuboEvents(page, pageSize)
        .then(response => {
            dispatch({
                type: INCUBO_EVENTS_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: INCUBO_EVENTS_FETCHING_FAILURE,
                error
            })
        })
}

export const getCompetitorOffer = offerId => dispatch => {
    dispatch({ type: INCUBO_OFFER_FETCHING })

    Api.getCompetitorOffer(offerId)
        .then(response => {
            dispatch({
                type: INCUBO_OFFER_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: INCUBO_OFFER_FETCHING_FAILURE,
                error
            })
        })
}

export const monitorPrice = (sku, competitorOfferId, pmId) => dispatch => {
    Api.monitorPrice(sku, competitorOfferId, pmId)
        .then(() => {
            dispatch({
                type: INCUBO_OFFER_PAIRING_SUCCESS,
            })
        })
        .catch(error => {
            dispatch({
                type: INCUBO_OFFER_PAIRING_FAILURE,
                error
            })
        })
}
