import {
    OPERATION_REGISTRY_FETCHING,
    OPERATION_REGISTRY_FETCHING_SUCCESS,
    OPERATION_REGISTRY_FETCHING_FAILURE,
    OPERATION_REGISTRY_FILTER_CHANGE,
} from '../actions/types'


const INITIAL_STATE = {
    items: [],
    fetching: false,
    page: 1,
    pageSize: 10,
    filters: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPERATION_REGISTRY_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case OPERATION_REGISTRY_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case OPERATION_REGISTRY_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                page: action.data.page,
                pageSize: action.data.pageSize,
                items: prepareItems(action.data.items)
            }

        case OPERATION_REGISTRY_FILTER_CHANGE:
            return {
                ...state,
                filters: action.filters,
            }

        default:
            return state
    }
}

const prepareItems = items => {
    return items.map(item => {
        let localName = item.action
        let details = ''

        switch (item.action) {
            case 'ProductCreate':
                localName = 'Utworzenie produktu'
                break

            case 'SaveProduct':
                localName = 'Modyfikacja produktu'
                break

            case 'StopAuction':
                localName = 'Zatrzymano aukcje'
                details = `Numer aukcji: ${item.after.data.auctionNumber}`
                break

            case 'StartAuction':
                localName = 'Aktywowany aukcję'
                details = `Numer aukcji: ${item.after.data.auctionNumber}`
                break

            case 'PriceChange':
                localName = 'Zmiana ceny'

                const oldPrice = Number(item.before.price).toFixed(2)
                const newPrice = Number(item.after.price).toFixed(2)

                details = `${oldPrice} -> ${newPrice}`
                break

            default:
                localName = 'Inne'
        }

        return {
            ...item,
            date: (new Date(item.date)).toLocaleString('pl-PL'),
            localName,
            details,
        }
    })
}

export default reducer

