import {
    OPERATION_REGISTRY_FETCHING,
    OPERATION_REGISTRY_FETCHING_SUCCESS,
    OPERATION_REGISTRY_FETCHING_FAILURE,
} from '../actions/types'

import Api from '../Api'


export const findOperationsInRegistry = (filters=[], page=1, pageSize=10) => dispatch => {
    dispatch({
        type: OPERATION_REGISTRY_FETCHING,
    })

    Api.findOperationsInRegistry(filters, page, pageSize)
        .then(response => {
            dispatch({
                type: OPERATION_REGISTRY_FETCHING_SUCCESS,
                data: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: OPERATION_REGISTRY_FETCHING_FAILURE,
                error,
            })
        })
}
