import {
    STATIC_BLOCK_FETCHING,
    STATIC_BLOCK_FETCHING_SUCCESS,
    STATIC_BLOCK_FETCHING_FAILURE,
    STATIC_BLOCK_CHANGE,
    STATIC_BLOCK_SAVING_IN_PROGRESS,
    STATIC_BLOCK_SAVING_SUCCESS,
    STATIC_BLOCK_SAVING_FAILURE,
    STATIC_BLOCK_CLEAR,
    ADD_ATTRIBUTE_TO_STATIC_BLOCK,
    REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK,
} from '../actions/types'


const INITIAL_STATE = {
    data: {
        descriptionItemTypeId: 3,
        header: '',
        id: null,
        image1: '',
        image2: '',
        text: '',
        vertoAttributes: [],
    },
    fetching: false,
    changed: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    const vertoAttributes = state.data.vertoAttributes.slice()

    switch (action.type) {
        case STATIC_BLOCK_CLEAR:
            return {
                ...INITIAL_STATE
            }

        case STATIC_BLOCK_FETCHING:
        case STATIC_BLOCK_SAVING_IN_PROGRESS:
            return {
                ...state,
                fetching: true,
            }

        case STATIC_BLOCK_FETCHING_SUCCESS:
        case STATIC_BLOCK_SAVING_SUCCESS:
            return {
                ...state,
                fetching: false,
                changed: false,
                data: prepareData(action.payload)
            }

        case STATIC_BLOCK_FETCHING_FAILURE:
        case STATIC_BLOCK_SAVING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case STATIC_BLOCK_CHANGE:
            return {
                ...state,
                changed: true,
                data: {
                    ...state.data,
                    [action.name]: action.value,
                }
            }

        case ADD_ATTRIBUTE_TO_STATIC_BLOCK:
            vertoAttributes.push(action.payload)

            return {
                ...state,
                changed: true,
                data: {
                    ...state.data,
                    vertoAttributes: vertoAttributes
                }
            }

        case REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK:
            return {
                ...state,
                changed: true,
                data: {
                    ...state.data,
                    vertoAttributes: vertoAttributes.filter(({ id }) => id !== action.attributeValueId)
                }
            }

        default:
            return state
    }
}

const prepareData = (data) => {
    return {
        ...data,
        image1: data.image1 ? data.image1.url : '',
        image2: data.image2 ? data.image2.url : '',
    }
}


export default reducer
