import {
    PRODUCT_ATTRIBUTE_CHANGE,
    PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE,
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_ATTRIBUTES_FETCHING_SUCCESS,
} from '../../actions/types'

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    const items = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareAttributes(action.payload.attributes)

        case PRODUCT_ATTRIBUTE_CHANGE:
            if (action.value == null) {
                return items.filter(item => item.id !== action.id)
            }

            if (!items.find(item => item.id === action.id)) {
                items.push({ id: action.id, value: action.value })
                return items
            }

            return state.map(item => {
                if (item.id === action.id) {
                    return { id: action.id, value: action.value }
                } else {
                    return item
                }
            })

        case PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE:
            return state.map(item => {
                if (item.id === action.id) {
                    return { ...item, customValue: action.value }
                }
                return item
            })

        case PRODUCT_ATTRIBUTES_FETCHING_SUCCESS:
            return prepareAttributes(action.payload)

        default:
            return state
    }
}

const prepareAttributes = attributes => {
    if (!attributes) {
        return []
    }

    return attributes.map(({ attributeId, value, customValue, attribute }) => {
        const values = attribute.values.split('|').map(item => {
            const splittedItem = item.split(':')
            const label = splittedItem[1]
            let value = splittedItem[0]

            return { label, value }
        })

        attribute = {
            ...attribute,
            values,
        }

        if (attribute.multipleChoices) {
            if ([null, undefined, ''].includes(value.trim())) {
                value = []
            } else {
                value = value
                    .split('|')
                    .map(id => values.find(item => item.value === id))
            }
        }

        if (attribute.isRange) {
            try {
                const vt = value.replace(/'/g, '"')
                value = JSON.parse(vt)
            } catch (err) {
                const v = value.split('-')
                let from = null
                let to = null

                if (v.length > 0) {
                    from = String(v[0]).trim()

                    if (attribute.unit) {
                        from = from.replace(attribute.unit, '').trim()
                    }

                    if (v.length > 1) {
                        to = String(v[1]).trim()

                        if (attribute.unit) {
                            to = to.replace(attribute.unit, '').trim()
                        }
                    }
                }

                value = { from, to }
            }
        }

        return { id: attributeId, value, attribute, customValue }
    })
}

export default reducer
