import React from 'react'
import IconButton from './IconButton'


class DataInput extends React.Component {
    handleChange = e => {
        const files = e.target.files

        if (!files) {
            return
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            this.props.handleFile(file)
        }
    }

    onClick = () => {
        this.inputElement.click()
    }

    render() {
        const { label, accept, style, className } = this.props

        return (
            <div style={style} className={className} >
                <IconButton
                    icon="cloud_upload"
                    onClick={this.onClick}
                >
                    {label}
                </IconButton>
                <input
                    accept={accept}
                    id="file"
                    multiple
                    onChange={this.handleChange}
                    ref={input => this.inputElement = input}
                    style={{
                        display: 'none',
                    }}
                    type="file"
                />
            </div>
        )
    }
}


export default DataInput
