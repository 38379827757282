import React from 'react'
import PropTypes from 'prop-types'
import Categories from './prestaShopCategories/CategoryList'
import { connect } from 'react-redux'
import { updateProductCategoriesInPrestaShop } from '../../../actions/product/PrestaShopCategoryActions'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})


const Context = ({
    updateCategories,
    categories,
    selectedCategories,
    title,
    productId,
    isLoading,
    classes,
}) => {
    return (
        <>
            {isLoading ? (
                <>
                    <h3 className={classes.h}>
                        Pobieranie kategorii z PrestaShop...
                    </h3>
                    <CircularProgress size={20} />
                </>
            ) : (
                Object.values(categories).length > 0 && (
                    <Categories
                        title={title}
                        updateCategories={categories =>
                            updateCategories(productId, categories)
                        }
                        selectedCategories={selectedCategories}
                        categories={categories}
                    />
                )
            )}
        </>
    )
}

Context.propTypes = {
    categories: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    updateCategories: PropTypes.func.isRequired,
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.number,
        })
    ).isRequired,
    title: PropTypes.string,
    productId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

Context.defaultProps = {
    isLoading: false,
}

const mapStateToProps = ({ presto }) => {
    const { prestaShopCategories } = presto
    const isLoading = prestaShopCategories.all.loading || prestaShopCategories.selected.loading
    const selectedCategories = presto.product.prestashopCategories

    return {
        categories: prestaShopCategories.all.items,
        selectedCategories,
        isLoading
    }
}

export default connect(mapStateToProps, {
    updateCategories: updateProductCategoriesInPrestaShop,
})(withStyles(styles, { withTheme: true })(Context))
