import {
    PRODUCT_CLONING_IN_PROGRESS,
    PRODUCT_CLONING_SUCCESS,
    PRODUCT_CLONING_FAILURE,
    PRODUCT_CLONING_FORM_OPEN,
    PRODUCT_CLONING_FORM_CLOSE,
} from '../actions/types'


const INITIAL_STATE = {
    inProgress: false,
    productId: null,
    open: false,
}


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_CLONING_IN_PROGRESS:
            return {
                ...state,
                inProgress: true,
                productId: null,
            }

        case PRODUCT_CLONING_FAILURE:
            return {
                ...state,
                inProgress: false,
                productId: null,
            }

        case PRODUCT_CLONING_SUCCESS:
            return {
                ...state,
                inProgress: false,
                productId: action.payload.productId,
            }

        case PRODUCT_CLONING_FORM_OPEN:
            return {
                ...state,
                open: true,
                productId: null,
            }

        case PRODUCT_CLONING_FORM_CLOSE:
            return {
                ...state,
                open: false,
                productId: null,
            }

        default:
            return state
    }
}


export default reducer
