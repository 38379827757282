import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import DescriptionForm from './DescriptionForm'
import DescriptionPreview from './DescriptionPreview'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        padding: theme.spacing.unit * 2,
        overflow: 'auto',
        minHeight: '600px',
    },
})

class Description extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showAllegro: false,
            selectedItemLocalId: null,
            paperHeight: '100vh',
        }
    }

    toggleShowAllegro = () => {
        const showAllegro = !this.state.showAllegro
        this.setState({ showAllegro })
    }

    selectDescriptionItem = localId => {
        this.setState({ selectedItemLocalId: localId })
    }

    addNewDescriptionItem = () => {
        this.setState({ selectedItemLocalId: null })
    }

    componentDidMount() {
        const paperHeight = this.form.clientHeight
        this.setState({ paperHeight })
    }

    componentDidUpdate() {
        const paperHeight = this.form.clientHeight

        if (paperHeight !== this.state.paperHeight) {
            this.setState({ paperHeight })
        }
    }

    removeDescriptionItem = localId => {
        this.props.removeDescriptionItem(localId)
        this.setState({ selectedItemLocalId: null })
    }

    render() {
        const { classes } = this.props
        const { paperHeight } = this.state

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <div ref={element => (this.form = element)}>
                            <Paper className={classes.paper}>
                                <DescriptionForm
                                    addDescriptionItem={
                                        this.props.addDescriptionItem
                                    }
                                    addNewDescriptionItem={
                                        this.addNewDescriptionItem
                                    }
                                    bundleItems={this.props.bundleItems}
                                    clearNewDescription={
                                        this.props.clearNewDescription
                                    }
                                    description={this.props.description}
                                    descriptionItemTypes={
                                        this.props.descriptionItemTypes
                                    }
                                    images={this.props.images}
                                    newDescription={this.props.newDescription}
                                    newDescriptionChange={
                                        this.props.newDescriptionChange
                                    }
                                    product={this.props.product}
                                    removeDescriptionItem={
                                        this.removeDescriptionItem
                                    }
                                    selectedItemLocalId={
                                        this.state.selectedItemLocalId
                                    }
                                    setMainImage={this.props.setMainImage}
                                    showAllegro={this.state.showAllegro}
                                    staticBlocks={this.props.staticBlocks.items}
                                    toggleNotForGallery={
                                        this.props.toggleNotForGallery
                                    }
                                    toggleShowAllegro={this.toggleShowAllegro}
                                    updateDescriptionItem={
                                        this.props.updateDescriptionItem
                                    }
                                    uploadImage={this.props.uploadImage}
                                    isDescription2={this.props.isDescription2}
                                    reorderDescription={
                                        this.props.reorderDescription
                                    }
                                />
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper
                            className={classes.paper}
                            style={{ height: paperHeight }}
                        >
                            <DescriptionPreview
                                description={this.props.description}
                                newDescription={this.props.newDescription}
                                images={this.props.images}
                                staticBlocks={this.props.staticBlocks.items}
                                bundleItems={this.props.bundleItems}
                                onClick={this.selectDescriptionItem}
                                reorderDescription={
                                    this.props.reorderDescription
                                }
                                showAllegro={this.state.showAllegro}
                                toggleShowAllegro={this.toggleShowAllegro}
                                isDescription2={this.props.isDescription2}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Description)
