import React from 'react'
import {Redirect} from 'react-router-dom'
import {FuseUtils} from '@fuse/index'
import PrestoAppConfig from 'apps/presto/PrestoAppConfig'

const routeConfigs = [
    PrestoAppConfig,
]

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        component: () => <Redirect to="/"/>
    }
]
