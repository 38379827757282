import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },

    table: {
        minWidth: 700,
    },

    progress: {
        margin: theme.spacing.unit,
    },

    clickableRow: {
        cursor: 'pointer',
    },
})

class List extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.getInitialState()
    }

    getInitialState = () => {
        const initialState = {}

        if (this.props.inputs) {
            this.props.inputs.forEach(item => {
                if (item) {
                    initialState[item.name] = null
                }
            })
        }

        return initialState
    }

    onInputChange = name => e => {
        this.setState({ [name]: e.target.value })
    }

    onSelectChange = name => e => {
        this.setState({ [name]: e.target.value })

        const data = {
            ...this.state,
            [name]: e.target.value,
        }

        this.props.onSearch(data)
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    search = () => {
        this.props.onSearch(this.state)
        this.setState(this.getInitialState())
    }

    render() {
        const { classes, columns, values, inputs } = this.props

        const hasActions = values.some(
            value => value.actions && value.actions.length > 0
        )

        const headerRows = columns.map(({ name }, index) => (
            <TableCell key={index}>{name}</TableCell>
        ))

        const bodyRows = values.map(({ columns, actions, onClick }, index) => {
            const cells = columns.map((value, index) => (
                <TableCell key={index}>{value}</TableCell>
            ))

            let className = null

            if (onClick) {
                className = classes.clickableRow
            }

            return (
                <TableRow
                    hover
                    key={index}
                    onClick={onClick}
                    className={className}
                >
                    {cells}
                    {hasActions && <TableCell numeric>{actions}</TableCell>}
                </TableRow>
            )
        })

        let setInputColspan = false

        if (inputs.length === 1 && columns.length > inputs.length) {
            setInputColspan = true
        }

        const firstRowCells = inputs.map((item, index) => {
            let content = ''

            if (item) {
                if (item.select) {
                    const options = item.select.map(({ value, label }) => {
                        return (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        )
                    })

                    options.unshift(
                        <MenuItem key={`key-${Date.now()}`} value={null}>
                            -- wybierz --
                        </MenuItem>
                    )

                    content = (
                        <TextField
                            label={item.label}
                            name={item.name}
                            value={this.state[item.name] || ''}
                            onChange={this.onSelectChange(item.name)}
                            type={item.type}
                            select
                            fullWidth
                        >
                            {options}
                        </TextField>
                    )
                } else {
                    content = (
                        <TextField
                            fullWidth
                            placeholder="Szukaj..."
                            label={item.label}
                            name={item.name}
                            value={this.state[item.name] || ''}
                            onChange={this.onInputChange(item.name)}
                            type={item.type}
                            onKeyPress={this.onKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <Icon
                                        className={classNames(
                                            classes.leftIcon,
                                            classes.iconSmall
                                        )}
                                    >
                                        search
                                    </Icon>
                                ),
                            }}
                        />
                    )
                }
            }
            return (
                <TableCell
                    key={index}
                    colSpan={setInputColspan ? columns.length : null}
                >
                    {content}
                </TableCell>
            )
        })

        const firstRow = firstRowCells.length > 0 && (
            <TableRow key={1}>{firstRowCells}</TableRow>
        )

        return (
            <div>
                <Table padding="dense" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {headerRows}
                            {hasActions && <TableCell numeric>Akcje</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {firstRow}
                        {!this.props.loading ? (
                            bodyRows
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 1}>
                                    <CircularProgress
                                        className={classes.progress}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {this.props.paginated && (
                    <TablePagination
                        labelRowsPerPage="Ilość wyników na stronie:"
                        component="div"
                        rowsPerPageOptions={[10, 25, 50]}
                        count={1000000}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page || 0}
                        onChangePage={this.props.onChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                )}
            </div>
        )
    }
}

List.defaultProps = {
    classes: {},
    columns: [],
    inputs: [],
    loading: false,
    onSearch: data => console.log('onSearch', data),
    values: [],
}

List.propTypes = {
    classes: PropTypes.object,
    columns: PropTypes.array,
    inputs: PropTypes.array,
    loading: PropTypes.bool,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onSearch: PropTypes.func,
    paginated: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    values: PropTypes.array,
}

export default withStyles(styles)(List)
