import { connect } from 'react-redux'
import ProductErrors from './components/ProductErrors'
import {
    getProductsWithErrors,
    changeProductWithErrorsFilter,
} from '../../actions/ProductActions'
import { redirect } from '../../actions/RouterActions'



const mapStateToProps = state => {
    const { items, fetching, page, pageSize, filter } = state.presto.productErrors

    return {
        items,
        fetching,
        page,
        pageSize,
        filter,
    }
}

const Component = connect(
    mapStateToProps, {
        getProductsWithErrors,
        changeProductWithErrorsFilter,
        redirect,
    }
)(ProductErrors)

export default Component
