import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles/index'
import {Button, Card, CardContent,  TextField, Typography, Dialog} from '@material-ui/core'
import classNames from 'classnames'
import _ from '@lodash'
import {FuseAnimate} from '@fuse'
import { connect } from 'react-redux'
import { login, isLogged as isLoggedIn } from '../actions/AuthActions'
import { redirect } from '../actions/RouterActions'


const styles = theme => ({
    root: {
        background    : 'url("/assets/images/backgrounds/dark-material-bg.jpg") no-repeat',
        backgroundSize: 'cover'
    },

    card: {
        width   : '100%',
        maxWidth: 384
    }
})

class LoginPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            username: '',
            password: '',
        }
    }

    componentDidMount() {
        this.props.isLoggedIn()
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value))
    }

    onSubmit = () => {
        if (this.canBeSubmitted()) {
            const { username, password } = this.state
            this.props.login(username, password)
            this.setState({ password: '' })
        }
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    canBeSubmitted = () => {
        const { username, password } = this.state
        return (
            username.length > 3 && password.length > 0
        )
    }

    render() {
        const { classes } = this.props
        const { username, password } = this.state
        const open = !this.props.isLogged

        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                fullScreen
            >
                <div className={classNames(classes.root, 'flex flex-col flex-auto flex-no-shrink items-center justify-center p-32')}>
                    <div className="flex flex-col items-center justify-center w-full">
                        <FuseAnimate animation="transition.expandIn">
                            <Card className={classes.card}>
                                <CardContent className="flex flex-col items-center justify-center p-32">
                                    <img className="w-128 m-32" src="assets/images/logos/fuse.svg" alt="logo"/>
                                    <Typography variant="h6" className="mt-16 mb-32">ZALOGUJ SIĘ NA SWOJE KONTO</Typography>
                                    <form name="loginForm" noValidate className="flex flex-col justify-center w-full" onSubmit={this.onSubmit}>
                                        <TextField
                                            className="mb-16"
                                            label="Login"
                                            autoFocus
                                            name="username"
                                            value={username}
                                            onChange={this.handleChange}
                                            onKeyPress={this.onKeyPress}
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                        <TextField
                                            className="mb-16"
                                            label="Hasło"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={this.handleChange}
                                            onKeyPress={this.onKeyPress}
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="w-224 mx-auto mt-16"
                                            aria-label="LOG IN"
                                            disabled={!this.canBeSubmitted()}
                                            onClick={this.onSubmit}
                                        >
                                            ZALOGUJ
                                        </Button>
                                    </form>
                                </CardContent>
                            </Card>
                        </FuseAnimate>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const page = withStyles(styles, {withTheme: true})(LoginPage)

const mapStateToProps = state => {
    const { token, isLogged } = state.presto.auth
    return { token, isLogged }
}

export default connect(mapStateToProps, { login, isLoggedIn, redirect })(page)
