import React from 'react'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import TextField from '@material-ui/core/TextField'
import { FuseScrollbars } from '@fuse'

import { objectToArray } from '../../../helpers'
import List from '../../../components/List'
import ConfirmDialog from '../../../components/ConfirmDialog'

const Select = props => {
    const { value, options, onChange } = props

    return (
        <TextField
            id="standard-select-currency"
            select
            value={value}
            onChange={onChange}
            margin="normal"
            style={{ border: 'none' }}
            InputProps={{
                disableUnderline: true,
            }}
        >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}

class ActionsMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            anchor: null,
        }
    }

    closeMenu = () => this.setState({ anchor: null })
    openMenu = event => this.setState({ anchor: event.currentTarget })
    onClick = action => {
        this.setState({ anchor: null })
        action()
    }

    render() {
        const open = Boolean(this.state.anchor)
        const loading = this.props.loading

        const {
            activateAuction,
            auctionNumber,
            id,
            isActive,
            localId,
            removeAuction,
            saveAuctionToAllegro,
            stopAuction,
        } = this.props

        const saveButton = (
            <MenuItem
                key={1}
                onClick={() =>
                    this.onClick(saveAuctionToAllegro.bind(this, id, localId))
                }
            >
                {auctionNumber ? 'Przegeneruj' : 'Wystaw'}
            </MenuItem>
        )

        const stopButton = (
            <MenuItem
                key={2}
                onClick={() => this.onClick(() => stopAuction(id, localId))}
            >
                Deaktywuj
            </MenuItem>
        )

        const removeButton = (
            <MenuItem
                key={3}
                onClick={() => this.onClick(removeAuction.bind(this, localId))}
            >
                Usuń
            </MenuItem>
        )

        const activateButton = (
            <MenuItem
                key={4}
                onClick={() =>
                    this.onClick(activateAuction.bind(this, id, localId))
                }
            >
                Aktywuj
            </MenuItem>
        )

        const toAllegroButton = (
            <MenuItem
                key={5}
                onClick={() =>
                    window.open(
                        `https://allegro.pl/listing?string=${auctionNumber}`
                    )
                }
            >
                Przejdź do Allegro
            </MenuItem>
        )

        return (
            <div key={1}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <IconButton onClick={this.openMenu}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            open={open}
                            onClose={this.closeMenu}
                            anchorEl={this.state.anchor}
                        >
                            {saveButton}
                            {isActive ? stopButton : removeButton}
                            {auctionNumber && !isActive ? activateButton : null}
                            {auctionNumber && isActive ? toAllegroButton : null}
                        </Menu>
                    </div>
                )}
            </div>
        )
    }
}

class Auctions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accountId: 0,
            categoryId: 0,
            titleId: 0,
            isEmphasized: false,
            isBold: false,
            isHighlight: false,
            isSynchronized: true,
            confirmDialog: {
                isOpen: false,
                id: null,
                localId: null,
            },
        }
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    saveAuction = () => {
        const {
            accountId,
            categoryId,
            isBold,
            isEmphasized,
            isHighlight,
            titleId,
            isSynchronized,
        } = this.state

        this.props.addAuction(this.props.product.id, {
            accountId,
            categoryId,
            isBold,
            isEmphasized,
            isHighlight,
            titleId,
            isSynchronized,
        })

        this.setState({
            accountId: 0,
            categoryId: 0,
            titleId: 0,
            isEmphasized: false,
            isBold: false,
            isHighlight: false,
            isSynchronized: true,
        })
    }

    closeConfirmDialog = () => {
        this.setState({
            confirmDialog: {
                isOpen: false,
                id: null,
                localId: null,
            },
        })
    }

    openConfirmDialog = (id, localId) => {
        this.setState({
            confirmDialog: {
                isOpen: true,
                id,
                localId,
            },
        })
    }

    render() {
        const { categories, titles, auctions } = this.props.product
        const {
            accounts,
            saveAuctionToAllegro,
            removeAuction,
            stopAuction,
            activateAuction,
            addEmphasize,
            removeEmphasize,
        } = this.props

        const categoryOptions = categories.map(({ id, name }) => ({
            label: name,
            value: id,
        }))
        categoryOptions.unshift({ label: 'Wybierz', value: 0 })

        const accountOptions = accounts.map(({ id, name }) => ({
            label: name,
            value: id,
        }))
        accountOptions.unshift({ label: 'Wybierz', value: 0 })

        const titleOptions = titles
            .filter(({ id }) => id !== null && id !== undefined && id !== 0)
            .map(({ id, title }) => ({ label: title, value: id }))
        titleOptions.unshift({ label: 'Wybierz', value: 0 })

        const columns = [
            { name: 'Konto' },
            { name: 'Kategoria' },
            { name: 'Tytuł' },
            { name: 'Synchronizacja' },
            { name: 'Aktywna' },
        ]

        const values = objectToArray(auctions, (item, localId) => {
            return {
                actions: [
                    <ActionsMenu
                        key={1}
                        activateAuction={activateAuction}
                        auctionNumber={item.auctionNumber}
                        id={item.id}
                        isActive={item.isActive}
                        localId={localId}
                        removeAuction={removeAuction}
                        saveAuctionToAllegro={saveAuctionToAllegro}
                        stopAuction={this.openConfirmDialog}
                        loading={item.fetching}
                    />,
                ],

                columns: [
                    <Select
                        key={1}
                        value={item.accountId}
                        options={accountOptions}
                        onChange={event =>
                            this.props.updateAuction(
                                'accountId',
                                event.target.value,
                                localId
                            )
                        }
                    />,
                    <Select
                        key={2}
                        value={item.categoryId}
                        options={categoryOptions}
                        onChange={event =>
                            this.props.updateAuction(
                                'categoryId',
                                event.target.value,
                                localId
                            )
                        }
                    />,
                    <Select
                        key={3}
                        value={item.titleId}
                        options={titleOptions}
                        onChange={event =>
                            this.props.updateAuction(
                                'titleId',
                                event.target.value,
                                localId
                            )
                        }
                    />,
                    <FormControlLabel
                        key={5}
                        control={
                            <Checkbox
                                checked={item.isSynchronized}
                                onChange={event =>
                                    this.props.updateAuction(
                                        'isSynchronized',
                                        event.target.checked,
                                        localId
                                    )
                                }
                            />
                        }
                        label="Synchronizuj"
                    />,
                    item.isActive ? (
                        <Icon key={6}>thumb_up</Icon>
                    ) : (
                        <Icon key={6}>thumb_down</Icon>
                    ),
                ],
            }
        })

        values.push({
            actions: [
                <IconButton key={1} onClick={this.saveAuction}>
                    <AddIcon />
                </IconButton>,
            ],

            columns: [
                <Select
                    key={1}
                    value={this.state.accountId}
                    options={accountOptions}
                    onChange={event =>
                        this.onChange('accountId', event.target.value)
                    }
                />,
                <Select
                    key={2}
                    value={this.state.categoryId}
                    options={categoryOptions}
                    onChange={event =>
                        this.onChange('categoryId', event.target.value)
                    }
                />,
                <Select
                    key={3}
                    value={this.state.titleId}
                    options={titleOptions}
                    onChange={event =>
                        this.onChange('titleId', event.target.value)
                    }
                />,
                <div key={4}></div>,
                <div key={5}></div>,
            ],
        })

        return (
            <FuseScrollbars>
                <List columns={columns} values={values} />
                <ConfirmDialog
                    title="Deaktywować aukcję?"
                    content=""
                    open={this.state.confirmDialog.isOpen}
                    handleClose={this.closeConfirmDialog}
                    onConfirm={() => {
                        stopAuction(
                            this.state.confirmDialog.id,
                            this.state.confirmDialog.localId
                        )
                        this.closeConfirmDialog()
                    }}
                />
            </FuseScrollbars>
        )
    }
}

export default Auctions
