import React from 'react'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import {withStyles} from '@material-ui/core/styles'

import List from '../../../../components/List'


const styles = theme => ({
    margin: {
        margin: theme.spacing.unit
    },

    root: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },
})


class BundleItemsSearch extends React.Component {
    addItem = (item, event) => {
        event.preventDefault()
        this.props.addItem(item)
    }

    removeItem = (id, event) => {
        event.preventDefault()
        this.props.removeItem(id)
    }

    onChange = (id, e, { value }) => {
        this.props.changeQuantity(id, value)
    }

    render() {
        const { classes } = this.props

        const columns = [
            { name: 'Numer katalogowy' },
            { name: 'Produkt' },
        ]

        const inputs = [
            { name: 'sku', label: 'Numer katalogowy' },
            { name: 'name', label: 'Produkt' },
        ]

        const values = this.props.searchItems.map(item => {
            return {
                actions: [(
                    <Fab
                        key={1}
                        size="medium"
                        color="secondary"
                        aria-label="Add"
                        className={classes.margin}
                        onClick={this.addItem.bind(this, item)}
                    >
                        <Icon>playlist_add</Icon>
                    </Fab>
                )],
                columns: [item.sku, item.name],
                onClick: this.addItem.bind(this, item),
            }
        })

        return (
            <div className={classes.root}>
                <List
                    columns={columns}
                    values={values}
                    inputs={inputs}
                    loading={this.props.searchInProgress}
                    onSearch={this.props.onSearch}
                />
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(BundleItemsSearch)
