import {
    AUCTIONS_FETCHING,
    AUCTIONS_FETCHING_FAILURE,
    AUCTIONS_FETCHING_SUCCESS,
} from '../actions/types'
import Api from '../Api'

export const findAuctions = (filters=[], page=0, pageSize=10) => dispatch => {
    dispatch({
        type: AUCTIONS_FETCHING,
        filters,
    })

    Api.findAuctions(filters, page, pageSize)
        .then(response => {
            dispatch({
                type: AUCTIONS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTIONS_FETCHING_FAILURE,
                error
            })
        })
}


export const getAuctions = page => dispatch => {
    dispatch({ type: AUCTIONS_FETCHING })

    Api.getAuctions(page)
        .then(response => {
            dispatch({
                type: AUCTIONS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTIONS_FETCHING_FAILURE,
                error
            })
        })
}

export const auctionsFilterChange = filters => dispatch => {
    dispatch({
        type: AUCTIONS_FETCHING_SUCCESS,
        filters,
    })
}
