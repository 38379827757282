import { uuid } from '../../helpers'

import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_ADD_AUCTION,
    AUCTION_UPDATE,
    SAVE_AUCTION,
    REMOVE_AUCTION,
    AUCTION_CHANGE_IN_PROGRESS,
    AUCTION_CHANGE_SUCCESS,
    AUCTION_CHANGE_FAILURE,
    PRODUCT_AUCTIONS_FETCHING_SUCCESS,
    AUCTION_EMPHASIZE_REMOVED,
    AUCTION_EMPHASIZE_ADDED,
} from '../../actions/types'


const INITIAL_STATE = {}

const reducer = (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }

    let localId = null

    switch (action.type) {
        case AUCTION_CHANGE_IN_PROGRESS:
            newState[action.localId] = {
                ...newState[action.localId],
                fetching: true,
            }

            return newState

        case AUCTION_CHANGE_FAILURE:
            newState[action.localId] = {
                ...newState[action.localId],
                fetching: false,
            }

            return newState

        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareAuctions(action.payload)

        case AUCTION_CHANGE_SUCCESS:
            newState[action.localId] = {
                ...action.payload,
                fetching: false,
            }

            return newState

        case AUCTION_UPDATE:
            return {
                ...state,
                [action.localId]: {
                    ...state[action.localId],
                    [action.name]: action.value
                }
            }

        case SAVE_AUCTION:
            return {
                ...state,
                [uuid()]: action.auction
            }

        case REMOVE_AUCTION:
            delete newState[action.localId]
            return newState

        case PRODUCT_ADD_AUCTION:
            return {
                ...state,
                [uuid()]: {
                    ...action.payload,
                    fetching: false,
                }
            }

        case PRODUCT_AUCTIONS_FETCHING_SUCCESS:
            newState = {}
            action.payload.forEach(item => { newState[uuid()] = {
                ...item,
                fetching: false,
            }})
            return newState

        case AUCTION_EMPHASIZE_ADDED:
            localId = findLocalId(state, item => {
                return item.id === action.auctionId
            })

            if (localId === null) {
                return state
            }

            return {
                ...state,
                [localId]: {
                    ...state[localId],
                    isEmphasized: true
                }
            }

        case AUCTION_EMPHASIZE_REMOVED:
            localId = findLocalId(state, item => {
                return item.id === action.auctionId
            })

            if (localId === null) {
                return state
            }

            return {
                ...state,
                [localId]: {
                    ...state[localId],
                    isEmphasized: false
                }
            }


        default:
            return state
    }
}

const prepareAuctions = ({ auctions }) => {
    if (!auctions) {
        return []
    }

    const result = {}

    auctions.forEach(item => { result[uuid()] = {
        ...item,
        fetching: false,
    }})

    return result
}


const findLocalId = (state, predicate) => {
    const keys = Object.keys(state)
    const result = keys.find(key => predicate(state[key]))

    if (result === undefined) {
        return null
    }

    return result
}

export default reducer
