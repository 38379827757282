import {
    VERTO_PRODUCT_SEARCHING,
    VERTO_PRODUCT_SEARCHING_SUCCESS,
    VERTO_PRODUCT_SEARCHING_FAILURE,
    VERTO_PRODUCT_SEARCH_CLEAR,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    product: null,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VERTO_PRODUCT_SEARCHING:
            return {
                ...state,
                fetching: true,
            }

        case VERTO_PRODUCT_SEARCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                product: prepare(action.payload)
            }

        case VERTO_PRODUCT_SEARCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case VERTO_PRODUCT_SEARCH_CLEAR:
            return {
                ...state,
                fetching: false,
                product: null,
            }

        default:
            return state
    }
}

const prepare = (data) => {
    if (!data) {
        return null
    }

    const { name, sku, price } = data

    return { name, sku, price }
}

export default reducer
