import {
    AUCTION_UPDATE,
    SAVE_AUCTION,
    REMOVE_AUCTION,
    AUCTION_CHANGE_IN_PROGRESS,
    AUCTION_CHANGE_SUCCESS,
    AUCTION_CHANGE_FAILURE,
    PRODUCT_ADD_AUCTION,
    PRODUCT_AUCTIONS_FETCHING,
    PRODUCT_AUCTIONS_FETCHING_SUCCESS,
    PRODUCT_AUCTIONS_FETCHING_FAILURE,
    AUCTION_EMPHASIZE_ADDED,
    AUCTION_EMPHASIZE_REMOVED,
    AUCTION_EMPHASIZE_ADDING_FAILURE,
    AUCTION_EMPHASIZE_REMOVING_FAILURE,
} from '../types'
import Api from '../../Api'


export const updateAuction = (name, value, localId) => dispatch => {
    dispatch({
        type: AUCTION_UPDATE,
        localId,
        name,
        value
    })
}

export const saveAuction = (auction) => dispatch => {
    dispatch({
        type: SAVE_AUCTION,
        auction
    })
}

export const addAuction = (productId, auction) => dispatch => {
    Api.addProductAuction(productId, auction)
        .then(response => {
            dispatch({
                type: PRODUCT_ADD_AUCTION,
                payload: response.data
            })
        })
}

export const removeAuction = (localId) => dispatch => {
    dispatch({
        type: REMOVE_AUCTION,
        localId
    })
}

export const saveAuctionToAllegro = (id, localId) => dispatch => {
    dispatch({ type: AUCTION_CHANGE_IN_PROGRESS, localId })

    Api.saveAuctionToAllegro(id)
        .then(response => {
            dispatch({
                type: AUCTION_CHANGE_SUCCESS,
                payload: response.data,
                localId,
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTION_CHANGE_FAILURE,
                localId,
                error
            })
        })
}

export const activateAuction = (id, localId) => dispatch => {
    dispatch({ type: AUCTION_CHANGE_IN_PROGRESS, localId })

    Api.activateAuction(id)
        .then(response => {
            dispatch({
                type: AUCTION_CHANGE_SUCCESS,
                localId,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTION_CHANGE_FAILURE,
                localId,
                error
            })
        })
}

export const stopAuction = (id, localId) => dispatch => {
    dispatch({ type: AUCTION_CHANGE_IN_PROGRESS, localId })

    Api.stopAuction(id)
        .then(response => {
            dispatch({
                type: AUCTION_CHANGE_SUCCESS,
                localId,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTION_CHANGE_FAILURE,
                localId,
                error
            })
        })
}

export const getAuctions = sku => dispatch => {
    dispatch({ type: PRODUCT_AUCTIONS_FETCHING })

    Api.getProductAuctions(sku)
        .then(response => {
            dispatch({
                type: PRODUCT_AUCTIONS_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_AUCTIONS_FETCHING_FAILURE,
                error,
            })
        })
}

export const removeEmphasize = auctionId => dispatch => {
    Api.removeEmphasize(auctionId)
        .then(() => {
            dispatch({
                type: AUCTION_EMPHASIZE_REMOVED,
                auctionId,
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTION_EMPHASIZE_REMOVING_FAILURE,
                error
            })
        })
}

export const addEmphasize = auctionId => dispatch => {
    Api.addEmphasize(auctionId)
        .then(() => {
            dispatch({
                type: AUCTION_EMPHASIZE_ADDED,
                auctionId,
            })
        })
        .catch(error => {
            dispatch({
                type: AUCTION_EMPHASIZE_ADDING_FAILURE,
                error
            })
        })
}
