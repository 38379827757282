import {
    PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE,
    PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE,
    PRODUCT_DELIVERY_QUANTITY_CHANGE,
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
} from '../../actions/types'


const INITIAL_STATE = {
    deliveryOptions: []
}

const reducer = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return state

        case PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE:
            return {
                ...state,
                deliveryOptions: state.deliveryOptions.map(item => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            firstPieceCost: Number(action.value)
                        }
                    } else {
                        return item
                    }
                })
            }

        case PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE:
            return {
                ...state,
                deliveryOptions: state.deliveryOptions.map(item => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            nextPiecesCost: Number(action.value)
                        }
                    } else {
                        return item
                    }
                })
            }

        case PRODUCT_DELIVERY_QUANTITY_CHANGE:
            return {
                ...state,
                deliveryOptions: state.deliveryOptions.map(item => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            quantity: Number(action.value)
                        }
                    } else {
                        return item
                    }
                })
            }

        default:
            return state

    }
}

export default reducer
