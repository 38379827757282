export const PRODUCT_ADD_IMAGE = 'PRODUCT_ADD_IMAGE'
export const PRODUCT_ADD_TITLE = 'PRODUCT_ADD_TITLE'
export const PRODUCT_ADD_AUCTION = 'PRODUCT_ADD_AUCTION'
export const PRODUCT_ATTRIBUTE_CHANGE = 'PRODUCT_ATTRIBUTE_CHANGE'
export const PRODUCT_FETCHING = 'PRODUCT_FETCHING'
export const PRODUCT_FETCHING_FAILURE = 'PRODUCT_FETCHING_FAILURE'
export const PRODUCT_FETCHING_SUCCESS = 'PRODUCT_FETCHING_SUCCESS'
export const PRODUCT_FORM_TITLE_UPDATE = 'PRODUCT_FORM_TITLE_UPDATE'
export const PRODUCT_FORM_UPDATE = 'PRODUCT_FORM_UPDATE'
export const PRODUCT_REMOVE_IMAGE = 'PRODUCT_REMOVE_IMAGE'
export const PRODUCT_REMOVE_TITLE = 'PRODUCT_REMOVE_TITLE'
export const PRODUCT_SAVING_FAILURE = 'PRODUCT_SAVING_FAILURE'
export const PRODUCT_SAVING_IN_PROGRESS = 'PRODUCT_SAVING_IN_PROGRESS'
export const PRODUCT_SAVING_SUCCESS = 'PRODUCT_SAVING_SUCCESS'
export const PRODUCT_SET_MAIN_IMAGE = 'PRODUCT_SET_MAIN_IMAGE'
export const PRODUCT_SET_ALLEGRO_MINI = 'PRODUCT_SET_ALLEGRO_MINI'
export const PRODUCT_DESCRIPTION_UPDATE = 'PRODUCT_DESCRIPTION_UPDATE'
export const PRODUCT_ADD_DESCRIPTION_ITEM = 'PRODUCT_ADD_DESCRIPTION_ITEM'
export const PRODUCT_REMOVE_DESCRIPTION_ITEM = 'PRODUCT_REMOVE_DESCRIPTION_ITEM'
export const PRODUCT_CATEGORY_ADDING = 'PRODUCT_CATEGORY_ADDING'
export const PRODUCT_CATEGORY_ADDING_SUCCESS = 'PRODUCT_CATEGORY_ADDING_SUCCESS'
export const PRODUCT_CATEGORY_ADDING_FAILURE = 'PRODUCT_CATEGORY_ADDING_FAILURE'
export const PRODUCT_CATEGORY_REMOVE = 'PRODUCT_CATEGORY_REMOVE'
export const PRODUCT_CATEGORIES_CHANGE_IN_PROGRESS =
    'PRODUCT_CATEGORIES_CHANGE_IN_PROGRESS'
export const PRODUCT_CATEGORIES_CHANGE_SUCCESS =
    'PRODUCT_CATEGORIES_CHANGE_SUCCESS'
export const PRODUCT_CATEGORIES_CHANGE_FAILURE =
    'PRODUCT_CATEGORIES_CHANGE_FAILURE'
export const PRODUCT_REORDER_DESCRIPTION = 'PRODUCT_REORDER_DESCRIPTION'
export const PRODUCT_ADD_MAGENTO_CATEGORY = 'PRODUCT_ADD_MAGENTO_CATEGORY'
export const PRODUCT_REMOVE_MAGENTO_CATEGORY = 'PRODUCT_REMOVE_MAGENTO_CATEGORY'
export const PRODUCT_ADD_MAGENTO_CATEGORY_FOR_ELTROX =
    'PRODUCT_ADD_MAGENTO_CATEGORY_FOR_ELTROX'
export const PRODUCT_REMOVE_MAGENTO_CATEGORY_FOR_ELTROX =
    'PRODUCT_REMOVE_MAGENTO_CATEGORY_FOR_ELTROX'
export const TOGGLE_NOT_FOR_GALLERY = 'TOGGLE_NOT_FOR_GALLERY'
export const PRODUCT_SET_IMAGE_SORT = 'PRODUCT_SET_IMAGE_SORT'
export const SWAP_BUNDLE_ITEM = 'SWAP_BUNDLE_ITEM'

export const PRODUCT_LIST_FETCHING = 'PRODUCT_LIST_FETCHING'
export const PRODUCT_LIST_FETCHING_FAILURE = 'PRODUCT_LIST_FETCHING_FAILURE'
export const PRODUCT_LIST_FETCHING_SUCCESS = 'PRODUCT_LIST_FETCHING_SUCCESS'

export const DESCRIPTION_ITEM_TYPES_FETCHING = 'DESCRIPTION_ITEM_TYPES_FETCHING'
export const DESCRIPTION_ITEM_TYPES_FETCHING_SUCCESS =
    'DESCRIPTION_ITEM_TYPES_FETCHING_SUCCESS'
export const DESCRIPTION_ITEM_TYPES_FETCHING_FAILURE =
    'DESCRIPTION_ITEM_TYPES_FETCHING_FAILURE'

export const SEARCH_CATEGORIES_FETCHING = 'SEARCH_CATEGORIES_FETCHING'
export const SEARCH_CATEGORIES_FETCHING_SUCCESS =
    'SEARCH_CATEGORIES_FETCHING_SUCCESS'
export const SEARCH_CATEGORIES_FETCHING_FAILURE =
    'SEARCH_CATEGORIES_FETCHING_FAILURE'

export const DELIVERY_OPTION_TYPES_FETCHING = 'DELIVERY_OPTION_TYPES_FETCHING'
export const DELIVERY_OPTION_TYPES_FETCHING_SUCCESS =
    'DELIVERY_OPTION_TYPES_FETCHING_SUCCESS'
export const DELIVERY_OPTION_TYPES_FETCHING_FAILURE =
    'DELIVERY_OPTION_TYPES_FETCHING_FAILURE'

export const PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE =
    'PRODUCT_DELIVERY_FIRST_PIECE_COST_CHANGE'
export const PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE =
    'PRODUCT_DELIVERY_NEXT_PIECES_COST_CHANGE'
export const PRODUCT_DELIVERY_QUANTITY_CHANGE =
    'PRODUCT_DELIVERY_QUANTITY_CHANGE'
export const PRODUCT_TOGGLE_COLLECTION_IN_PERSON =
    'PRODUCT_TOGGLE_COLLECTION_IN_PERSON'
export const PRODUCT_TOGGLE_COLLECTION_IN_PERSON_AFTER_PAYMENT =
    'PRODUCT_TOGGLE_COLLECTION_IN_PERSON_AFTER_PAYMENT'

export const ALLEGRO_ACCOUNTS_FETCHING = 'ALLEGRO_ACCOUNTS_FETCHING'
export const ALLEGRO_ACCOUNTS_FETCHING_SUCCESS =
    'ALLEGRO_ACCOUNTS_FETCHING_SUCCESS'
export const ALLEGRO_ACCOUNTS_FETCHING_FAILURE =
    'ALLEGRO_ACCOUNTS_FETCHING_FAILURE'

export const AUCTION_UPDATE = 'AUCTION_UPDATE'
export const SAVE_AUCTION = 'SAVE_AUCTION'
export const REMOVE_AUCTION = 'REMOVE_AUCTION'
export const AUCTION_CHANGE_IN_PROGRESS = 'AUCTION_CHANGE_IN_PROGRESS'
export const AUCTION_CHANGE_SUCCESS = 'AUCTION_CHANGE_SUCCESS'
export const AUCTION_CHANGE_FAILURE = 'AUCTION_CHANGE_FAILURE'

export const DELIVERIES_FETCHING = 'DELIVERIES_FETCHING'
export const DELIVERIES_FETCHING_SUCCESS = 'DELIVERIES_FETCHING_SUCCESS'
export const DELIVERIES_FETCHING_FAILURE = 'DELIVERIES_FETCHING_FAILURE'

export const ADD_ERROR = 'ADD_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const STATIC_BLOCKS_FETCHING = 'STATIC_BLOCKS_FETCHING'
export const STATIC_BLOCKS_FETCHING_SUCCESS = 'STATIC_BLOCKS_FETCHING_SUCCESS'
export const STATIC_BLOCKS_FETCHING_FAILURE = 'STATIC_BLOCKS_FETCHING_FAILURE'

export const STATIC_BLOCK_FETCHING = 'STATIC_BLOCK_FETCHING'
export const STATIC_BLOCK_FETCHING_SUCCESS = 'STATIC_BLOCK_FETCHING_SUCCESS'
export const STATIC_BLOCK_FETCHING_FAILURE = 'STATIC_BLOCK_FETCHING_FAILURE'
export const STATIC_BLOCK_CHANGE = 'STATIC_BLOCK_CHANGE'

export const STATIC_BLOCK_SAVING_IN_PROGRESS = 'STATIC_BLOCK_SAVING_IN_PROGRESS'
export const STATIC_BLOCK_SAVING_SUCCESS = 'STATIC_BLOCK_SAVING_SUCCESS'
export const STATIC_BLOCK_SAVING_FAILURE = 'STATIC_BLOCK_SAVING_FAILURE'

export const STATIC_BLOCK_CLEAR = 'STATIC_BLOCK_CLEAR'

export const ADD_ATTRIBUTE_TO_STATIC_BLOCK = 'ADD_ATTRIBUTE_TO_STATIC_BLOCK'
export const REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK =
    'REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK'

export const PRODUCT_BUNDLE_ITEM_ADD = 'PRODUCT_BUNDLE_ITEM_ADD'
export const PRODUCT_BUNDLE_ITEM_REMOVE = 'PRODUCT_BUNDLE_ITEM_REMOVE'

export const BUNDLE_ITEMS_FETCHING = 'BUNDLE_ITEMS_FETCHING'
export const BUNDLE_ITEMS_FETCHING_SUCCESS = 'BUNDLE_ITEMS_FETCHING_SUCCESS'
export const BUNDLE_ITEMS_FETCHING_FAILURE = 'BUNDLE_ITEMS_FETCHING_FAILURE'
export const BUNDLE_ITEM_CHANGE_QUANTITY = 'BUNDLE_ITEM_CHANGE_QUANTITY'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

export const VERTO_PRODUCT_SEARCHING = 'VERTO_PRODUCT_SEARCHING'
export const VERTO_PRODUCT_SEARCHING_SUCCESS = 'VERTO_PRODUCT_SEARCHING_SUCCESS'
export const VERTO_PRODUCT_SEARCHING_FAILURE = 'VERTO_PRODUCT_SEARCHING_FAILURE'
export const VERTO_PRODUCT_SEARCH_CLEAR = 'VERTO_PRODUCT_SEARCH_CLEAR'

export const VERTO_PRODUCT_UPDATE_IN_PROGRESS =
    'VERTO_PRODUCT_UPDATE_IN_PROGRESS'
export const VERTO_PRODUCT_UPDATE_SUCCESS = 'VERTO_PRODUCT_UPDATE_SUCCESS'
export const VERTO_PRODUCT_UPDATE_FAILURE = 'VERTO_PRODUCT_UPDATE_FAILURE'

export const DELIVERY_FETCHING = 'DELIVERY_FETCHING'
export const DELIVERY_FETCHING_SUCCESS = 'DELIVERY_FETCHING_SUCCESS'
export const DELIVERY_FETCHING_FAILURE = 'DELIVERY_FETCHING_FAILURE'
export const DELIVERY_UPDATED = 'DELIVERY_UPDATED'
export const DELIVERY_SAVING = 'DELIVERY_SAVING'
export const DELIVERY_SAVING_SUCCESS = 'DELIVERY_SAVING_SUCCESS'
export const DELIVERY_SAVING_FAILURE = 'DELIVERY_SAVING_FAILURE'
export const DELIVERY_CLEAR = 'DELIVERY_CLEAR'

export const CATEGORY_IMPORT_IN_PROGRESS = 'CATEGORY_IMPORT_IN_PROGRESS'
export const CATEGORY_IMPORT_SUCCESS = 'CATEGORY_IMPORT_SUCCESS'
export const CATEGORY_IMPORT_FAILURE = 'CATEGORY_IMPORT_FAILURE'

export const NEW_DESCRIPTION_CHANGE = 'NEW_DESCRIPTION_CHANGE'
export const NEW_DESCRIPTION_CLEAR = 'NEW_DESCRIPTION_CLEAR'

export const ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING =
    'ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING'
export const ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS =
    'ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS'
export const ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE =
    'ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE'
export const ALLEGRO_ACCOUNTS_CLOSE_MODAL = 'ALLEGRO_ACCOUNTS_CLOSE_MODAL'

export const VERTO_ATTRIBUTES_FETCHING = 'VERTO_ATTRIBUTES_FETCHING'
export const VERTO_ATTRIBUTES_FETCHING_SUCCESS =
    'VERTO_ATTRIBUTES_FETCHING_SUCCESS'
export const VERTO_ATTRIBUTES_FETCHING_FAILURE =
    'VERTO_ATTRIBUTES_FETCHING_FAILURE'

export const VERTO_ATTRIBUTE_FETCHING = 'VERTO_ATTRIBUTE_FETCHING'
export const VERTO_ATTRIBUTE_FETCHING_SUCCESS =
    'VERTO_ATTRIBUTE_FETCHING_SUCCESS'
export const VERTO_ATTRIBUTE_FETCHING_FAILURE =
    'VERTO_ATTRIBUTE_FETCHING_FAILURE'

export const PRODUCT_ATTRIBUTES_FETCHING = 'PRODUCT_ATTRIBUTES_FETCHING'
export const PRODUCT_ATTRIBUTES_FETCHING_SUCCESS =
    'PRODUCT_ATTRIBUTES_FETCHING_SUCCESS'
export const PRODUCT_ATTRIBUTES_FETCHING_FAILURE =
    'PRODUCT_ATTRIBUTES_FETCHING_FAILURE'

export const ATTRIBUTE_FETCHING = 'ATTRIBUTE_FETCHING'
export const ATTRIBUTE_FETCHING_SUCCESS = 'ATTRIBUTE_FETCHING_SUCCESS'
export const ATTRIBUTE_FETCHING_FAILURE = 'ATTRIBUTE_FETCHING_FAILURE'
export const ATTRIBUTE_ADD_MAPPING = 'ATTRIBUTE_ADD_MAPPING'
export const ATTRIBUTE_REMOVE_MAPPING = 'ATTRIBUTE_REMOVE_MAPPING'

export const ALLEGRO_TAB_CHANGE = 'ALLEGRO_TAB_CHANGE'
export const PRODUCT_TAB_CHANGE = 'PRODUCT_TAB_CHANGE'

export const WARRANTIES_FETCHING = 'WARRANTIES_FETCHING'
export const WARRANTIES_FETCHING_SUCCESS = 'WARRANTIES_FETCHING_SUCCESS'
export const WARRANTIES_FETCHING_FAILURE = 'WARRANTIES_FETCHING_FAILURE'

export const AUCTIONS_FETCHING = 'AUCTIONS_FETCHING'
export const AUCTIONS_FETCHING_SUCCESS = 'AUCTIONS_FETCHING_SUCCESS'
export const AUCTIONS_FETCHING_FAILURE = 'AUCTIONS_FETCHING_FAILURE'

export const AUCTIONS_FILTER_CHANGE = 'AUCTIONS_FILTER_CHANGE'

export const PRODUCT_STATIC_BLOCKS_FETCHING = 'PRODUCT_STATIC_BLOCKS_FETCHING '
export const PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS =
    'PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS '
export const PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE =
    'PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE '

export const MAGENTO_CATEGORIES_FETCHING = 'MAGENTO_CATEGORIES_FETCHING '
export const MAGENTO_CATEGORIES_FETCH_SUCCESS =
    'MAGENTO_CATEGORIES_FETCH_SUCCESS '
export const MAGENTO_CATEGORIES_FETCH_FAILURE =
    'MAGENTO_CATEGORIES_FETCH_FAILURE '
export const MAGENTO_CATEGORIES_CHANGE = 'MAGENTO_CATEGORIES_CHANGE'

export const MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX =
    'MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX'
export const MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX =
    'MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX'
export const MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX =
    'MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX'
export const MAGENTO_CATEGORIES_CHANGE_FOR_ELTROX =
    'MAGENTO_CATEGORIES_CHANGE_FOR_ELTROX'

export const PRODUCT_ERRORS_FETCHING = 'PRODUCT_ERRORS_FETCHING'
export const PRODUCT_ERRORS_FETCHING_SUCCESS = 'PRODUCT_ERRORS_FETCHING_SUCCESS'
export const PRODUCT_ERRORS_FETCHING_FAILURE = 'PRODUCT_ERRORS_FETCHING_FAILURE'
export const PRODUCT_ERRORS_CHANGE_FILTER = 'PRODUCT_ERRORS_CHANGE_FILTER'

export const PRODUCT_HISTORY_FETCH = 'PRODUCT_HISTORY_FETCH'

export const USERS_FETCHING = 'USERS_FETCHING'
export const USERS_FETCHING_SUCCESS = 'USERS_FETCHING_SUCCESS'
export const USERS_FETCHING_FAILURE = 'USERS_FETCHING_FAILURE'

export const OPERATION_REGISTRY_FETCHING = 'OPERATION_REGISTRY_FETCHING'
export const OPERATION_REGISTRY_FETCHING_SUCCESS =
    'OPERATION_REGISTRY_FETCHING_SUCCESS'
export const OPERATION_REGISTRY_FETCHING_FAILURE =
    'OPERATION_REGISTRY_FETCHING_FAILURE'
export const OPERATION_REGISTRY_FILTER_CHANGE =
    'OPERATION_REGISTRY_FILTER_CHANGE'

export const PRODUCT_CLONING_IN_PROGRESS = 'PRODUCT_CLONING_IN_PROGRESS'
export const PRODUCT_CLONING_FAILURE = 'PRODUCT_CLONING_FAILURE'
export const PRODUCT_CLONING_SUCCESS = 'PRODUCT_CLONING_SUCCESS'
export const PRODUCT_CLONING_FORM_OPEN = 'PRODUCT_CLONING_FORM_OPEN'
export const PRODUCT_CLONING_FORM_CLOSE = 'PRODUCT_CLONING_FORM_CLOSE'

export const NOTIFICATIONS_FETCHING = 'NOTIFICATIONS_FETCHING'
export const NOTIFICATIONS_FETCHING_SUCCESS = 'NOTIFICATIONS_FETCHING_SUCCESS'
export const NOTIFICATIONS_FETCHING_FAILURE = 'NOTIFICATIONS_FETCHING_FAILURE'
export const NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ'

export const PRODUCT_AUCTIONS_FETCHING = 'PRODUCT_AUCTIONS_FETCHING'
export const PRODUCT_AUCTIONS_FETCHING_SUCCESS =
    'PRODUCT_AUCTIONS_FETCHING_SUCCESS'
export const PRODUCT_AUCTIONS_FETCHING_FAILURE =
    'PRODUCT_AUCTIONS_FETCHING_FAILURE'

export const INCUBO_EVENTS_FETCHING = 'INCUBO_EVENTS_FETCHING'
export const INCUBO_EVENTS_FETCHING_SUCCESS = 'INCUBO_EVENTS_FETCHING_SUCCESS'
export const INCUBO_EVENTS_FETCHING_FAILURE = 'INCUBO_EVENTS_FETCHING_FAILURE'

export const INCUBO_OFFERS_FETCHING = 'INCUBO_OFFERS_FETCHING'
export const INCUBO_OFFERS_FETCHING_SUCCESS = 'INCUBO_OFFERS_FETCHING_SUCCESS'
export const INCUBO_OFFERS_FETCHING_FAILURE = 'INCUBO_OFFERS_FETCHING_FAILURE'

export const INCUBO_OFFER_FETCHING = 'INCUBO_OFFER_FETCHING'
export const INCUBO_OFFER_FETCHING_SUCCESS = 'INCUBO_OFFER_FETCHING_SUCCESS'
export const INCUBO_OFFER_FETCHING_FAILURE = 'INCUBO_OFFER_FETCHING_FAILURE'
export const INCUBO_OFFER_PAIRING_SUCCESS = 'INCUBO_OFFER_PAIRING_SUCCESS'
export const INCUBO_OFFER_PAIRING_FAILURE = 'INCUBO_OFFER_PAIRING_FAILURE'

export const AUCTION_EMPHASIZE_REMOVED = 'AUCTION_EMPHASIZE_REMOVED'
export const AUCTION_EMPHASIZE_REMOVING_FAILURE =
    'AUCTION_EMPHASIZE_REMOVING_FAILURE'
export const AUCTION_EMPHASIZE_ADDED = 'AUCTION_EMPHASIZE_ADDED'
export const AUCTION_EMPHASIZE_ADDING_FAILURE =
    'AUCTION_EMPHASIZE_ADDING_FAILURE'
export const PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS =
    'PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS'
export const PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE =
    'PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE '

export const REAL_STOCK_IN_CATEGORIES_FETCHING =
    'REAL_STOCK_IN_CATEGORIES_FETCHING'
export const REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS =
    'REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS'
export const REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE =
    'REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE'

export const SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS =
    'SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS'
export const SAVE_REAL_STOCK_IN_CATEGORY_FAILURE =
    'SAVE_REAL_STOCK_IN_CATEGORY_FAILURE'

export const UPDATING_DESCRIPTION_IN_BASELINKER =
    'UPDATING_DESCRIPTION_IN_BASELINKER'
export const UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS =
    'UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS'
export const UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE =
    'UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE'

export const NEW_POPUP = 'NEW_POPUP'
export const REMOVE_POPUP = 'REMOVE_POPUP'

export const PRESTASHOP_CATEGORIES_FETCHING = 'PRESTASHOP_CATEGORIES_FETCHING'
export const PRESTASHOP_CATEGORIES_FETCH_SUCCESS =
    'PRESTASHOP_CATEGORIES_FETCH_SUCCESS'
export const PRESTASHOP_CATEGORIES_FETCH_FAILURE =
    'PRESTASHOP_CATEGORIES_FETCH_FAILURE'
export const PRESTASHOP_PRODUCT_CATEGORIES_FETCHING =
    'PRESTASHOP_PRODUCT_CATEGORIES_FETCHING'
export const PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS =
    'PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS'
export const PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE =
    'PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE'
export const PRESTASHOP_PRODUCT_CATEGORIES_UPDATING =
    'PRESTASHOP_PRODUCT_CATEGORIES_UPDATING'
export const PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS =
    'PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS'
export const PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_FAILURE =
    'PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_FAILURE'

export const ICONS_FETCHING = 'ICONS_FETCHING'
export const ICONS_FETCHING_SUCCESS = 'ICONS_FETCHING_SUCCESS'
export const ICONS_FETCHING_FAILURE = 'ICONS_FETCHING_FAILURE'
