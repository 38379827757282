import {TextField} from '@material-ui/core'
import React from 'react'


const Input = props => {
    let { value } = props

    if (value === null) {
        value = ''
    }

    return (
        <TextField
            {...props}
            value={value}
        />
    )
}

export default Input
