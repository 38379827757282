import {
    PRESTASHOP_CATEGORIES_FETCHING,
    PRESTASHOP_CATEGORIES_FETCH_SUCCESS,
    PRESTASHOP_CATEGORIES_FETCH_FAILURE,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCHING,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE,
    // PRESTASHOP_PRODUCT_CATEGORIES_UPDATING,
    PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS,
    // PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_FAILURE,
} from '../actions/types'

const INITIAL_STATE = {
    all: {
        loading: false,
        items: {},
    },
    selected: {
        loading: false,
        items: [],
    },
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRESTASHOP_CATEGORIES_FETCHING:
            return {
                ...state,
                all: {
                    ...state.all,
                    loading: true,
                },
            }

        case PRESTASHOP_CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                all: {
                    ...state.all,
                    loading: false,
                    items: processCategories(action.payload),
                },
            }

        case PRESTASHOP_CATEGORIES_FETCH_FAILURE:
            return {
                ...state,
                all: {
                    ...state.all,
                    loading: false,
                    items: [],
                },
            }

        case PRESTASHOP_PRODUCT_CATEGORIES_FETCHING:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: true,
                },
            }

        case PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: false,
                    items: processSelectedCategories(action.payload.items),
                },
            }

        case PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: false,
                    items: action.payload,
                },
            }

        case PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    loading: false,
                    items: [],
                },
            }

        default:
            return state
    }
}

const processCategories = data => {
    const result = {}

    data.forEach(category => {
        if (result.hasOwnProperty(category.id)) {
            result[category.id] = {
                ...result[category.id],
                name: category.name,
                id: category.id,
                parent: category.idParent,
            }
        } else {
            result[category.id] = {
                name: category.name,
                id: category.id,
                parent: category.idParent,
                children: [],
            }
        }

        if (category.idParent !== 0) {
            if (result.hasOwnProperty(category.idParent)) {
                result[category.idParent].children.push(category.id)
            } else {
                result[category.idParent] = {
                    children: [category.id],
                }
            }
        }
    })

    return result
}

const processSelectedCategories = data => {
    return data.map(categoryId => {
        return {
            categoryId: categoryId,
        }
    })
}

export default reducer
