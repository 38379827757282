import {
    PRODUCT_REMOVE_DESCRIPTION_ITEM,
    PRODUCT_DESCRIPTION_UPDATE,
    PRODUCT_ADD_DESCRIPTION_ITEM,
    PRODUCT_REORDER_DESCRIPTION,
} from './types'


export const removeDescriptionItem = (localId) => dispatch => {
    dispatch({
        type: PRODUCT_REMOVE_DESCRIPTION_ITEM,
        localId
    })
}

export const updateDescription = (localId, name, value) => dispatch => {
    dispatch({
        type: PRODUCT_DESCRIPTION_UPDATE,
        localId,
        name,
        value,
    })
}

export const reorderDescription = (oldIndex, newIndex) => dispatch => {
    dispatch({
        type: PRODUCT_REORDER_DESCRIPTION,
        oldIndex,
        newIndex,
    })
}

export const addDescriptionItem = (descriptionItem) => dispatch => {
    dispatch({
        type: PRODUCT_ADD_DESCRIPTION_ITEM,
        descriptionItem
    })
}

