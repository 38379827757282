import Api from '../Api'

import {
    UPDATING_DESCRIPTION_IN_BASELINKER,
    UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS,
    UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE,
    NEW_POPUP,
} from './types'

export const updateDescriptionInBaselinker = sku => dispatch => {
    dispatch({ type: UPDATING_DESCRIPTION_IN_BASELINKER })

    Api.updateDescriptionInBaselinker(sku)
        .then(_ => {
            dispatch({ type: UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS })
            dispatch({
                type: NEW_POPUP,
                text: 'Zaktualizowano w Baselinkerze',
                severity: 'success',
            })
        })
        .catch(error => {
            dispatch({
                type: UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE,
                error,
            })
            dispatch({
                type: NEW_POPUP,
                text: 'Nie udało się zaktualizować w Baselinkerze',
                severity: 'error',
            })
        })
}
