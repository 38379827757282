import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tree from '../../../../components/Tree'

const styles = {}

class CategoryList extends React.Component {
    onCheck = id => {
        const categories = this.props.selectedCategories
        const { items: allCategories } = this.props.categories

        const category = allCategories[id]

        if (categories.includes(id)) {
            this.props.removeCategory(id)
        } else {
            this.props.addCategory(category)
        }
    }

    render() {
        const { selectedCategories, categories } = this.props

        return (
            <div>
                <Tree
                    data={categories.items}
                    selected={selectedCategories}
                    onCheck={this.onCheck}
                    rootId={2}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(CategoryList)
