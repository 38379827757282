import { toCamelCase } from '../helpers'

import {
    ALLEGRO_ACCOUNTS_FETCHING,
    ALLEGRO_ACCOUNTS_FETCHING_SUCCESS,
    ALLEGRO_ACCOUNTS_FETCHING_FAILURE
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: []
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALLEGRO_ACCOUNTS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case ALLEGRO_ACCOUNTS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload)
            }

        case ALLEGRO_ACCOUNTS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}

const prepareItems = (data) => {
    const { items } = data
    return items.map(({ id, name }) => toCamelCase({id, name}))
}

export default reducer

