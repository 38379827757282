import React from 'react'
import {Button, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import Page from '../../../components/Page'
import List from '../../../components/List'


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },
})


class StaticBlockList extends React.Component {
    componentDidMount() {
        this.props.getStaticBlocks()
    }

    addStaticBlock = () => {
        this.props.redirect('static-block')
    }

    getStaticBlocks = (event, page) => {
        const { pageSize } = this.props
        page += 1

        this.props.getStaticBlocks(page, pageSize)
    }

    onChangePageSize = pageSize => {
        const page = 0
        this.props.getStaticBlocks(page, pageSize)
    }

    render() {
        const { classes } = this.props

        const values = this.props.items.map(item => {
            return {
                actions: [],
                columns: [item.header],
                onClick: () => this.props.redirect(`/static-block/${item.id}`)
            }
        })

        const columns = [
            { name: 'Nazwa bloku' }
        ]

        return (
            <Page
                pageTitle="Bloki statyczne"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">art_track</Icon>
                )}

                content={(
                    <>
                        <List
                            columns={columns}
                            values={values}
                            loading={this.props.fetching}
                            onChangePage={this.getStaticBlocks}
                            onChangeRowsPerPage={event => this.onChangePageSize(event.target.value)}
                            page={this.props.page - 1}
                            paginated={true}
                            rowsPerPage={this.props.pageSize}
                        />
                        <Button
                            onClick={this.addStaticBlock}
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            <Icon className={classes.leftIcon}>add</Icon>
                            Dodaj nowy
                        </Button>
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles)(StaticBlockList)
