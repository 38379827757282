import {
    REAL_STOCK_IN_CATEGORIES_FETCHING,
    REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS,
    REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE,

    SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS,
} from '../actions/types'

const INITIAL_STATE = {
    loading: false,
    items: {},
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REAL_STOCK_IN_CATEGORIES_FETCHING:
            return {
                ...state,
                loading: true
            }

        case REAL_STOCK_IN_CATEGORIES_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                items: prepareItems(action.payload)
            }

        case REAL_STOCK_IN_CATEGORIES_FETCHING_FAILURE:
            return {
                ...state,
                loading: false,
            }

        case SAVE_REAL_STOCK_IN_CATEGORY_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.id]: action.payload
                }
            }
            
        default:
            return state
    }
}

export default reducer

const prepareItems = data => {
    const result = {}
    data.forEach(item => {
        result[item.id] = item
    })

    return result
}
