import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'


const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing.unit,
    }
})


const IconButton = props => {
    const {
        icon,
        variant,
        color,
        onClick,
        children,
        classes,
        style,
    } = props

    let { disabled, size } = props

    if (!['small', 'medium', 'large'].includes(size)) {
        size = 'medium'
    }

    const iconAdditionalStyles = { }

    if (children === undefined || children === null) {
        iconAdditionalStyles.marginRight = 0
    }

    return (
        <Button
            variant={variant}
            disabled={disabled}
            color={color}
            onClick={onClick}
            style={style}
            size={size}
        >
            <Icon
                size={size}
                className={classes.leftIcon}
                style={iconAdditionalStyles}
            >
                {icon}
            </Icon>
            {children}
        </Button>
    )
}


IconButton.propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['text', 'outlined', 'contained', 'fab', 'extendedFab', 'flat', 'raised']),
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    icon: PropTypes.string.isRequired,
    classes: PropTypes.object,
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    children: PropTypes.node,
}

IconButton.defaultProps = {
    disabled: false,
    size: 'medium',
    style: {},
    classes: {
        leftIcon: {}
    },
    color: 'primary',
    children: null,
    variant: 'contained'
}


export default withStyles(styles)(IconButton)
