import React from 'react'
import {withStyles} from '@material-ui/core/styles'

import CategoryList from './categories/CategoryList'
import AttributeList from './categories/AttributeList'


const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    }
})


const Categories = (props) => {
    return (
        <div>
            <h3 className={props.classes.h}>Lista kategorii</h3>
            <CategoryList
                onSearch={props.onSearch}
                categories={props.categories}
                product={props.product}
                removeCategoryFromProduct={props.removeCategoryFromProduct}
                addCategoryToProduct={props.addCategoryToProduct}
            />

            <h3 className={props.classes.h}>Atrybuty kategorii</h3>
            <AttributeList
                product={props.product}
                onAttributeChange={props.updateAttribute}
                importCategory={props.importCategory}
                getAttribute={props.getAttribute}
                attribute={props.attribute}
                addAttributeMapping={props.addAttributeMapping}
                removeAttributeMapping={props.removeAttributeMapping}
            />
        </div>
    )
}

export default withStyles(styles, {withTheme: true})(Categories)
