import Api from '../Api'
import {
    VERTO_ATTRIBUTES_FETCHING,
    VERTO_ATTRIBUTES_FETCHING_FAILURE,
    VERTO_ATTRIBUTES_FETCHING_SUCCESS,
    VERTO_ATTRIBUTE_FETCHING,
    VERTO_ATTRIBUTE_FETCHING_FAILURE,
    VERTO_ATTRIBUTE_FETCHING_SUCCESS,
} from './types'

export const findAttributesByName = value => dispatch => {
    dispatch({
        type: VERTO_ATTRIBUTES_FETCHING,
    })

    Api.findAttributesInVertoByName(value)
        .then(response => {
            dispatch({
                type: VERTO_ATTRIBUTES_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: VERTO_ATTRIBUTES_FETCHING_FAILURE,
                payload: error,
            })
        })
}

export const clearAttributes = () => dispatch => {
    dispatch({
        type: VERTO_ATTRIBUTES_FETCHING_SUCCESS,
        payload: [],
    })
}

export const getAttribute = idAttribute => dispatch => {
    dispatch({ type: VERTO_ATTRIBUTE_FETCHING })

    Api.getVertoAttribute(idAttribute)
        .then(response => {
            dispatch({
                type: VERTO_ATTRIBUTE_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: VERTO_ATTRIBUTE_FETCHING_FAILURE,
                payload: error,
            })
        })
}
