import {
    PRODUCT_FETCHING,
    PRODUCT_FETCHING_FAILURE,
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_FORM_UPDATE,
    PRODUCT_SAVING_FAILURE,
    PRODUCT_SAVING_IN_PROGRESS,
    PRODUCT_SAVING_SUCCESS,
    VERTO_PRODUCT_UPDATE_IN_PROGRESS,
    VERTO_PRODUCT_UPDATE_SUCCESS,
    VERTO_PRODUCT_UPDATE_FAILURE,
    PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS,
} from '../../actions/types'

const INITIAL_STATE = {
    product: {
        deliveryId: null,
        id: null,
        name: '',
        price2: '',
        price: '',
        quantity: '',
        shortDescription: '',
        sku: '',
        isBundle: false,
        grossWeight: '',
        netWeight: '',
        height: '',
        width: '',
        depth: '',
        ean: '',
        warranty: '',
        manageAuctionsActivation: true,
        minimalStock: 0,
        activateAuctionAboveStock: 3,
        rotation: 0,
        purchasePrice: 0,
        stock: 0,
        allegroProducts: [],
    },
    fetching: false,
    changed: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_FETCHING:
        case PRODUCT_SAVING_IN_PROGRESS:
        case VERTO_PRODUCT_UPDATE_IN_PROGRESS:
            return {
                ...state,
                fetching: true,
            }

        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
        case VERTO_PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                fetching: false,
                changed: false,
                product: {
                    ...state.product,
                    ...prepareProduct(action.payload),
                },
            }

        case PRODUCT_FETCHING_FAILURE:
        case PRODUCT_SAVING_FAILURE:
        case VERTO_PRODUCT_UPDATE_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case PRODUCT_FORM_UPDATE:
            return {
                ...state,
                changed: true,
                product: { ...state.product, [action.name]: action.value },
            }

        case PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS:
            return {
                ...state,
                product: {
                    ...state.product,
                    rotation: action.payload.rotation,
                    purchasePrice: action.payload.purchasePrice,
                    stock: action.payload.stock,
                },
            }

        default:
            return state
    }
}

const prepareProduct = ({
    deliveryId,
    id,
    name,
    price,
    price2,
    quantity,
    shortDescription,
    sku,
    isBundle,
    grossWeight,
    netWeight,
    height,
    width,
    depth,
    ean,
    warranty,
    manageAuctionsActivation,
    minimalStock,
    activateAuctionAboveStock,
    stock,
    allegroProducts,
}) => {
    return {
        deliveryId,
        id,
        name,
        price: Number(price).toFixed(2),
        price2: Number(price2).toFixed(2),
        quantity,
        sku,
        shortDescription,
        isBundle,
        grossWeight,
        netWeight,
        height,
        width,
        depth,
        ean,
        warranty,
        manageAuctionsActivation,
        minimalStock,
        activateAuctionAboveStock,
        stock,
        allegroProducts,
    }
}

export default reducer
