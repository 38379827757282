import React from 'react'

import {Button, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import Page from '../../../components/Page'
import List from '../../../components/List'


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },
})


class AccountList extends React.Component {
    componentDidMount() {
        this.props.getAccounts()
    }

    authorize = id => this.props.authorizeAccount(id)
    onClose = () => this.props.closeModal()

    render() {
        const { classes, items, loading } = this.props
        const values = items.map(item => {
            return {
                actions: [
                    <Button
                        key={1}
                        className={classes.button}
                        onClick={() => this.authorize(item.id)}
                        variant="contained"
                        color="primary"
                    >
                        <Icon className={classes.leftIcon}>account_circle</Icon>
                        Autoryzuj
                    </Button>
                ],
                columns: [item.name],
            }
        })

        const columns = [{ name: 'Nazwa' }]

        return (
            <Page
                pageTitle='Konta allegro'
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">account_box</Icon>
                )}

                content={(
                    <>
                        <List
                            values={values}
                            columns={columns}
                            loading={loading}
                        />
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles)(AccountList)
