import { connect } from 'react-redux'
import { getAllegroAccounts, authorizeAccount, closeModal } from '../../actions/AllegroAccountActions'
import { redirect } from '../../actions/RouterActions'
import AccountList from './components/AccountList'


const mapStateToProps = state => {
    const { items, fetching } = state.presto.allegroAccounts
    const { fetching: loadingAuth } = state.presto.authorizeAccount

    return { items, fetching, loadingAuth }
}

export default connect(
    mapStateToProps, {
        authorizeAccount,
        closeModal,
        getAccounts: getAllegroAccounts,
        redirect,
    }
)(AccountList)
