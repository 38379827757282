import Api from '../Api'
import {
    WARRANTIES_FETCHING_FAILURE,
    WARRANTIES_FETCHING_SUCCESS,
    WARRANTIES_FETCHING
} from './types'


export const getWarranties = () => dispatch => {
    dispatch({
        type: WARRANTIES_FETCHING,
    })

    Api.getWarranties()
        .then(response => {
            dispatch({
                type: WARRANTIES_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: WARRANTIES_FETCHING_FAILURE,
                error: error
            })
        })
}
