import {
    PRODUCT_STATIC_BLOCKS_FETCHING,
    PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS,
    PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE
} from '../../actions/types'


const INITIAL_STATE = {
    items: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_STATIC_BLOCKS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: action.payload,
            }

        default:
            return state
    }
}

export default reducer
