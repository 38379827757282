import {
    ICONS_FETCHING,
    ICONS_FETCHING_SUCCESS,
    ICONS_FETCHING_FAILURE,
} from '../actions/types'

const INITIAL_STATE = {
    icons: [],
    loading: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ICONS_FETCHING:
            return {
                ...state,
                loading: true,
            }

        case ICONS_FETCHING_SUCCESS:
            return {
                ...state,
                loading: false,
                icons: action.payload,
            }

        case ICONS_FETCHING_FAILURE:
            return {
                loading: false,
            }

        default:
            return state
    }
}

export default reducer
