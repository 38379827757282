import { showMessage, setNavigation } from 'store/actions'
import {
    NOTIFICATIONS_FETCHING,
    NOTIFICATIONS_FETCHING_SUCCESS,
    NOTIFICATIONS_FETCHING_FAILURE,
    NOTIFICATIONS_MARK_AS_READ,
} from './types'

import { fuseNavigationConfig } from '../../../fuse-configs/fuseNavigationConfig'

import Api from '../Api'


export const getNotification = id => dispatch => {
    dispatch({
        type: NOTIFICATIONS_FETCHING,
    })

    Api.getNotification(id)
        .then(response => {
            const items = {
                [id]: response.data
            }

            dispatch({
                type: NOTIFICATIONS_FETCHING_SUCCESS,
                payload: {
                    ...response.data,
                    items,
                }
            })
        })
        .catch(error => {
            dispatch({
                type: NOTIFICATIONS_FETCHING_FAILURE,
                error,
            })
        })
}


export const markNotificationAsRead = id => dispatch => {
    Api.markNotificationAsRead(id)
        .then(() => {
            dispatch({
                type: NOTIFICATIONS_MARK_AS_READ,
                id
            })
        })
}


export const getUnreadNotifications = (page = 1) => dispatch => {
    dispatch({
        type: NOTIFICATIONS_FETCHING,
    })

    Api.getUnreadUserNotifications(page)
        .then(response => {
            const items = {}

            response.data.items.forEach(item => {
                items[item.id] = item
            })

            dispatch({
                type: NOTIFICATIONS_FETCHING_SUCCESS,
                payload: {
                    ...response.data,
                    items,
                }
            })

            if (response.data.items.length > 0) {
                dispatch(
                    showMessage({
                        message     : 'Masz nieodczytane powiadomienia',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical  : 'top',
                            horizontal: 'right'
                        },
                        variant: 'info'
                    })
                )

                dispatch(
                    setNavigation(
                        updateNavigationItem('notifications', {
                            badge: {
                                title: response.data.items.length
                            }
                        })
                    )
                )
            }
        })
        .catch(error => {
            dispatch({
                type: NOTIFICATIONS_FETCHING_FAILURE,
                error,
            })
        })
}

const updateNavigationItem = (id, data) => {
    const config = fuseNavigationConfig[0].children.map(item => {
        if (item.id === id) {
            return {
                ...item,
                ...data
            }
        }

        return item
    })

    fuseNavigationConfig[0].children = config
    return fuseNavigationConfig
}

export const getNotifications = () => dispatch => {
}
