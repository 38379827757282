import ProductListsPage from './pages/productList'
import ProductPage from './pages/product'
import DeliveryListPage from './pages/deliveryList'
import DeliveryPage from './pages/delivery'
import StaticBlocksPage from './pages/staticBlockList'
import StaticBlockPage from './pages/staticBlock'
import AccountListPage from './pages/accountList'
import AuctionListPage from  './pages/auctionList'
import ProductErrors from './pages/productErrors'
import OperationRegistry from './pages/operationRegistry'
import LoginPage from './components/LoginModal'
import NotificationListPage from './pages/notificationList'
import IncuboListPage from './pages/incubo'
import IncuboCompetitorOfferPage from './pages/incubo/components/Offer'
import RealStockInCategories from './pages/realStockInCategories'


const PrestoAppConfig = {
    routes: [
        {
            path: '/products',
            component: ProductListsPage,
        },
        {
            path: '/product/:productId',
            component: ProductPage,
        },
        {
            path: '/deliveries',
            component: DeliveryListPage,
        },
        {
            path: '/delivery/:deliveryId',
            component: DeliveryPage,
        },
        {
            path: '/delivery',
            component: DeliveryPage,
        },
        {
            path: '/static-blocks',
            component: StaticBlocksPage,
        },
        {
            path: '/static-block/:staticBlockId',
            component: StaticBlockPage,
        },
        {
            path: '/static-block',
            component: StaticBlockPage,
        },
        {
            path: '/accounts',
            component: AccountListPage,
        },
        {
            path: '/auctions',
            component: AuctionListPage,
        },
        {
            path: '/product-errors',
            component: ProductErrors,
        },
        {
            path: '/operation-registry',
            component: OperationRegistry,
        },
        {
            path: '/notifications',
            component: NotificationListPage,
        },
        {
            path: '/login',
            component: LoginPage,
        },
        {
            path: '/incubo/:offerId',
            component: IncuboCompetitorOfferPage,
        },
        {
            path: '/incubo',
            component: IncuboListPage,
        },
        {
            path: '/real-stock',
            component: RealStockInCategories
        },
    ]
}

export default PrestoAppConfig
