import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'

import Editor from '../../../components/description/DescriptionEditor'
import Input from '../../../components/Input'
import BundleItems from './BundleItems'
import MagentoCategories from './MagentoCategories'
import MagentoCategoriesPerStore from './magentoCategoriesPerStore/MagentoCategoriesContext'
import PrestaShopCategories from './PrestaShopCategories'
import Images from './Images'
import { withStyles } from '@material-ui/core/styles'

import { isLogged } from '../../../helpers'

const styles = theme => ({
    h: {
        marginBottom: theme.spacing.unit * 2,
    },
})

class ProductBasicInformation extends React.Component {
    componentDidMount() {
        const { getPurchasePriceAndRotation } = this.props
        const { sku } = this.props.product

        if (isLogged() && sku) {
            getPurchasePriceAndRotation(sku)
        }
    }
    handleChange = event => {
        const { name, value } = event.target
        this.props.handleChange(name, value)
    }

    handleCheckboxChange = event => {
        const { name, checked } = event.target
        this.props.handleChange(name, checked)
    }

    render() {
        const {
            name,
            sku,
            shortDescription,
            isBundle,
            bundleItems,
            images,
            grossWeight,
            netWeight,
            height,
            width,
            depth,
            ean,
            warranty,
            rotation,
            purchasePrice,
            stock,
            description,
        } = this.props.product

        let additionalImages = []

        description
            .filter(
                block =>
                    block.staticBlock != null &&
                    block.staticBlock.image1 != null
            )
            .forEach(block => {
                if (
                    block.staticBlock.image1.url != null ||
                    block.staticBlock.image1.url != ''
                ) {
                    additionalImages.push(block.staticBlock.image1.url)
                }

                if (
                    block.staticBlock.image2 != null &&
                    (block.staticBlock.image2.url != null ||
                        block.staticBlock.image2.url != '')
                ) {
                    additionalImages.push(block.staticBlock.image2.url)
                }
            })

        bundleItems.forEach(item => {
            let bundleItemInDescription = false

            description.forEach(block => {
                if (block.bundleItemId == item.id) {
                    bundleItemInDescription = true
                }
            })

            if (
                bundleItemInDescription &&
                item.image != null &&
                item.image !== ''
            ) {
                additionalImages.push(item.image)
            }
        })

        const { classes } = this.props
        const warrantyOptions = this.props.warranties.map(warranty => (
            <MenuItem key={warranty} value={warranty}>
                {warranty}
            </MenuItem>
        ))

        return (
            <div>
                <h3 className={classes.h}>Dane podstawowe</h3>
                <div className="flex">
                    <Input
                        className="mt-8 mb-16 mr-8"
                        required
                        label="Nazwa produktu"
                        id="name"
                        name="name"
                        value={name}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16"
                        required
                        label="Numer katalogowy"
                        id="sku"
                        name="sku"
                        value={sku}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div className="flex">
                    <Input
                        className="mt-8 mb-16 mr-8"
                        required
                        label="Ean"
                        id="ean"
                        name="ean"
                        value={ean}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16 mr-8"
                        label="Stan magazynowy GL"
                        id="stock"
                        name="stock"
                        value={stock}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16 mr-8"
                        label="Rotacja"
                        id="rotation"
                        name="rotation"
                        value={rotation}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16"
                        label="Cena zakupu"
                        id="purchasePrice"
                        name="purchasePrice"
                        value={purchasePrice}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <h3 className={classes.h}>Miary</h3>
                <div className="flex">
                    <Input
                        className="mt-8 mb-16 mr-8"
                        label="Waga netto"
                        id="netWeight"
                        name="netWeight"
                        value={netWeight}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16"
                        label="Waga brutto"
                        id="grossWeight"
                        name="grossWeight"
                        value={grossWeight}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div className="flex">
                    <Input
                        className="mt-8 mb-16 mr-8"
                        label="Wysokość"
                        id="height"
                        name="height"
                        value={height}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16 mr-8"
                        label="Szerokość"
                        id="width"
                        name="width"
                        value={width}
                        variant="outlined"
                        fullWidth
                    />
                    <Input
                        className="mt-8 mb-16"
                        label="Głębokość"
                        id="depth"
                        name="depth"
                        value={depth}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <h3 className={classes.h}>Krótki opis</h3>
                <FormControl className="mt-8 mb-16" fullWidth>
                    <Editor
                        placeholder="krótki opis..."
                        value={shortDescription}
                        onChange={value =>
                            this.props.handleChange('shortDescription', value)
                        }
                        id="shortDescription"
                        name="shortDescription"
                    />
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isBundle}
                            name="isBundle"
                            color="primary"
                            onChange={this.handleCheckboxChange}
                        />
                    }
                    label="Produkt jest zestawem"
                />
                {isBundle && (
                    <BundleItems
                        items={bundleItems}
                        onSearch={this.props.findBundleItems}
                        searchItems={this.props.bundleItemsSearch.items}
                        searchInProgress={this.props.bundleItemsSearch.fetching}
                        addItem={this.props.addBundleItem}
                        removeItem={this.props.removeBundleItem}
                        changeQuantity={this.props.changeBundleItemQuantity}
                        product={this.props.product}
                        swapBundleItem={this.props.swapBundleItem}
                    />
                )}
                <MagentoCategories
                    title={'Lista kategorii dla hurtona i innych'}
                    selectedCategories={this.props.product.magentoCategories}
                    categories={this.props.magentoCategories}
                    addCategory={this.props.addCategory}
                    removeCategory={this.props.removeCategory}
                />
                {this.props.product.id && (
                    <PrestaShopCategories
                        title={
                            'Lista kategorii dla eltrox.pl (nowy sklep PrestaShop)'
                        }
                        productId={this.props.product.id}
                    />
                )}
                <Images
                    addImage={this.props.addImage}
                    images={images}
                    additionalImages={additionalImages}
                    toggleNotForGallery={this.props.toggleNotForGallery}
                    product={this.props.product}
                    removeImage={this.props.removeImage}
                    setMainImage={this.props.setMainImage}
                    setAllegroMini={this.props.setAllegroMini}
                    uploadImage={this.props.uploadImage}
                    setImageSort={this.props.setImageSort}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductBasicInformation)
