import React from 'react'
import ReactQuill, { Quill }from 'react-quill'
import 'react-quill/dist/quill.snow.css'


const Inline = Quill.import('blots/inline')

class BoldBlot extends Inline {}

BoldBlot.blotName = 'bold'
BoldBlot.tagName = 'b'

Quill.register(BoldBlot)


class Editor extends React.Component {
    format = [
        'bold',
        'list',
    ]

    modules = {
        toolbar: [
            ['bold', {list: 'bullet'}, 'clean']
        ]
    }

    render() {
        return (
            <ReactQuill
                value={this.props.value}
                onChange={this.props.onChange}
                modules={this.modules}
                placeholder={this.props.placeholder}
                formats={this.format}
            />
        )
    }
}

export default Editor
