import {
    MAGENTO_CATEGORIES_FETCH_FAILURE,
    MAGENTO_CATEGORIES_FETCH_SUCCESS,
    MAGENTO_CATEGORIES_FETCHING
} from '../actions/types'


const INITIAL_STATE = {
    items: {},
    fetching: false,
}


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAGENTO_CATEGORIES_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case MAGENTO_CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                items: prepareCategories(action.payload),
                fetching: false,
            }

        case MAGENTO_CATEGORIES_FETCH_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}


const prepareCategories = (magentoCategories) => {
    return magentoCategories
}

export default reducer
