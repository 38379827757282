import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Divider, Grid, MenuItem, Paper, TextField } from '@material-ui/core'
import IconButton from '../../../components/IconButton'

import Page from '../../../components/Page'
import Autosuggest from '../../../components/Autosuggest'
import Preview from '../../product/components/description/DescriptionItemPreview'
import Form from './Form'
import List from '../../../components/List'
import { SingleSelect } from '../../../components/Select'

const styles = theme => ({
    control: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },

    paper: {
        padding: theme.spacing.unit * 2,
    },

    divider: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
})

class StaticBlockForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            idAttributeValue: 0,
        }
    }

    componentDidMount() {
        this.props.getDescriptionItemTypes()

        const staticBlockId = this.props.match.params.staticBlockId

        if (staticBlockId) {
            this.props.getStaticBlock(staticBlockId)
        } else {
            this.props.clearStaticBlock()
        }
    }

    setIdAttributeValue = value => {
        this.setState({ idAttributeValue: value })
    }

    handleChange = (name, value) => {
        this.props.staticBlockChange(name, value)
    }

    saveStaticBlock = () => {
        this.props.saveStaticBlock({
            ...this.props.data,
            id: this.props.match.params.staticBlockId,
        })
    }

    getSuggestions = value => {
        if (value.length >= 3) {
            this.props.findAttributesByName(value)
        }
    }

    renderAttributes = () => {
        const { id, vertoAttributes } = this.props.data

        const { removeAttribute } = this.props

        const columns = [{ name: 'Cecha' }, { name: 'Wartość' }]

        const values = vertoAttributes.map(attribute => ({
            actions: [
                <IconButton
                    key={1}
                    onClick={() => removeAttribute(id, attribute.id)}
                    icon="remove"
                />,
            ],
            columns: [attribute.attribute.name, attribute.value],
        }))

        return <List className="my-16" columns={columns} values={values} />
    }

    render() {
        const {
            id: staticBlockId,
            header,
            headerIcon,
            text,
            image1,
            image2,
            notForAllegro,
            fetching,
            fetchingAttribute,
        } = this.props.data

        let { descriptionItemTypeId } = this.props.data

        if (!descriptionItemTypeId) {
            descriptionItemTypeId = 3
        }

        const { classes, attribute, addAttribute } = this.props

        const { idAttributeValue } = this.state

        const images = []

        if (image1) {
            images.push(image1)
        }

        if (image2) {
            images.push(image2)
        }

        const suggestions = this.props.suggestions.map(({ id, name }) => ({
            id,
            label: name,
        }))

        let options = []
        let attributeOptions = []

        if (attribute) {
            options = attribute.values
                .sort((a, b) => {
                    const first = a.value.toLowerCase()
                    const second = b.value.toLowerCase()

                    if (first < second) {
                        return -1
                    }

                    if (first > second) {
                        return 1
                    }

                    return 0
                })
                .map(({ id, value }) => (
                    <MenuItem key={id} value={id}>
                        {value}
                    </MenuItem>
                ))

            attributeOptions = attribute.values
                .sort((a, b) => {
                    const first = a.value.toLowerCase()
                    const second = b.value.toLowerCase()

                    if (first < second) {
                        return -1
                    }

                    if (first > second) {
                        return 1
                    }

                    return 0
                })
                .map(({ id, value }) => ({
                    value: id,
                    label: value,
                }))
        }

        const selectedAttributeValue = attributeOptions.find(
            ({ value }) => value == idAttributeValue
        )

        return (
            <Page
                loading={fetching}
                pageTitle={header}
                content={
                    <div className={classes.root}>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Form
                                        descriptionItemTypeId={
                                            descriptionItemTypeId
                                        }
                                        header={header}
                                        headerIcon={headerIcon}
                                        text={text}
                                        image1={image1}
                                        image2={image2}
                                        notForAllegro={notForAllegro}
                                        images={images}
                                        descriptionItemTypes={
                                            this.props.descriptionItemTypes
                                        }
                                        handleChange={this.handleChange}
                                        allowImageAdditions
                                        onAddImage={this.handleChange}
                                    />
                                    <h2 className="mt-24 mb-8">
                                        Mapowanie bloku statycznego do cech
                                    </h2>
                                    <Autosuggest
                                        className="mb-8 mt-8"
                                        placeholder="Wyszukaj cechę do zmapowania"
                                        suggestions={suggestions}
                                        getSuggestions={this.getSuggestions}
                                        onSelect={({ id: attributeId }) =>
                                            this.props.getAttribute(attributeId)
                                        }
                                        clearSuggestions={
                                            this.props.clearAttributes
                                        }
                                        clearAfterSelect={true}
                                    />
                                    {attribute && (
                                        <div className="flex mt-8 mb-8">
                                            <SingleSelect
                                                className="mr-8"
                                                label={attribute.name}
                                                name="attribute-value"
                                                helperText="Kliknij, aby wybrać lub wyszukać cechę"
                                                onChange={
                                                    this.setIdAttributeValue
                                                }
                                                value={
                                                    selectedAttributeValue ===
                                                        null ||
                                                    selectedAttributeValue ===
                                                        undefined
                                                        ? null
                                                        : selectedAttributeValue
                                                }
                                                options={attributeOptions}
                                                fullWidth
                                            />
                                            <IconButton
                                                onClick={() =>
                                                    addAttribute(
                                                        staticBlockId,
                                                        idAttributeValue
                                                    )
                                                }
                                                icon="add"
                                            />
                                        </div>
                                    )}
                                    <h3 className="mt-48 mb-8">
                                        Lista zmapowanych cech
                                    </h3>
                                    {this.renderAttributes()}
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Preview
                                        descriptionItemTypeId={
                                            descriptionItemTypeId
                                        }
                                        header={header}
                                        headerIcon={headerIcon}
                                        text={text}
                                        image1={image1}
                                        image2={image2}
                                        description={this.props.description}
                                        onClick={this.selectDescriptionItem}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <IconButton onClick={this.saveStaticBlock} icon="save">
                            Zapisz
                        </IconButton>
                    </div>
                }
            />
        )
    }
}

export default withStyles(styles)(StaticBlockForm)
