import StaticBlockForm from './components/StaticBlockForm'
import { connect } from 'react-redux'

import {
    findAttributesByName,
    clearAttributes,
} from '../../actions/VertoAttributeActions'
import {
    getStaticBlock,
    staticBlockChange,
    saveStaticBlock,
    clearStaticBlock,
    addAttributeToStaticBlock,
    removeAttributeFromStaticBlock,
    getAttribute
} from '../../actions/StaticBlockActions'
import { getDescriptionItemTypes } from '../../actions/DescriptionItemTypeActions'


const mapStateToProps = ({ presto: state }) => {
    const { data, fetching, changed } = state.staticBlock
    const { items } = state.vertoAttributes
    const { data: attribute } = state.vertoAttribute

    const {
        descriptionItemTypes,
    } = state

    return {
        attribute,
        data,
        fetching,
        changed,
        descriptionItemTypes,
        suggestions: items,
    }
}

const Page = connect(
    mapStateToProps, {
        getStaticBlock,
        getDescriptionItemTypes,
        staticBlockChange,
        saveStaticBlock,
        clearStaticBlock,
        findAttributesByName,
        clearAttributes,
        getAttribute,
        addAttribute: addAttributeToStaticBlock,
        removeAttribute: removeAttributeFromStaticBlock,
    }
)(StaticBlockForm)

export default Page
