import {
    ALLEGRO_ACCOUNTS_FETCHING,
    ALLEGRO_ACCOUNTS_FETCHING_SUCCESS,
    ALLEGRO_ACCOUNTS_FETCHING_FAILURE,
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS,
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE,
    ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING,
    ALLEGRO_ACCOUNTS_CLOSE_MODAL,
} from '../actions/types'

import Api from '../Api'


export const getAllegroAccounts = () => dispatch => {
    dispatch({ type: ALLEGRO_ACCOUNTS_FETCHING })

    Api.getAllegroAccounts()
        .then(response => {
            dispatch({
                type: ALLEGRO_ACCOUNTS_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: ALLEGRO_ACCOUNTS_FETCHING_FAILURE,
                payload: error
            })
        })
}

export const authorizeAccount = accountId => dispatch => {
    dispatch({ type: ALLEGRO_ACCOUNTS_AUTHORIZE_FETCHING })

    Api.authorizeAccount(accountId)
        .then(response => {
            dispatch({
                type: ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_SUCCESS,
                url: response.data.url,
            })
        })
        .catch(error => {
            dispatch({
                type: ALLEGRO_ACCOUNTS_AUTHORIZE_FETCH_FAILURE,
                payload: error,
            })
        })
}

export const closeModal = () => dispatch => {
    dispatch({ type: ALLEGRO_ACCOUNTS_CLOSE_MODAL })
}
