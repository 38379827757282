import React, { Component } from 'react'
import {
    withStyles,
    Paper,
    Input,
    Icon,
    Typography,
    MuiThemeProvider,
} from '@material-ui/core'
import { FusePageCarded } from '@fuse'
import { FuseAnimate, FuseSelectedTheme } from '@fuse'
import Loading from './Loading'
import LoginModal from './LoginModal'
import { getUnreadNotifications } from '../actions/NotificationActions'
import { connect } from 'react-redux'
import PopupList from './PopupList'

const styles = {}

class PageHeader extends Component {
    constructor(props) {
        super(props)

        this.state = this.getInitialState()
    }

    getInitialState = () => {
        const initialState = {}

        if (this.props.inputs) {
            this.props.inputs.forEach(item => {
                initialState[item.name] = null
            })
        }

        return initialState
    }

    onInputChange = name => e => {
        this.setState({ [name]: e.target.value })
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    search = () => {
        this.props.onSearch(this.state)
        this.setState(this.getInitialState())
    }

    render() {
        const delay = 300
        const duration = 400

        let { inputs, icon, pageTitle, header } = this.props

        if (!inputs) {
            inputs = []
        }

        const searchForm = inputs.map((item, index) => {
            return (
                <FuseAnimate
                    key={index}
                    animation="transition.slideDownIn"
                    delay={300}
                >
                    <Paper
                        className="flex items-center w-full max-w-512 px-8 py-4 rounded-8"
                        elevation={1}
                    >
                        <Icon className="mr-8" color="action">
                            search
                        </Icon>
                        <Input
                            className="flex flex-1"
                            disableUnderline
                            fullWidth
                            placeholder={(item.label || item.name) + '...'}
                            name={item.name}
                            value={this.state[item.name] || ''}
                            onChange={this.onInputChange(item.name)}
                            onKeyPress={this.onKeyPress}
                            inputProps={{
                                'aria-label': 'Search',
                            }}
                        />
                    </Paper>
                </FuseAnimate>
            )
        })

        return (
            <div className={'flex flex-1 w-full items-center justify-between'}>
                <div className="flex items-center">
                    {icon && (
                        <FuseAnimate
                            animation="transition.expandIn"
                            duration={duration}
                            delay={delay}
                        >
                            {icon}
                        </FuseAnimate>
                    )}
                    <FuseAnimate
                        animation="transition.slideLeftIn"
                        duration={duration}
                        delay={delay}
                    >
                        <Typography className="hidden sm:flex" variant="h6">
                            {pageTitle}
                        </Typography>
                    </FuseAnimate>
                </div>

                <div className="flex flex-1 items-center justify-center px-12">
                    <MuiThemeProvider theme={FuseSelectedTheme}>
                        {searchForm}
                    </MuiThemeProvider>
                </div>
                {header && (
                    <FuseAnimate
                        animation="transition.slideRightIn"
                        delay={delay}
                        duration={duration}
                    >
                        {header}
                    </FuseAnimate>
                )}
            </div>
        )
    }
}

class Page extends Component {
    componentDidMount() {
        this.setDocumentTitle()
        this.props.getUnreadNotifications()
    }

    componentDidUpdate() {
        this.setDocumentTitle()
    }

    setDocumentTitle = () => {
        if (this.props.pageTitle) {
            document.title = `Presto - ${this.props.pageTitle}`
        } else {
            document.title = 'Presto'
        }
    }

    render() {
        const {
            classes,
            pageTitle,
            contentToolbar,
            content,
            headerIcon,
            loading,
            header,
        } = this.props

        const animation = 'transition.fadeIn'
        const delay = 300
        const duration = 400

        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot,
                }}
                header={
                    <div className="p-36">
                        <PageHeader
                            header={header}
                            icon={headerIcon}
                            pageTitle={pageTitle}
                        />
                    </div>
                }
                contentToolbar={
                    contentToolbar ? (
                        <FuseAnimate
                            animation={animation}
                            delay={delay}
                            duration={duration}
                        >
                            <div className="px-24">{contentToolbar}</div>
                        </FuseAnimate>
                    ) : null
                }
                content={
                    loading ? (
                        <Loading />
                    ) : (
                        <FuseAnimate
                            animation={animation}
                            delay={delay}
                            duration={duration}
                        >
                            <div className="p-24">
                                <LoginModal />
                                <PopupList />
                                {content}
                            </div>
                        </FuseAnimate>
                    )
                }
            />
        )
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(null, {
        getUnreadNotifications,
    })(Page)
)
