import { connect } from 'react-redux'
import StaticBlockList from './components/StaticBlockList'
import { redirect } from '../../actions/RouterActions'
import { getStaticBlocks } from '../../actions/StaticBlockActions'


const mapStateToProps = ({ presto: state }) => {
    const { items, fetching, page, pageSize } = state.staticBlocks
    return {
        items,
        fetching,
        page,
        pageSize,
    }
}

const StaticBlocks = connect(
    mapStateToProps, {
        redirect,
        getStaticBlocks,
    }
)(StaticBlockList)

export default StaticBlocks

