import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import BundleItemsSearch from './bundleItems/BundleItemsSearch'
import BundleItemsList from './bundleItems/BundleItemsList'


const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    }
})

const BundleItems = (props) => {
    const {
        items,
        addItem,
        removeItem,
        changeQuantity,
        searchItems,
        searchInProgress,
        onSearch,
        classes,
    } = props

    return (
        <div>
            <h3 className={classes.h}>Wyszukiwanie produktów do zestawu</h3>
            <BundleItemsSearch
                addItem={addItem}
                searchItems={searchItems}
                serchInProgress={searchInProgress}
                onSearch={onSearch}
            />
            {items.length > 0 && (
                <BundleItemsList
                    items={items}
                    removeItem={removeItem}
                    changeQuantity={changeQuantity}
                    onSearch={onSearch}
                    searchItems={searchItems}
                    product={props.product}
                    swapBundleItem={props.swapBundleItem}
                />
            )}
        </div>
    )
}

export default withStyles(styles, {withTheme: true})(BundleItems)
