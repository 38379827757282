export const fuseNavigationConfig = [
    {
        id: 'main',
        title: 'Menu',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'products',
                title: 'Produkty',
                type: 'item',
                icon: 'shopping_cart',
                url: '/products',
            },
            {
                id: 'deliveries',
                title: 'Koszty dostawy',
                type: 'item',
                icon: 'local_shipping',
                url: '/deliveries',
            },
            {
                id: 'static-blocks',
                title: 'Bloki statyczne',
                type: 'item',
                icon: 'art_track',
                url: '/static-blocks',
            },
            {
                id: 'auctions',
                title: 'Aukcje',
                type: 'item',
                icon: 'shopping_basket',
                url: '/auctions',
            },
            {
                id: 'accounts',
                title: 'Konta allegro',
                type: 'item',
                icon: 'account_box',
                url: '/accounts',
            },
            {
                id: 'notifications',
                title: 'Powiadomienia',
                type: 'item',
                icon: 'message',
                url: '/notifications',
            },
            {
                id: 'operation-registry',
                title: 'Rejestr Operacji',
                type: 'item',
                icon: 'data_usage',
                url: '/operation-registry',
            },
            {
                id: 'product-errors',
                title: 'Błędy',
                type: 'item',
                icon: 'bug_report',
                url: '/product-errors',
            },
            {
                id: 'real-stock',
                title: 'Realne stany magazynowe',
                type: 'item',
                icon: 'stock',
                url: '/real-stock',
            },
        ],
    },
]
