import React from 'react'
import SheetJS from 'xlsx'
import DataInput from './DataInput'
import IconButton from './IconButton'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    InputBase,
    IconButton as IconButton2,
    Icon,
} from '@material-ui/core'


const ACCEPTED_FILES = ['xlsx', 'xls', 'csv', 'ods'].map(ext => `.${ext}`).join(',')

const makeCols = refstr => {
    let result = []
    const range = SheetJS.utils.decode_range(refstr).e.c + 1

    for (let i = 0; i < range; ++i) {
        result[i] = {name: SheetJS.utils.encode_col(i), key: i}
    }

    return result
}

class TableEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
    }

    openEditor = () => this.setState({open: true})

    closeEditor = () => this.setState({open: false})

    handleFile = file => {
        const reader = new FileReader()
        const readAsBinaryString = !!reader.readAsBinaryString

        reader.onload = e => {
            let result = e.target.result

            if (!readAsBinaryString) {
                result = new Uint8Array(result)
            }

            const workbook = SheetJS.read(result, {type: readAsBinaryString ? 'binary' : 'array'})
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = SheetJS.utils.sheet_to_json(worksheet, {header: 1})
            const cols = makeCols(worksheet['!ref'])
            const boldedCells = data.map((_, index) => ({row: index, col: 0}))

            this.props.onChange({
                data,
                cols,
                boldedCols: [],
                boldedRows: [],
                boldedCells: boldedCells,
            })
        }

        if (readAsBinaryString) {
            reader.readAsBinaryString(file)
        } else {
            reader.readAsArrayBuffer(file)
        }
    }

    saveFile = () => {
        const header = []
        const data = this.props.data.data.map((row) => {
            const result = {}

            row.forEach((col, colIndex) => {
                const key = String(colIndex)

                if (!header.includes(key)) {
                    header.push(key)
                }

                result[key] = col
            })

            return result
        })

        const sheet = SheetJS.utils.json_to_sheet(data, {
            header,
            skipHeader: true,
        })

        const wb = SheetJS.utils.book_new()

        SheetJS.utils.book_append_sheet(wb, sheet, 'sheet')

        SheetJS.writeFile(wb, 'out.ods', {
            type: 'ods'
        })
    }

    render() {
        const disabled = this.props.data.data.length === 0

        return (
            <div className="flex">
                <DataInput
                    className="mr-8"
                    handleFile={this.handleFile}
                    label="Importuj arkusz"
                    accept={ACCEPTED_FILES}
                />
                <IconButton
                    style={{marginRight: 8}}
                    disabled={disabled}
                    icon="edit"
                    onClick={this.openEditor}
                >
                    Edytuj tabelę
                </IconButton>
                <IconButton
                    disabled={disabled}
                    icon="save"
                    onClick={this.saveFile}
                >
                    Zapisz do pliku
                </IconButton>
                <Editor
                    open={this.state.open}
                    data={this.props.data}
                    onClose={this.closeEditor}
                    onChange={this.props.onChange}
                />
            </div>
        )
    }
}

const Editor = props => {
    const onChange = (event, rowIndex, colIndex) => {
        const value = event.target.value

        let data = props.data.data.map((row, rowI) => {
            if (rowIndex !== rowI) {
                return row
            } else {
                return row.map((col, colI) => {
                    if (colI !== colIndex) {
                        return col
                    } else {
                        return value
                    }
                })
            }
        })

        props.onChange({
            ...props.data,
            data,
        })
    }

    const toggleBold = (rowIndex, colIndex) => {
        const isBold = props.data.boldedCells.find(item => item.row === rowIndex && item.col === colIndex)
        let data = []

        if (isBold) {
            data = props.data.boldedCells.filter(item => !(item.row === rowIndex && item.col === colIndex))
        } else {
            data = props.data.boldedCells.slice()
            data.push({row: rowIndex, col: colIndex})
        }

        props.onChange({
            ...props.data,
            boldedCells: data,
        })
    }

    const rows = props.data.data.map((row, rowIndex) => {
        return (
            <TableRow key={rowIndex}>
                {row.map((col, colIndex) => {
                    const isBold = props.data.boldedCells.find(item => item.row === rowIndex && item.col === colIndex)
                    let style = {}

                    if (isBold) {
                        style.fontWeight = 'bold'
                    }

                    return (
                        <TableCell key={colIndex}>
                            <div className="flex">
                                <IconButton2
                                    className="mr-8"
                                    size="small"
                                    onClick={() => toggleBold(rowIndex, colIndex)}
                                    style={{padding: 6}}
                                >
                                    <Icon>format_bold</Icon>
                                </IconButton2>
                                <InputBase
                                    value={col}
                                    onChange={value => onChange(value, rowIndex, colIndex)}
                                    fullWidth
                                    multiline
                                    style={style}
                                />
                            </div>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    })

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>Edycja tabeli</DialogTitle>
            <DialogContent>
                <Table>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <IconButton
                    color="primary"
                    onClick={props.onClose}
                    icon='close'
                >
                    Zamknij
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}

export default TableEditor
