import {
    MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX,
    MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX,
    MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX,
} from '../actions/types'

const INITIAL_STATE = {
    items: {},
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX:
            return {
                ...state,
                fetching: true,
            }

        case MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX:
            return {
                ...state,
                items: prepareCategories(action.payload),
                fetching: false,
            }

        case MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}

const prepareCategories = magentoCategories => {
    return magentoCategories
}

export default reducer
