import {
    PRODUCT_HISTORY_FETCH,
} from '../../actions/types'


const INITIAL_STATE = {
    items: [],
    fetching: false,
    page: 1,
    pageSize: 10,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_HISTORY_FETCH:
            return {
                ...state,
                fetching: false,
                page: action.data.page,
                pageSize: action.data.pageSize,
                items: prepareItems(action.data.items)
            }
        default:
            return state
    }
}

const prepareItems = data => {
    return data
}

export default reducer
