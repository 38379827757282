import {
    UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE,
    UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS,
    UPDATING_DESCRIPTION_IN_BASELINKER,
} from '../actions/types'

const INITIAL_STATE = {
    loading: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATING_DESCRIPTION_IN_BASELINKER:
            return {
                loading: true,
            }

        case UPDATE_DESCRIPTION_IN_BASELINKER_SUCCESS:
            return {
                loading: false,
            }

        case UPDATE_DESCRIPTION_IN_BASELINKER_FAILURE:
            return {
                loading: false,
            }

        default:
            return state
    }
}

export default reducer
