import React from 'react'
import Input from './Input'


class RangeInput extends React.Component {
    onChange = (id, { label, value }) => {
        let { from, to } = this.props.value

        if ([null, undefined].includes(from)) {
            from = ''
        }

        if ([null, undefined].includes(to)) {
            to = ''
        }

        if (label === 'from') {
            from = value
        } else if (label === 'to') {
            to = value
        }

        value = { from , to }

        this.props.onChange(id, value)
    }

    render() {
        const {
            id,
            isRequired,
            name,
            value,
            max,
            min,
            precision,
            type,
            adornment,
            variant
        } = this.props

        const sid = String(id)

        let {from , to} = value

        if ([null, undefined].includes(from)) {
            from = ''
        }

        if ([null, undefined].includes(to)) {
            to = ''
        }

        return (
            <div className="flex">
                <Input
                    id={sid}
                    required={isRequired}
                    key={`${id}-from`}
                    label={`${name} - od`}
                    max={max}
                    min={min}
                    name={name}
                    onChange={event => this.onChange(id, {
                        value: event.target.value,
                        label: 'from'
                    })}
                    precision={precision}
                    type={type}
                    InputProps={{
                        startAdornment: adornment
                    }}
                    value={from}
                    fullWidth
                    variant={variant}
                />
                <span style={{padding: '18.5px 14px'}}>-</span>
                <Input
                    id={sid}
                    required={isRequired}
                    key={`${id}-to`}
                    label={`${name} - do`}
                    max={max}
                    min={min}
                    name={name}
                    onChange={event => this.onChange(id, {
                        value: event.target.value,
                        label: 'to'
                    })}
                    precision={precision}
                    type={type}
                    InputProps={{
                        startAdornment: adornment
                    }}
                    value={to}
                    fullWidth
                    variant={variant}
                />
            </div>
        )
    }
}


export default RangeInput
