import { toCamelCase } from '../../helpers'

import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_BUNDLE_ITEM_ADD,
    PRODUCT_BUNDLE_ITEM_REMOVE,
    BUNDLE_ITEM_CHANGE_QUANTITY,
    SWAP_BUNDLE_ITEM,
} from '../../actions/types'


const INITIAL_STATE = []

const reducer = (state = toCamelCase(INITIAL_STATE), action) => {
    let bundleItems = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareBundleItems(action.payload)

        case PRODUCT_BUNDLE_ITEM_ADD:
            if (bundleItems.find(i => i.id === action.payload.item.id)) {
                return state
            }

            bundleItems.push(prepareItem(action.payload))
            return bundleItems

        case PRODUCT_BUNDLE_ITEM_REMOVE:
            return state.filter(({ id }) => id !== action.id)

        case BUNDLE_ITEM_CHANGE_QUANTITY:
            return state.map(item => {
                if (item.id === action.id) {
                    return {
                        ...item,
                        quantity: action.quantity,
                    }
                }

                return item
            })

        case SWAP_BUNDLE_ITEM:
            return state.map(item => {
                if (item.id === action.oldItemId) {
                    const data = action.data.bundleItems.find(item => item.item.id === action.newItemId)
                    return prepareItem(data)
                }

                return item
            })


        default:
            return state
    }
}

const prepareBundleItems = ({ bundleItems }) => {
    if (!bundleItems) {
        return []
    }

    return bundleItems.map(item => prepareItem(item))
}

const prepareItem = data => {
    const { item, quantity } = data
    const {
        id,
        name,
        sku,
        shortDescription,
        images,
    } = item

    let image = null

    if (images && images.length > 0) {
        const mainImages = images.filter(image => image.isMain)

        if (mainImages.length > 0) {
            image = mainImages[0].url
        } else {
            image = images[0].url
        }
    }

    return {
        id,
        name,
        sku,
        quantity,
        shortDescription,
        image,
    }
}

export default reducer

