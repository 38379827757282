import React from 'react'
import Page from '../../../components/Page'
import List from '../../../components/List'
import { Icon, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    list: {
        marginBottom: theme.spacing.unit * 6,
    },
})

class ProductList extends React.Component {
    importProduct = sku => {
        this.props.importProduct(sku)
    }

    getProducts = (_event, page) => {
        const { pageSize, query } = this.props
        page += 1

        if (query !== null && query !== '') {
            this.props.findProductsBySkuAndName(query, page, pageSize)
        }
    }

    onChangePageSize = pageSize => {
        const { query } = this.props
        const page = 1
        this.props.findProductsBySkuAndName(query, page, pageSize)
    }

    onSearchInVerto = data => {
        const { sku } = data
        this.props.searchProductFromVerto(sku)
    }

    onSearch = ({ query }) => {
        const { pageSize } = this.props
        this.props.findProductsBySkuAndName(query, 1, pageSize)
    }

    renderImport = () => {
        const values = this.props.product
            ? [
                  {
                      actions: [],
                      columns: [
                          this.props.product.sku,
                          this.props.product.name,
                      ],
                      onClick: () => this.importProduct(this.props.product.sku),
                  },
              ]
            : []

        const columns = [{ name: 'Numer katalogowy' }, { name: 'Produkt' }]

        const inputs = [{ name: 'sku', label: 'Numer katalogowy' }, null]

        return (
            <List
                columns={columns}
                values={values}
                loading={this.props.searchInProgress}
                onSearch={this.onSearchInVerto}
                inputs={inputs}
            />
        )
    }

    render() {
        const { classes } = this.props

        const values = this.props.items.map(item => {
            return {
                actions: [],
                columns: [item.name, item.sku],
                onClick: () => this.props.redirect(`/product/${item.id}`),
            }
        })

        const columns = [{ name: 'Produkt' }, { name: 'Numer katalogowy' }]

        const inputs = [{ name: 'query', label: 'Wyszukaj produkt' }]

        return (
            <Page
                pageTitle="Produkty"
                headerIcon={
                    <Icon className="text-32 mr-0 sm:mr-12">
                        shopping_basket
                    </Icon>
                }
                content={
                    <>
                        <Typography variant="h6">Lista produktów</Typography>
                        <div className={classes.list}>
                            <List
                                columns={columns}
                                inputs={inputs}
                                loading={this.props.searchInProgress}
                                onChangePage={this.getProducts}
                                onChangeRowsPerPage={event =>
                                    this.onChangePageSize(event.target.value)
                                }
                                onSearch={this.onSearch}
                                page={this.props.page - 1}
                                paginated={true}
                                rowsPerPage={this.props.pageSize}
                                values={values}
                            />
                        </div>
                        <Typography variant="h6">Importuj produkt</Typography>
                        {this.renderImport()}
                    </>
                }
            />
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductList)
