import {combineReducers} from 'redux'
import fuse from './fuse'
import auth from 'auth/store/reducers'
import quickPanel from 'main/quickPanel/store/reducers'
import presto from 'apps/presto/reducers'


const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        quickPanel,
        presto,
        ...asyncReducers
    })

export default createReducer
