import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
} from '../actions/types'

import { isLogged } from '../helpers'


const INITIAL_STATE = {
    token: localStorage.getItem('token'),
    isLogged: isLogged(),
    user: null,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                isLogged: true,
                user: action.data
            }

        case LOGOUT:
        case LOGIN_FAILURE:
            return {
                ...state,
                token: null,
                isLogged: false
            }

        default:
            return state
    }
}

export default reducer
