import {
    DELIVERY_OPTION_TYPES_FETCHING,
    DELIVERY_OPTION_TYPES_FETCHING_SUCCESS,
    DELIVERY_OPTION_TYPES_FETCHING_FAILURE,
} from '../actions/types'

import Api from '../Api'


export const getDeliveryOptionTypes = () => dispatch => {
    dispatch({ type: DELIVERY_OPTION_TYPES_FETCHING })

    Api.getDeliveryOptionTypes()
        .then(response => {
            dispatch({
                type: DELIVERY_OPTION_TYPES_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_OPTION_TYPES_FETCHING_FAILURE,
                payload: error
            })
        })
}

