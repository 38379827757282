import React from 'react'
import { connect } from 'react-redux'
import { removePopup } from '../actions/PopupActions'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const styles1 = theme => {
    return {
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        info: {
            backgroundColor: theme.palette.primary.dark,
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing.unit,
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
    }
}

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon
                        className={classNames(
                            classes.icon,
                            classes.iconVariant
                        )}
                    />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    )
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
        .isRequired,
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

class PopupList extends React.Component {
    onClose = () => {
        const { alerts, removePopup } = this.props
        alerts.forEach((_, index) => removePopup(index))
        // this.setState({ open: false })
    }

    render() {
        const { alerts, removePopup } = this.props

        const alertList = alerts.map((item, index) => (
            <MySnackbarContentWrapper
                message={item.text}
                onClose={() => removePopup(index)}
                variant={item.severity}
                key={index}
            >
                {item.text}
            </MySnackbarContentWrapper>
        ))

        const vertical = 'top'
        const horizontal = 'right'

        if (alerts.length < 1) {
            return null
        }

        return (
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={true}
                onClose={this.onClose}
            >
                <>{alertList}</>
            </Snackbar>
        )
    }
}

const mapStateToProps = ({ presto }) => {
    return {
        alerts: presto.popups,
    }
}

export default connect(mapStateToProps, {
    removePopup,
})(PopupList)
