import {
    PRODUCT_ERRORS_FETCHING,
    PRODUCT_ERRORS_FETCHING_SUCCESS,
    PRODUCT_ERRORS_FETCHING_FAILURE,
    PRODUCT_ERRORS_CHANGE_FILTER,
} from '../actions/types'


const INITIAL_STATE = {
    items: [],
    page: 0,
    pageSize: 10,
    fetching: false,
    filter: 'ALL',
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_ERRORS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case PRODUCT_ERRORS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case PRODUCT_ERRORS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload.items),
                page: action.payload.page,
                pageSize: action.payload.pageSize,
            }

        case PRODUCT_ERRORS_CHANGE_FILTER:
            return {
                ...state,
                filter: action.filter,
            }

        default:
            return state
    }
}

const errors = (items) => {
    let missingAttributes = false
    let invalidHtml = false
    let otherErrors = false

    items.forEach(item => {
        if (item.includes('Uzupełnij brakujące parametry')) {
            missingAttributes = true
        } else if (item.includes('Invalid Html')) {
            invalidHtml = true
        } else {
            otherErrors = true
        }
    })

    const result = []

    if (missingAttributes) {
        result.push('Uzupełnij brakujące parametry kategorii Allegro')
    }

    if (invalidHtml) {
        result.push('Nieprawidłowy opis')
    }

    if (otherErrors) {
        result.push('Inne')
    }

    return result.join(', ')
}

const prepareItems = data => {
    return data.map(item => {
        return {
            ...item,
            errors: errors(item.auctions.map(auction => auction.lastError)),
        }
    })
}

export default reducer
