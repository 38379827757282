import { connect } from 'react-redux'
import { getUsers } from '../../actions/UserActions'
import { findOperationsInRegistry } from '../../actions/OperationRegistryActions'
import OperationRegistry from './components/OperationRegistry'


const mapStateToProps = ({ presto: state }) => {
    const { items: users, fetching: fetchingUsers } = state.users
    const { items, page, pageSize, fetching, filters } = state.operationRegistry

    return {
        users,
        fetchingUsers,
        items,
        page,
        pageSize,
        fetching,
        filters,
    }
}

export default connect(mapStateToProps, {
    getUsers,
    findOperationsInRegistry,
})(OperationRegistry)
