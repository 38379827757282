import { connect } from 'react-redux'
import ProductList from './components/ProductList'
import {
    findProducts,
    findProductsBySkuAndNameForList,
    importProduct,
    searchProductFromVerto,
    clearSearchProductInVerto,
} from '../../actions/ProductActions'
import { redirect } from '../../actions/RouterActions'

const mapStateToProps = state => {
    const {
        items,
        fetching: searchInProgress,
        page,
        pageSize,
        query,
    } = state.presto.products
    const { product, fetching } = state.presto.searchVertoProduct

    return {
        items,
        page,
        pageSize,
        query,
        fetching,
        product,
        searchInProgress,
    }
}

const Products = connect(mapStateToProps, {
    clearSearchProductInVerto,
    findProducts,
    findProductsBySkuAndName: findProductsBySkuAndNameForList,
    searchProductFromVerto,
    importProduct,
    redirect,
})(ProductList)

export default Products
