import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
} from '../../actions/types'


const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareAttributes(action.payload.vertoAttributes)

        default:
            return state
    }
}

const prepareAttributes = attributes => {
    if (!attributes) {
        return []
    }

    return attributes.map(({attributeId, value, name}) => ({id: attributeId, value, name}))
}

export default reducer


