import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {
    Icon,
    Typography,
    TextField,
    MenuItem,
} from '@material-ui/core'

import Page from '../../../components/Page'
import List from '../../../components/List'


const styles = theme => ({

})


class ProductErrors extends React.Component {
    componentDidMount() {
        const { page, pageSize, filter } = this.props
        this.props.getProductsWithErrors(filter, page, pageSize)
    }

    getProducts = (event, page) => {
        const { pageSize, filter } = this.props
        page += 1
        this.props.getProductsWithErrors(filter, page, pageSize)
    }

    onChangePageSize = pageSize => {
        const { filter } = this.props
        const page = 0
        this.props.getProductsWithErrors(filter, page, pageSize)
    }

    onChangeFilter = filter => {
        const page = 1
        const { pageSize } = this.props

        this.props.changeProductWithErrorsFilter(filter)
        this.props.getProductsWithErrors(filter, page, pageSize)
    }

    render() {
        const { classes, items, filter } = this.props
        const filters = [
            { value: 'ALL', label: 'wszystkie' },
            { value: 'ALLEGRO_MISSING_ATTRIBUTES', label: 'Brakujące atrybuty allegro' },
            { value: 'ALLEGRO_INVALID_HTML', label: 'Niepoprawny opis' },
            { value: 'DEFAULT', label: 'Inne' },
        ]

        const values = items.map(item => {
            return {
                actions: [],
                columns: [item.name, item.sku, item.errors],
                onClick: () => window.open(`/product/${item.id}`)
            }
        })

        const filterInput = (
            <TextField
                select
                value={filter}
                onChange={event => this.onChangeFilter(event.target.value)}
            >
                {filters.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        )

        values.unshift({
            actions: [],
            columns: ['', '', filterInput]
        })

        const columns = [
            { name: 'Produkt' },
            { name: 'Numer katalogowy' },
            { name: 'Błąd' },
        ]

        return (
            <Page
                pageTitle="Błędy"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">bug_report</Icon>
                )}
                content={(
                    <>
                        <Typography variant="h6">Lista produktów</Typography>
                        <div
                            className={classes.list}
                        >
                            <List
                                columns={columns}
                                loading={this.props.fetching}
                                onChangePage={this.getProducts}
                                onChangeRowsPerPage={event => this.onChangePageSize(event.target.value)}
                                page={this.props.page - 1}
                                paginated={true}
                                rowsPerPage={this.props.pageSize}
                                values={values}
                            />
                        </div>
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles)(ProductErrors)
