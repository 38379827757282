import {
    AUCTIONS_FETCHING_FAILURE,
    AUCTIONS_FETCHING_SUCCESS,
    AUCTIONS_FETCHING,
    AUCTIONS_FILTER_CHANGE,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: [],
    page: 0,
    pageSize: 10,
    filters: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUCTIONS_FETCHING:
            return {
                ...state,
                filters: action.filters,
                fetching: true,
            }

        case AUCTIONS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: action.payload.items,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
            }

        case AUCTIONS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case AUCTIONS_FILTER_CHANGE:
            return {
                ...state,
                filters: action.filters,
            }

        default:
            return state
    }
}


export default reducer
