import {
    SEARCH_CATEGORIES_FETCHING,
    SEARCH_CATEGORIES_FETCHING_SUCCESS,
    SEARCH_CATEGORIES_FETCHING_FAILURE,
    CATEGORY_IMPORT_IN_PROGRESS,
    CATEGORY_IMPORT_FAILURE,
    CATEGORY_IMPORT_SUCCESS,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: [],
    page: 0,
    pageSize: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CATEGORY_IMPORT_IN_PROGRESS:
        case SEARCH_CATEGORIES_FETCHING:
            return {
                ...state,
                fetching: true
            }

        case SEARCH_CATEGORIES_FETCHING_SUCCESS:
            return {
                ...state,
                items: prepareItems(action.payload),
                page: action.payload.page,
                pageSize: action.payload.page_size,
                fetching: false
            }

        case SEARCH_CATEGORIES_FETCHING_FAILURE:
        case CATEGORY_IMPORT_FAILURE:
            return {
                ...state,
                fetching: false
            }

        case CATEGORY_IMPORT_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: [{
                    id: action.payload.id,
                    name: action.payload.name,
                }],
            }

        default:
            return state
    }
}

const prepareItems = (data) => {
    const { items } = data
    return items.map(({ id, path: name }) => ({ id, name }))
}

export default reducer
