import { connect } from 'react-redux'
import DeliveryForm from './components/DeliveryForm'
import {
    getDelivery,
    updateDelivery,
    saveDelivery,
    clearDelivery,
} from '../../actions/DeliveryActions'
import { getDeliveryOptionTypes } from '../../actions/DeliveryOptionTypeActions'


const mapStateToProps = ({ presto: state }) => {
    const { data, fetching: deliveryFetching  } = state.delivery
    const { items: deliveryOptionTypes, fetching: optionsFetching } = state.deliveryOptionTypes

    const fetching = deliveryFetching || optionsFetching

    return {
        data,
        fetching,
        deliveryOptionTypes,
    }
}

export default connect(mapStateToProps, {
    getDelivery,
    updateDelivery,
    saveDelivery,
    getDeliveryOptionTypes,
    clearDelivery,

})(DeliveryForm)

