import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Icon} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Page from '../../../components/Page'
import List from '../../../components/List'


const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },

    pre: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
    },

    title: {
    },

    divider : {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },

    paper: {
        marginTop: theme.spacing.unit,
        padding: theme.spacing.unit,
    },
})


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={{enter: 0, exit: 300}} ref={ref} {...props} />
})


class OperationRegistry extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: null
        }
    }

    componentDidMount() {
        this.props.getUsers()
        this.props.findOperationsInRegistry()
    }

    getOperations = (event, page) => {
        const { pageSize, filters } = this.props
        page += 1
        this.props.findOperationsInRegistry(filters, page, pageSize)
    }

    onChangePageSize = pageSize => {
        const page = 0
        const { filters } = this.props
        this.props.findOperationsInRegistry(filters, page, pageSize)
    }

    onFilter = values => {
        const filters = Object.entries(values)
            .filter(entry => entry[1])
            .map(entry => {
                const filter = entry[0]
                let value = entry[1]
                let operator = 'like'

                return {
                    filter,
                    value,
                    operator,
                }
            })

        this.props.findOperationsInRegistry(filters, 0, this.props.pageSize)
    }

    onClose = () => this.setState({id: null})

    render() {
        const { classes, items, fetching, page, pageSize, users } = this.props

        const inputs = [
            {
                name: 'action',
                label: 'Akcja',
                select: [
                    { value: 'ProductCreate', label: 'Utworzenie produktu' },
                    { value: 'SaveProduct', label: 'Modyfikacja produktu' },
                    { value: 'StopAuction', label: 'Zatrzymanie aukcje' },
                    { value: 'StartAuction', label: 'Aktywacja aukcji produktu' },
                    { value: 'PriceChange', label: 'Zmiana ceny' },
                    { value: 'AuctionCreate', label: 'Dodanie aukcji do harmonogramu' },
                ]
            },
            {
                name: 'user',
                label: 'Użytkownik',
                select: users.map(item => ({ value: item.login, label: item.login })),
            },
        ]

        const values = items.map(item => {
            return {
                actions: [ ],
                columns: [
                    item.localName,
                    item.product.name,
                    item.user,
                    item.date,
                    item.details,
                ],
                onClick: () => this.setState({id: item.id}),
            }
        })

        const columns = [
            { name: 'Akcja' },
            { name: 'Produkt' },
            { name: 'Użytkownik' },
            { name: 'Data' },
            { name: 'Szczegóły' },
        ]

        const open = this.state.id != null
        let d = {before: null, after: null}

        if (open) {
            d = items.find(item => item.id === this.state.id)
        }

        return (
            <Page
                pageTitle="Rejestr operacji"
                headerIcon={(
                    <Icon className="text-32 mr-0 sm:mr-12">data_usage</Icon>
                )}

                content={(
                        <>
                            <List
                                columns={columns}
                                inputs={inputs}
                                loading={fetching}
                                onChangePage={this.getOperations}
                                onChangeRowsPerPage={event => this.onChangePageSize(event.target.value)}
                                onSearch={this.onFilter}
                                page={page - 1}
                                paginated={true}
                                rowsPerPage={pageSize}
                                values={values}
                            />
                            <Dialog
                                open={open}
                                onClose={this.onClose}
                                fullScreen
                                TransitionComponent={Transition}
                            >
                                <DialogTitle className={classes.title}>
                                    Szczegóły - {d.localName} ({d.action})
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">Użytkownik: {d.user}</Typography>
                                            <Typography variant="body1">Data: {d.date}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Przed:</Typography>
                                            <Paper className={classes.paper}>
                                                <pre className={classes.pre}>{JSON.stringify(d.before, null, 4)}</pre>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Po:</Typography>
                                            <Paper className={classes.paper}>
                                                <pre className={classes.pre}>{JSON.stringify(d.after, null, 4)}</pre>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        color="primary"
                                        onClick={this.onClose}
                                    >
                                        Zamknij
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                )}
            />
        )
    }
}

export default withStyles(styles)(OperationRegistry)
