import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
} from '@material-ui/core'


const Notification = ({ item, onClose, open, markAsRead }) => {
    if (!item) {
        return (
            <div></div>
        )
    }

    return (
        <Dialog onClose={onClose} open={open} maxWidth="md">
            <DialogTitle>
                {item.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {item.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => markAsRead(item.id)}
                >
                    Oznacz jako przeczytaną
                </Button>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="secondary"
                >
                    Zamknij
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Notification
