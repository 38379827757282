import {
    STATIC_BLOCKS_FETCHING,
    STATIC_BLOCKS_FETCHING_SUCCESS,
    STATIC_BLOCKS_FETCHING_FAILURE,
    STATIC_BLOCK_FETCHING,
    STATIC_BLOCK_FETCHING_SUCCESS,
    STATIC_BLOCK_FETCHING_FAILURE,
    STATIC_BLOCK_CHANGE,
    STATIC_BLOCK_SAVING_IN_PROGRESS,
    STATIC_BLOCK_SAVING_SUCCESS,
    STATIC_BLOCK_SAVING_FAILURE,
    STATIC_BLOCK_CLEAR,
    ADD_ATTRIBUTE_TO_STATIC_BLOCK,
    REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK,
    VERTO_ATTRIBUTE_FETCHING,
    VERTO_ATTRIBUTE_FETCHING_SUCCESS,
    VERTO_ATTRIBUTE_FETCHING_FAILURE,
    PRODUCT_STATIC_BLOCKS_FETCHING,
    PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE,
    PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS,
} from '../actions/types'

import Api from '../Api'

export const addAttributeToStaticBlock = (blockId, attributeId) => dispatch => {
    Api.addAttributeToStaticBlock(blockId, attributeId).then(response => {
        dispatch({
            type: ADD_ATTRIBUTE_TO_STATIC_BLOCK,
            payload: response.data,
        })
    })
}

export const removeAttributeFromStaticBlock = (
    blockId,
    attributeValueId
) => dispatch => {
    Api.removeAttributeFromStaticBlock(blockId, attributeValueId).then(() => {
        dispatch({
            type: REMOVE_ATTRIBUTE_FROM_STATIC_BLOCK,
            attributeValueId,
        })
    })
}

export const getStaticBlocks = (page = 1, pageSize = 10) => dispatch => {
    dispatch({ type: STATIC_BLOCKS_FETCHING })

    Api.getStaticBlocks(page, pageSize)
        .then(response => {
            dispatch({
                type: STATIC_BLOCKS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: STATIC_BLOCKS_FETCHING_FAILURE,
                error,
            })
        })
}

export const getStaticBlock = id => dispatch => {
    dispatch({ type: STATIC_BLOCK_FETCHING })

    Api.getStaticBlock(id)
        .then(response => {
            dispatch({
                type: STATIC_BLOCK_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: STATIC_BLOCK_FETCHING_FAILURE,
                error,
            })
        })
}

export const staticBlockChange = (name, value) => dispatch => {
    dispatch({
        type: STATIC_BLOCK_CHANGE,
        name,
        value,
    })
}

export const clearStaticBlock = () => dispatch => {
    dispatch({
        type: STATIC_BLOCK_CLEAR,
    })
}

export const saveStaticBlock = data => dispatch => {
    dispatch({
        type: STATIC_BLOCK_SAVING_IN_PROGRESS,
    })

    const {
        descriptionItemTypeId,
        header,
        text,
        notForAllegro,
        id,
        image1,
        image2,
        vertoAttributes,
        headerIcon,
    } = data

    const attributes = vertoAttributes.map(item => item.id)

    const request = {
        descriptionItemTypeId,
        header,
        text,
        notForAllegro,
        id,
        image1,
        image2,
        attributes,
        headerIcon: headerIcon == '' ? null : headerIcon,
    }

    Api.saveStaticBlock(request)
        .then(response => {
            dispatch({
                type: STATIC_BLOCK_SAVING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: STATIC_BLOCK_SAVING_FAILURE,
                payload: error,
            })
        })
}

export const getAttribute = idAttribute => dispatch => {
    dispatch({ type: VERTO_ATTRIBUTE_FETCHING })

    Api.importAttributeFromVerto(idAttribute)
        .then(response => {
            dispatch({
                type: VERTO_ATTRIBUTE_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: VERTO_ATTRIBUTE_FETCHING_FAILURE,
                payload: error,
            })
        })
}

export const getAllStaticBlocks = () => dispatch => {
    dispatch({ type: PRODUCT_STATIC_BLOCKS_FETCHING })

    Api.getAllStaticBlocks()
        .then(response => {
            dispatch({
                type: PRODUCT_STATIC_BLOCKS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_STATIC_BLOCKS_FETCHING_FAILURE,
                error,
            })
        })
}
