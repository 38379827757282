import {
    DELIVERIES_FETCHING,
    DELIVERIES_FETCHING_SUCCESS,
    DELIVERIES_FETCHING_FAILURE,
    DELIVERY_FETCHING,
    DELIVERY_FETCHING_SUCCESS,
    DELIVERY_FETCHING_FAILURE,
    DELIVERY_UPDATED,
    DELIVERY_SAVING,
    DELIVERY_SAVING_SUCCESS,
    DELIVERY_SAVING_FAILURE,
    DELIVERY_CLEAR,
} from '../actions/types'

import Api from '../Api'
import { objectToArray } from '../helpers'


export const getDeliveries = () => dispatch => {
    dispatch({ type: DELIVERIES_FETCHING })

    Api.getDeliveries()
        .then(response => {
            dispatch({
                type: DELIVERIES_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: DELIVERIES_FETCHING_FAILURE,
                payload: error
            })
        })
}

export const getDelivery = id => dispatch => {
    dispatch({ type:  DELIVERY_FETCHING })

    Api.getDelivery(id)
        .then(response => {
            dispatch({
                type: DELIVERY_FETCHING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_FETCHING_FAILURE,
                error
            })
        })
}

export const updateDelivery = (name, value) => dispatch => {
    dispatch({
        type: DELIVERY_UPDATED,
        name,
        value
    })
}

export const clearDelivery = () => dispatch => {
    dispatch({
        type: DELIVERY_CLEAR,
    })
}

export const saveDelivery = data => dispatch => {
    data = {
        ...data,
        deliveryOptions: objectToArray(data.deliveryOptions, (item, deliveryOptionTypeId) => ({
            ...item,
            deliveryOptionTypeId,
            firstPieceCost: Number(item.firstPieceCost).toFixed(2),
            nextPiecesCost: Number(item.nextPiecesCost).toFixed(2),
            quantity: Number(item.quantity).toFixed(),
        }))
    }

    dispatch({ type:  DELIVERY_SAVING })

    Api.saveDelivery(data)
        .then(response => {
            dispatch({
                type: DELIVERY_SAVING_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_SAVING_FAILURE,
                error
            })
        })
}
