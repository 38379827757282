import changeCase from 'change-case-object'
import { v1 as uuidv1 } from 'uuid'
import _ from 'lodash'
import moment from 'moment'
import jwt from 'jsonwebtoken'


export const toCamelCase = (data) => {
    let result = {}

    if (_.isArray(data)) {
        result = []

        data.forEach((value, index) => {
            if (_.isObject(value) || _.isArray(value)) {
                result[index] = toCamelCase(value)
            } else {
                result[index] = value
            }
        })

        return result
    }

    _.forOwn(data, (value, key) => {
        const newKey = changeCase.camel(key)
        if (_.isObject(value) || _.isArray(value)) {
            result[newKey] = toCamelCase(value)
        } else {
            result[newKey] = value
        }
    })

    return result
}

export const toSnakeCase = (data) => {
    let result = {}

    if (_.isArray(data)) {
        result = []

        data.forEach((value, index) => {
            if (_.isObject(value) || _.isArray(value)) {
                result[index] = toSnakeCase(value)
            } else {
                result[index] = value
            }
        })

        return result
    }

    _.forOwn(data, (value, key) => {
        const newKey = changeCase.snake(key)
        if (_.isObject(value) || _.isArray(value)) {
            result[newKey] = toSnakeCase(value)
        } else {
            result[newKey] = value
        }
    })

    return result
}

export const uuid = uuidv1

export const objectToArray = (object, f) => {
    const result = []

    _.forOwn(object, (value, key) => {
        const index = result.length
        result.push(f(value, key, index))
    })

    return result
}

export const nl2br = (text) => {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export const toCamelCase2 = (data) => {
    let result = {}

    if (_.isArray(data) && data.length === 0) {
        return []
    }

    if (_.isObject(data) && _.keys(data).length === 0) {
        return {}
    }

    _.forOwn(data, (value, key) => {
        const newKey = changeCase.camel(key)

        if (_.isObject(value) || _.isArray(value)) {
            result[newKey] = toCamelCase2(value)
        } else {
            result[newKey] = value
        }
    })

    return result
}

export const toSnakeCase2 = (data, result = null) => {
    _.forOwn(data, (value, key) => {
        const newKey = changeCase.snake(key)
        if (_.isObject(value) || _.isArray(value)) {
            result[newKey] = toSnakeCase2(value, result)
        } else {
            result[newKey] = value
        }
    })

    return result
}

export const isLogged = () => {
    const token = localStorage.getItem('token')

    if (!token) {
        return false
    }

    const decoded = jwt.decode(token)

    if (!decoded) {
        return false
    }

    const exp = moment(decoded.exp)
    const now = moment()

    if (now > exp) {
        return false
    }

    return true
}
