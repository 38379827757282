import {
    INCUBO_OFFERS_FETCHING,
    INCUBO_OFFERS_FETCHING_SUCCESS,
    INCUBO_OFFERS_FETCHING_FAILURE,

    INCUBO_OFFER_FETCHING_SUCCESS,
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: {},
    ids: [],
    page: 0,
    pageSize: 10,
    total: 0,
}

const reducer = (state = INITIAL_STATE, action) => {
    let items = {}

    switch (action.type) {
        case INCUBO_OFFER_FETCHING_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.offerId]: action.payload,
                }
            }

        case INCUBO_OFFERS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case INCUBO_OFFERS_FETCHING_SUCCESS:
            action.payload.items.forEach(it => {
                items[it.offerId] = it
            })

            return {
                ...state,
                items,
                page: action.payload.pagination.page,
                pageSize: action.payload.pagination.pageSize,
                total: action.payload.pagination.total,
                ids: action.payload.items.map(it => it.offerId),
                fetching: false,
            }

        case INCUBO_OFFERS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        default:
            return state
    }
}

export default reducer
