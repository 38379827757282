import Api from '../../Api'
import {
    PRESTASHOP_CATEGORIES_FETCHING,
    PRESTASHOP_CATEGORIES_FETCH_SUCCESS,
    PRESTASHOP_CATEGORIES_FETCH_FAILURE,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCHING,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS,
    PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE,
    PRESTASHOP_PRODUCT_CATEGORIES_UPDATING,
    PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS,
    PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_FAILURE,
} from '../types'

export const getCategoriesFromPrestaShop = () => dispatch => {
    dispatch({
        type: PRESTASHOP_CATEGORIES_FETCHING,
    })

    Api.getPrestaShopCategories()
        .then(response => {
            dispatch({
                type: PRESTASHOP_CATEGORIES_FETCH_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRESTASHOP_CATEGORIES_FETCH_FAILURE,
                error,
            })
        })
}

export const getProductCategoriesFromPrestaShop = id => dispatch => {
    dispatch({
        type: PRESTASHOP_PRODUCT_CATEGORIES_FETCHING,
    })

    Api.getPrestaShopProductCategories(id)
        .then(response => {
            dispatch({
                type: PRESTASHOP_PRODUCT_CATEGORIES_FETCH_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRESTASHOP_PRODUCT_CATEGORIES_FETCH_FAILURE,
                error,
            })
        })
}

export const updateProductCategoriesInPrestaShop = (
    id,
    categories
) => dispatch => {
    dispatch({
        type: PRESTASHOP_PRODUCT_CATEGORIES_UPDATE_SUCCESS,
        payload: categories,
    })
}
