import 'babel-polyfill'
import 'typeface-muli'
import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'
import './react-table-defaults'
import './styles/index.css'
import JssProvider from 'react-jss/lib/JssProvider'
import { create } from 'jss'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { routes } from './fuse-configs/fuseRoutesConfig'
import { FuseLayout, FuseTheme, FuseAuthorization } from '@fuse'
import MainToolbar from './main/MainToolbar'
import MainNavbarContent from './main/MainNavbarContent'
import MainNavbarHeader from './main/MainNavbarHeader'
import MainFooter from './main/MainFooter'
import jssExtend from 'jss-extend'
import QuickPanel from 'main/quickPanel/QuickPanel'
import store from 'store'
import SettingsPanel from 'main/SettingsPanel'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import Api from 'apps/presto/Api'

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
})

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

const checkLogin = () => {
    // const token = localStorage.getItem('token')

    // if (token) {
    //     Api.isLogged(token)
    //         .then(response => {
    //             const isLogged = response.data.isLogged

    //             if (!isLogged) {
    //                 localStorage.removeItem('token')
    //                 localStorage.removeItem('user')
    //             }
    //         })
    //         .catch(() => {
    //             localStorage.removeItem('token')
    //             localStorage.removeItem('user')
    //         })
    // }

    const token = localStorage.getItem('token')

    if (!token) {
        return false
    }

    const decoded = jwt.decode(token)

    if (!decoded) {
        return false
    }

    const exp = moment(decoded.exp)
    const now = moment()

    if (now > exp) {
        return false
    }

    return true
}

checkLogin()

ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <FuseAuthorization routes={routes}>
                    <FuseTheme>
                        <FuseLayout
                            routes={routes}
                            toolbar={<MainToolbar />}
                            navbarHeader={<MainNavbarHeader />}
                            navbarContent={<MainNavbarContent />}
                            footer={<MainFooter />}
                            rightSidePanel={
                                <React.Fragment>
                                    <QuickPanel />
                                </React.Fragment>
                            }
                            contentWrapper={<SettingsPanel />}
                        ></FuseLayout>
                    </FuseTheme>
                </FuseAuthorization>
            </ConnectedRouter>
        </Provider>
    </JssProvider>,
    document.getElementById('root')
)

registerServiceWorker()
