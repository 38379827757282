export const TEXT_IMAGE = 1
export const IMAGE_TEXT = 2
export const TEXT = 3
export const IMAGES = 4
export const BUNDLE_ITEM = 5
export const STATIC_BLOCK = 6
export const IMAGE_BOTTOM_TEXT = 7
export const TABLE = 8
export const YOUTUBE = 9
export const LINK = 10
