import { NEW_POPUP, REMOVE_POPUP } from '../actions/types'

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEW_POPUP:
            const newState = state.slice()
            newState.push({
                severity: action.severity,
                text: action.text,
            })
            return newState

        case REMOVE_POPUP:
            const slice = state.slice()
            slice.splice(action.index, 1)
            return slice

        default:
            return state
    }
}

export default reducer
