import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '../../../../components/Input'
import IconButton from '../../../../components/IconButton'
import List from '../../../../components/List'


class AttributeMappingDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: 0
        }
    }

    onChange = event => {
        const id = event.target.value
        this.setState({ id })
    }

    renderMapping = () => {
        const {
            id,
            name,
            vertoAttributes,
        } = this.props.attribute.data

        const {
            vertoAttributes: possibleAttributes,
            removeMapping,
        } = this.props

        const columns = [
            { name: 'Allegro' },
            { name: 'Verto' },
        ]

        const values = vertoAttributes
            .filter(item => possibleAttributes.find(item2 => item2.id === item.id))
            .map(attribute => ({
                actions: [(
                    <IconButton
                        key={1}
                        onClick={() => removeMapping(id, attribute.id)}
                        icon="remove"
                    />
                )],
                columns: [name, attribute.name]
            }))

        return (
            <>
                <h3 className="mt-20">Lista atrybutów</h3>
                <List
                    columns={columns}
                    values={values}
                />
           </>
        )
    }

    render() {
        const {
            open,
            onClose,
            vertoAttributes,
            mapAttribute,
        } = this.props

        const { id: vertoAttributeId } = this.state
        const attribute = this.props.attribute.data

        const sortByName = (a, b) => {
            let comparison = 0

            const aName = a.name.toUpperCase()
            const bName = b.name.toUpperCase()

            if (aName > bName) {
                comparison = 1
            }

            if (aName < bName) {
                comparison = -1
            }

            return comparison
        }

        const options = vertoAttributes
            .sort(sortByName)
            .map((item, index) => (
                <MenuItem key={index} value={item.id}>
                    {item.name}
                </MenuItem>
            ))

        options.unshift(
            <MenuItem key={options.length} value={0}>
                -- Wybierz --
            </MenuItem>
        )

        return (
            <React.Fragment>
                {attribute && (
                    <Dialog
                        open={open}
                        onClose={onClose}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>Mapowanie atrybutów - {attribute.name}</DialogTitle>
                        <DialogContent>
                            <div className="flex">
                                <Input
                                    label={attribute.name}
                                    value={vertoAttributeId}
                                    select
                                    onChange={this.onChange}
                                    fullWidth
                                    className="mr-8"
                                >
                                    {options}
                                </Input>
                                <IconButton
                                    onClick={() => mapAttribute(attribute.id, vertoAttributeId)}
                                    icon="add"
                                    disabled={vertoAttributeId === 0}
                                />
                            </div>
                            {this.renderMapping()}
                        </DialogContent>
                        <DialogActions>
                            <IconButton
                                onClick={onClose}
                                icon="close"
                            >
                                Zamknij
                            </IconButton>
                        </DialogActions>
                    </Dialog>
                )}
            </React.Fragment>
        )
    }
}

export default AttributeMappingDialog
