import axios from 'axios'
import { dispatch } from 'store'
import { addError } from './actions/ErrorActions'
import { toSnakeCase, toCamelCase } from './helpers'
import { LOGIN_FAILURE } from './actions/types'

// const baseURL = 'http://prestosandbox.eltrox.pl/api/'
// const baseURL = 'http://localhost:5000/api/'
const baseURL = 'http://presto.eltrox.pl/api/'

const instance = axios.create({
    baseURL,
    timeout: 30000,
})

const incubo = axios.create({
    baseURL: 'http://176.119.33.140/api',
    timeout: 30000,
})

const uploadImages = axios.create({
    baseURL: 'http://176.119.38.100/upload/fileUpload.php',
    timeout: 30000,
})

const myCi = axios.create({
    baseURL: 'http://myci.eltrox.pl/',
    timeout: 30000,
})

const uploadImages2 = axios.create({
    baseURL,
    timeout: 30000,
})

uploadImages2.interceptors.request.use(
    config => {
        return {
            ...config,
            params: {
                token: localStorage.getItem('token'),
            },
        }
    },
    error => {
        return Promise.reject(error)
    }
)

uploadImages.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        return response
    },
    error => {
        if (error.response && error.response.status === 401) {
            dispatch({ type: LOGIN_FAILURE })
            return Promise.reject(error)
        }

        const err =
            error.response &&
                error.response.data != null &&
                error.response.data.errors
                ? error.response.data.errors
                : [{ type: 'ERROR', message: String(error) }]

        addError(err)(dispatch)
        return Promise.reject(error)
    }
)

uploadImages2.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        return response
    },
    error => {
        if (error.response && error.response.status === 401) {
            dispatch({ type: LOGIN_FAILURE })
            return Promise.reject(error)
        }

        const err =
            error.response &&
                error.response.data != null &&
                error.response.data.errors
                ? error.response.data.errors
                : [{ type: 'ERROR', message: String(error) }]

        addError(err)(dispatch)
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        return response
    },
    error => {
        if (error.response && error.response.status === 401) {
            dispatch({ type: LOGIN_FAILURE })
            return Promise.reject(error)
        }

        const err =
            error.response &&
                error.response.data != null &&
                error.response.data.errors
                ? error.response.data.errors
                : [{ type: 'ERROR', message: String(error) }]

        addError(err)(dispatch)
        return Promise.reject(error)
    }
)

instance.interceptors.request.use(
    config => {
        const newConfig = {
            ...config,
            params: {
                token: localStorage.getItem('token'),
            },
        }

        if (config['data']) {
            newConfig['data'] = toSnakeCase(config['data'])
        }

        if (config['params']) {
            newConfig['params'] = {
                ...newConfig['params'],
                ...toSnakeCase(config['params']),
            }
        }

        return newConfig
    },
    error => {
        return Promise.reject(error)
    }
)

incubo.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        return response
    },
    error => {
        if (error.response && error.response.status === 401) {
            dispatch({ type: LOGIN_FAILURE })
            return Promise.reject(error)
        }

        const err =
            error.response &&
                error.response.data != null &&
                error.response.data.errors
                ? error.response.data.errors
                : [{ type: 'ERROR', message: String(error) }]

        addError(err)(dispatch)
        return Promise.reject(error)
    }
)

incubo.interceptors.request.use(
    config => {
        const newConfig = {
            ...config,
            params: {
                token: localStorage.getItem('token'),
            },
        }

        if (config['data']) {
            newConfig['data'] = toSnakeCase(config['data'])
        }

        if (config['params']) {
            newConfig['params'] = {
                ...newConfig['params'],
                ...toSnakeCase(config['params']),
            }
        }

        return newConfig
    },
    error => {
        return Promise.reject(error)
    }
)

const Api = {
    getProduct: id => instance.get(`product/${id}`),
    saveProduct: product => instance.post('product/save', product),
    cloneProduct: (id, sku) => instance.post(`product/${id}/clone`, { sku }),
    findProducts: filters => instance.post('product/find', filters),
    findProductsBySkuAndName: (query, page = 0, pageSize = 10) =>
        instance.get('product/find-by-sku-and-name', {
            params: { query, page, pageSize },
        }),
    findProductsBySkuAndNameForList: (query, page = 1, pageSize = 10) =>
        instance.get('product/find-by-sku-and-name-for-list', {
            params: { query, page, pageSize },
        }),
    addProductTitle: (id, title) =>
        instance.post(`product/${id}/title`, {
            title,
        }),
    removeProductTitle: (productId, titleId) =>
        instance.delete(`product/${productId}/title/${titleId}`),
    addProductImage: (id, url) =>
        instance.post(`product/${id}/image`, {
            url,
        }),
    removeProductImage: (productId, imageId) =>
        instance.delete(`product/${productId}/image/${imageId}`),
    addProductAuction: (productId, data) =>
        instance.post(`product/${productId}/auction`, data),
    removeProductAuction: (productId, auctionId) =>
        instance.delete(`product/${productId}/auction/${auctionId}`),
    addProductCategory: (productId, categoryId) =>
        instance.post(`product/${productId}/category/${categoryId}`),
    removeProductCategory: (productId, categoryId) =>
        instance.delete(`product/${productId}/category/${categoryId}`),

    getDescriptionItemTypes: () => instance.get('description/item/types'),

    findCategories: filters => instance.post('category/find', filters),
    getCategory: id => instance.get(`category/${id}`),
    importCategory: id => instance.get(`category/import-from-allegro/${id}`),

    getDeliveryOptionTypes: () => instance.get('delivery/option/types'),

    getAllegroAccounts: () => instance.get('allegro/accounts'),
    authorizeAccount: id => instance.get(`allegro/account/${id}/authorize`),

    importProduct: sku => instance.get(`verto/product/import?sku=${sku}`),
    getProductFromVerto: sku => instance.get(`verto/product/get?sku=${sku}`),

    getProductHistory: (id, page = 1, pageSize = 10) =>
        instance.get(
            `product/${id}/history?page=${page}&page_size=${pageSize}`
        ),

    saveAuctionToAllegro: id => instance.get(`allegro/auction/${id}/save`),
    activateAuction: id => instance.get(`allegro/auction/${id}/activate`),
    stopAuction: id => instance.get(`allegro/auction/${id}/stop`),

    getDeliveries: () => instance.get('deliveries'),
    getDelivery: id => instance.get(`delivery/${id}`),
    saveDelivery: data => instance.post('delivery', data),

    getAllStaticBlocks: () => instance.get('static-blocks'),

    getStaticBlocks: (page = 1, pageSize = 10) =>
        instance.get('static-block/list', {
            params: {
                page,
                pageSize,
            },
        }),
    getStaticBlock: id => instance.get(`static-block/${id}`),
    saveStaticBlock: data => instance.post('static-block', data),

    login: (username, password) =>
        instance.post('/auth/login', {
            username,
            password,
        }),

    logout: token => instance.get(`/auth/logout?token=${token}`),

    isLogged: token => instance.get(`/auth/is-logged?token=${token}`),

    findVertoAttributes: filters =>
        instance.post('verto/attributes/find', {
            filters,
        }),

    getVertoAttribute: idAttribute =>
        instance.get(`verto/attributes/${idAttribute}`),

    addAttributeToStaticBlock: (staticBlockId, attributeId) =>
        instance.post(`static-block/${staticBlockId}/attribute`, {
            attributeId,
        }),

    removeAttributeFromStaticBlock: (staticBlockId, attributeValueId) =>
        instance.delete(
            `static-block/${staticBlockId}/attribute/${attributeValueId}`
        ),

    findAttributesInVertoByName: name =>
        instance.post('verto/attributes/find-in-verto-by-name', {
            name,
        }),

    importAttributeFromVerto: idAttribute =>
        instance.get(`verto/attributes/import-from-verto/${idAttribute}`),

    mapVertoAttributeToAllegroAttribute: (attributeId, vertoAttributeId) =>
        instance.post('attribute/map-verto-attributes', {
            attributeId,
            vertoAttributeId,
        }),

    removeMappingBetweenVertoAndAllegroAttribute: (
        attributeId,
        vertoAttributeId
    ) =>
        instance.delete(
            `attribute/${attributeId}/verto-attribute/${vertoAttributeId}/remove-mapping`
        ),

    getAttribute: attributeId => instance.get(`attribute/${attributeId}`),

    getWarranties: () => instance.get('warranty/list'),

    getAuctions: page =>
        instance.post('/auctions', {
            page,
        }),

    findAuctions: (filters = [], page = 0, pageSize = 10) =>
        instance.post('/auctions/find', {
            page,
            pageSize,
            filters,
        }),

    swapBundleItem: (productId, oldItemId, newItemId) =>
        instance.post(`/product/${productId}/swap-bundle-item`, {
            oldItemId,
            newItemId,
        }),

    imageUpload: (data, sku, name) =>
        uploadImages2.post(`/image2?sku=${sku}&name=${name}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }),

    getMagentoCategories: () => myCi.get('Danzaw/categories_json'),
    getMagentoCategoriesForEltrox: () => myCi.get('Danzaw/categories_json/6'),

    getProductsWithErrors: (errorType, page, pageSize) =>
        instance.post('products/with-errors', {
            errorType,
            page,
            pageSize,
        }),

    getUsers: () => instance.get('/users'),

    findOperationsInRegistry: (filters, page, pageSize) =>
        instance.post('/operation-registry/find', {
            filters,
            page,
            pageSize,
        }),

    getUnreadUserNotifications: (page = 1) =>
        instance.get(`/notifications/unread?page=${page}`),

    getNotification: id => instance.get(`/notifications/${id}`),

    markNotificationAsRead: id => instance.get(`/notifications/${id}/read`),

    getProductAuctions: sku => instance.get(`/auctions/product/${sku}`),

    monitorPrice: (sku, competitorOfferId, pmId) =>
        incubo.post('/competitor-offers/monitor-price', {
            sku,
            competitorOfferId,
            pmId,
        }),

    stopMonitoringPrice: offerId =>
        incubo.delete(`/competitor-offers/monitor-price/${offerId}`),

    getMonitoredOffers: offerId =>
        incubo.get(`/competitor-offers/monitor-price/${offerId}`),

    getCompetitorOfferDescriptionDiff: offerId =>
        incubo.get(`/competitor-offers/${offerId}/diff`),

    getCompetitorOffer: offerId => incubo.get(`/competitor-offers/${offerId}`),

    getIncuboEvents: (page = 1, pageSize = 10) =>
        incubo.get(`/events?page=${page}&page_size=${pageSize}`),

    removeEmphasize: auctionId =>
        instance.get(`/allegro/auction/${auctionId}/remove-emphasize`),
    addEmphasize: auctionId =>
        instance.get(`/allegro/auction/${auctionId}/add-emphasize`),

    getPurchasePriceAndRotation: sku =>
        instance.get(`/products/${sku}/rotation-and-purchase-price`),

    findAllegroProduct: phrase =>
        instance.post('/allegro/product', {
            phrase,
        }),

    saveAllegroProduct: (allegroProductId, productId, categoryId) =>
        instance.post('/allegro/product/save', {
            allegroProductId,
            productId,
            categoryId,
        }),

    saveAllAllegroProducts: products =>
        instance.post('/allegro/products/save', {
            products,
        }),

    getAllCategoriesWithRealStock: () =>
        instance.get('/real-stock-in-categories'),

    saveRealStockInCategory: data =>
        instance.post('/real-stock-in-categories', data),

    updateDescriptionInBaselinker: sku =>
        instance.get(`/baselinker/update-description/${sku}`),

    getPrestaShopCategories: () => instance.get('/prestashop/get-categories'),

    getPrestaShopProductCategories: id =>
        instance.get(`/prestashop/get-product-categories/${id}`),

    updatePrestaShopProductCategories: (id, categories) =>
        instance.post(`/prestashop/update-product-categories`, {
            id,
            categories,
        }),

    getAllIcons: () => instance.get('/icons'),
}

export default Api
