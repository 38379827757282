import React from 'react'
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import {
    TEXT_IMAGE,
    IMAGE_TEXT,
    TEXT,
    IMAGES,
    BUNDLE_ITEM,
    STATIC_BLOCK,
    IMAGE_BOTTOM_TEXT,
    TABLE,
    YOUTUBE,
    LINK,
} from '../../../../consts'

import IFrame from '../../../../components/IFrame'

const styles = theme => ({
    gridRoot: {
        marginBottom: theme.spacing.unit * 2,
    },

    item: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        fontSize: '6px',
        lineHeight: `${6 * 1.375}px`,
    },

    itemText: {
        fontSize: '2em',
        lineHeight: '1.375em',
        fontFamily: '"Open Sans", Muli, Roboto, Arial, sans-serif',
    },

    itemHeader: {
        fontSize: '3em',
        lineHeight: '1em',
        marginTop: `${2 / 3}em`,
        marginBottom: `${2 / 3}em`,
        fontFamily: '"Open Sans", Muli, Roboto, Arial, sans-serif',
    },

    img: {
        display: 'block',
        margin: '0 auto',
    },

    iframe: {
        display: 'block',
        margin: '0 auto',
    },

    icon: {
        maxHeight: '2em',
    },
})

const isEmpty = value => {
    return [null, undefined].includes(value)
}

const listText = {
    padding: 0,
    fontSize: '2em',
    lineHeight: '1.375em',
    fontFamily: '"Open Sans", Muli, Roboto, Arial, sans-serif',
}

const ItemText = props => {
    const { classes, text } = props

    return <span className={classes.itemText} dangerouslySetInnerHTML={text} />
}

const ItemHeader = props => {
    const { classes, header, iconUrl } = props
    let icon = ''

    if (iconUrl) {
        icon = (
            <img
                src={iconUrl}
                className={classNames(classes.icon, 'mr-12', 'align-middle')}
            />
        )
    }

    return (
        <Typography className={classNames(classes.itemHeader)} variant="h6">
            {icon}
            <span className="align-middle">{header}</span>
        </Typography>
    )
}

class DescriptionItemPreview extends React.Component {
    renderItem = () => {
        let {
            descriptionItemTypeId,
            header,
            image1,
            image2,
            text,
            staticBlock,
            staticBlockId,
            bundleItem,
            bundleItemId,
            classes,
            json,
            showAllegro,
            youtubeLink,
            headerIcon,
        } = this.props

        if (descriptionItemTypeId === STATIC_BLOCK && staticBlockId !== null) {
            descriptionItemTypeId = staticBlock.descriptionItemTypeId
            header = staticBlock.header
            headerIcon = staticBlock.headerIcon
            image1 = staticBlock.image1Id ? staticBlock.image1.url : ''
            image2 = staticBlock.image2Id ? staticBlock.image2.url : ''
            text = staticBlock.text
        }

        if (
            descriptionItemTypeId === BUNDLE_ITEM &&
            bundleItemId !== null &&
            !isEmpty(bundleItem)
        ) {
            header = `${bundleItem.quantity}x ${bundleItem.name}`
            image1 = bundleItem.image
            image2 = null
            text = bundleItem.shortDescription

            if (image1) {
                descriptionItemTypeId = IMAGE_TEXT
            } else {
                descriptionItemTypeId = TEXT
            }
        }

        let anchors = [];

        if (descriptionItemTypeId === LINK) {
            const links = text.split("\n")
            anchors = links.map(text => {
                const [name, url] = text.split("|")
                return <a key={name} className={classes.itemText} href={url}>{name}</a>
            })
        }

        text = { __html: text }

        switch (descriptionItemTypeId) {
            case IMAGE_TEXT:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={6}>
                            <img
                                className={classes.img}
                                src={image1 == null ? '' : image1}
                                alt={image1}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={6}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                            <ItemText classes={classes} text={text} />
                        </Grid>
                    </Grid>
                )

            case TEXT_IMAGE:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={6}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                            <ItemText classes={classes} text={text} />
                        </Grid>
                        <Grid className={classes.item} item xs={6}>
                            <img
                                className={classes.img}
                                src={image1 == null ? '' : image1}
                                alt={image1}
                            />
                        </Grid>
                    </Grid>
                )

            case IMAGES:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        {image2 ? (
                            <>
                                <Grid className={classes.item} item xs={6}>
                                    <img
                                        className={classes.img}
                                        src={image1 == null ? '' : image1}
                                        alt={image1}
                                    />
                                </Grid>
                                <Grid className={classes.item} item xs={6}>
                                    <img
                                        className={classes.img}
                                        src={image2 == null ? '' : image2}
                                        alt={image2}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid className={classes.item} item xs={12}>
                                <img
                                    className={classes.img}
                                    src={image1 == null ? '' : image1}
                                    alt={image1}
                                />
                            </Grid>
                        )}
                    </Grid>
                )

            case IMAGE_BOTTOM_TEXT:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            <ItemText classes={classes} text={text} />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            <img
                                className={classes.img}
                                src={image1 == null ? '' : image1}
                                alt={image1}
                            />
                        </Grid>
                    </Grid>
                )

            case TABLE:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            {showAllegro ? (
                                <ul className={classes.ul}>
                                    {json.data.map((row, index) => (
                                        <li style={listText} key={index}>
                                            <span>
                                                {json.cols.map(
                                                    (column, colIndex) => {
                                                        const value =
                                                            row[column.key]

                                                        if (!value) {
                                                            return ''
                                                        }

                                                        if (
                                                            json.boldedRows.includes(
                                                                index
                                                            ) ||
                                                            json.boldedCols.includes(
                                                                colIndex
                                                            )
                                                        ) {
                                                            return (
                                                                <b
                                                                    key={
                                                                        colIndex
                                                                    }
                                                                >{`${value}${
                                                                    String(
                                                                        value
                                                                    ).endsWith(
                                                                        ':'
                                                                    )
                                                                        ? ''
                                                                        : ':'
                                                                } `}</b>
                                                            )
                                                        }

                                                        return `${value} `
                                                    }
                                                )}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <Table>
                                    <TableBody>
                                        {json.data.map((row, index) => (
                                            <TableRow key={index}>
                                                {json.cols.map(
                                                    (column, colIndex) => {
                                                        const value =
                                                            row[column.key]

                                                        const isBolded = json.boldedCells.find(
                                                            item =>
                                                                item.row ===
                                                                    index &&
                                                                item.col ===
                                                                    colIndex
                                                        )

                                                        if (isBolded) {
                                                            return (
                                                                <TableCell
                                                                    key={
                                                                        column.key
                                                                    }
                                                                >
                                                                    <b>
                                                                        {value}
                                                                    </b>
                                                                </TableCell>
                                                            )
                                                        }

                                                        return (
                                                            <TableCell
                                                                key={column.key}
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        )
                                                    }
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </Grid>
                    </Grid>
                )

            case YOUTUBE:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            <IFrame
                                className={classes.iframe}
                                link={youtubeLink}
                            />
                        </Grid>
                    </Grid>
                )
            case LINK:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            <ul>
                            {anchors.map((anchor, index) => {
                                return (
                                    <li key={index}>
                                            {anchor}
                                    </li>
                                )
                            })}
                            </ul>
                        </Grid>
                    </Grid>
                )

            case TEXT:
            case STATIC_BLOCK:
            case BUNDLE_ITEM:
            default:
                return (
                    <Grid container alignItems="center">
                        <Grid className={classes.item} item xs={12}>
                            <ItemHeader
                                classes={classes}
                                header={header}
                                iconUrl={headerIcon}
                            />
                        </Grid>
                        <Grid className={classes.item} item xs={12}>
                            <ItemText classes={classes} text={text} />
                        </Grid>
                    </Grid>
                )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Grid container alignItems="center" className={classes.gridRoot}>
                {this.renderItem()}
            </Grid>
        )
    }
}

export default withStyles(styles)(DescriptionItemPreview)
