import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    Avatar,
    Checkbox,
    FormControl,
    FormControlLabel,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    TextField,
} from '@material-ui/core'
import { getIcons } from '../../../actions/IconActions'

import Editor from '../../../components/description/DescriptionEditor'
import { STATIC_BLOCK, BUNDLE_ITEM } from '../../../consts'
import { connect } from 'react-redux'

const styles = theme => ({
    control: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },

    avatarImgProps: {
        width: 'auto',
        height: 'auto',
    },

    avatar: {
        maxWidth: '40px',
        maxHeight: '40px',
        width: 'auto',
        height: 'auto',
        borderRadius: 0,
    },
})

class Form extends React.Component {
    componentDidMount() {
        this.props.getIcons()
    }

    getTemplateOptions = () => {
        let templateOptions = this.props.descriptionItemTypes.items
            .filter(item => item.id !== STATIC_BLOCK && item.id !== BUNDLE_ITEM)
            .map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                    {name}
                </MenuItem>
            ))

        return templateOptions
    }

    getImageOptions = () => {
        const imageOptions = this.props.images.map(({ id, url }) => ({
            url,
            id,
        }))

        return imageOptions.map(({ url, id }) => (
            <MenuItem key={id} value={id}>
                {id === -1 ? (
                    <ListItemText>{url}</ListItemText>
                ) : (
                    <ListItemAvatar>
                        <Avatar src={url} />
                    </ListItemAvatar>
                )}
            </MenuItem>
        ))
    }

    handleChange = event => {
        const { name, value } = event.target
        this.props.handleChange(name, value)
    }

    descriptionChange = value => {
        this.props.handleChange('text', value)
    }

    handleCheckboxChange = (event, { name, checked }) => {
        this.handleChange(event, { name, value: checked })
    }

    render() {
        const {
            descriptionItemTypeId,
            header,
            headerIcon,
            text,
            image1,
            image2,
            notForAllegro,
            classes,
        } = this.props

        const templateOptions = this.getTemplateOptions()

        const iconOptions = this.props.icons.map(({ url }) => (
            <MenuItem key={url} value={url}>
                <ListItemAvatar>
                    <Avatar
                        className={classes.avatar}
                        imgProps={{
                            className: classes.avatarImgProps,
                        }}
                        src={url}
                    />
                </ListItemAvatar>
            </MenuItem>
        ))

        iconOptions.unshift(
            <MenuItem key="none" value="">
                -- brak ikony --
            </MenuItem>
        )

        let defaultTemplate = 1

        const iconUrl = headerIcon || ''

        return (
            <>
                <TextField
                    className={classes.control}
                    label="Szablon wyświetlania"
                    name="descriptionItemTypeId"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={descriptionItemTypeId || defaultTemplate}
                    select
                >
                    {templateOptions}
                </TextField>
                <TextField
                    className={classes.control}
                    label="Nagłowek"
                    name="header"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={header}
                />
                <TextField
                    className={classes.control}
                    label="Ikona w nagłówku (tylko na sklepie)"
                    name="headerIcon"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={iconUrl}
                    select
                >
                    {iconOptions}
                </TextField>
                <FormControl className={classes.control} fullWidth>
                    <Editor
                        placeholder="Treść..."
                        value={text}
                        onChange={value => this.descriptionChange(value)}
                        name="text"
                    />
                </FormControl>
                <TextField
                    className={classes.control}
                    label="Zdjęcie 1"
                    name="image1"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={image1 || ''}
                />
                <TextField
                    className={classes.control}
                    label="Zdjęcie 2"
                    name="image2"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={image2 || ''}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={notForAllegro}
                            onChange={this.handleCheckboxChange}
                            name="notForAllegro"
                        />
                    }
                    label="Nie wyświetlaj bloku na Allegro"
                />
            </>
        )
    }
}

const mapStateToProps = ({ presto }) => {
    return {
        icons: presto.icons.icons,
    }
}

export default withStyles(styles)(
    connect(mapStateToProps, {
        getIcons,
    })(Form)
)
