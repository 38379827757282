import React from 'react'
import MultiSelect from '../../../../components/Select'
import {withStyles} from '@material-ui/core/styles'
import _ from 'lodash'


const styles = {

}


class CategoryList extends React.Component {
    onSearch = data => {
        if (isNaN(data)) {
            if (data.length > 3) {
                this.props.onSearch({ name: data })
            }
        } else if (data.length > 0) {
            this.props.onSearch({ id: data })
        }

    }

    categoriesChange = newIds => {
        const { product } = this.props
        const oldIds = product.categories.map(({ id }) => id)

        const remove = oldIds.find(oldId => !newIds.includes(oldId))
        const add = newIds.find(newId => !oldIds.includes(newId))

        if (remove !== undefined) {
            this.props.removeCategoryFromProduct(remove, product.id)
        } else if (add !== undefined) {
            this.props.addCategoryToProduct(add, product.id)
        }
    }

    render() {
        const { product, categories } = this.props

        const categoryToOption = ({ id, name }) => ({ value: id, label: name })

        const values = product.categories.map(item => categoryToOption(item))
        const options = categories.items.map(item => categoryToOption(item))

        return (
            <div>
                <MultiSelect
                    value={values}
                    options={options}
                    onChange={this.categoriesChange}
                    onInputChange={_.debounce(this.onSearch, 400)}
                    placeholder="Wybierz kategorię"
                    label="Kategorie"
                    helperText="Podaj nazwę lub numer kategorii Allegro"
                />
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(CategoryList)
