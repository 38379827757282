import {
    ADD_ERROR,
    CLEAR_ERROR,
} from '../actions/types'


export const addError = (error) => dispatch => {
    dispatch({
        type: ADD_ERROR,
        payload: error
    })
}

export const clearError = () => dispatch => {
    dispatch({
        type: CLEAR_ERROR,
    })
}
