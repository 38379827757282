import {
    VERTO_ATTRIBUTE_FETCHING,
    VERTO_ATTRIBUTE_FETCHING_SUCCESS,
    VERTO_ATTRIBUTE_FETCHING_FAILURE,
} from '../actions/types'

const INITIAL_STATE = {
    data: null,
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VERTO_ATTRIBUTE_FETCHING:
            return {
                ...state,
                fetching: true
            }

        case VERTO_ATTRIBUTE_FETCHING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false,
            }

        case VERTO_ATTRIBUTE_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false
            }

        default:
            return state
    }
}

export default reducer
