import {
    ATTRIBUTE_FETCHING,
    ATTRIBUTE_FETCHING_SUCCESS,
    ATTRIBUTE_FETCHING_FAILURE,
    ATTRIBUTE_ADD_MAPPING,
    ATTRIBUTE_REMOVE_MAPPING
} from './types'
import Api from '../Api'


export const getAttribute = idAttribute => dispatch => {
    dispatch({ type: ATTRIBUTE_FETCHING })

    Api.getAttribute(idAttribute)
        .then(response => {
            dispatch({
                type: ATTRIBUTE_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: ATTRIBUTE_FETCHING_FAILURE,
                error,
            })
        })
}

export const addAttributeMapping = (idAttribute, vertoAttributeId) => dispatch => {
    Api.mapVertoAttributeToAllegroAttribute(idAttribute, vertoAttributeId)
        .then(response => {
            dispatch({
                type: ATTRIBUTE_ADD_MAPPING,
                payload: response.data,
            })
        })
}

export const removeAttributeMapping = (idAttribute, vertoAttributeId) => dispatch => {
    Api.removeMappingBetweenVertoAndAllegroAttribute(idAttribute, vertoAttributeId)
        .then(() => {
            dispatch({
                type: ATTRIBUTE_REMOVE_MAPPING,
                id: vertoAttributeId,
            })
        })
}
