import {
    MAGENTO_CATEGORIES_FETCH_FAILURE,
    MAGENTO_CATEGORIES_FETCH_SUCCESS,
    MAGENTO_CATEGORIES_FETCHING,
    MAGENTO_CATEGORIES_CHANGE,
    MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX,
    MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX,
    MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX,
    MAGENTO_CATEGORIES_CHANGE_FOR_ELTROX,
} from './types'

import Api from '../Api'

export const getCategoriesFromMagento = () => dispatch => {
    dispatch({
        type: MAGENTO_CATEGORIES_FETCHING,
    })

    Api.getMagentoCategories()
        .then(response => {
            dispatch({
                type: MAGENTO_CATEGORIES_FETCH_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: MAGENTO_CATEGORIES_FETCH_FAILURE,
                error,
            })
        })

    dispatch({
        type: MAGENTO_CATEGORIES_FETCHING_FOR_ELTROX,
    })

    Api.getMagentoCategoriesForEltrox()
        .then(response => {
            dispatch({
                type: MAGENTO_CATEGORIES_FETCH_SUCCESS_FOR_ELTROX,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: MAGENTO_CATEGORIES_FETCH_FAILURE_FOR_ELTROX,
                error,
            })
        })
}

export const changeMagentoCategories = categories => dispatch => {
    dispatch({
        type: MAGENTO_CATEGORIES_CHANGE,
        categories,
    })
}

export const changeMagentoCategoriesForEltrox = categories => dispatch => {
    dispatch({
        type: MAGENTO_CATEGORIES_CHANGE_FOR_ELTROX,
        categories,
    })
}
