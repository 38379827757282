import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'

import DescriptionItemPreview from './DescriptionItemPreview'

class DescriptionPreview extends React.Component {
    onDragEnd = result => {
        if (!result.destination) {
            return
        }

        this.props.reorderDescription(
            result.source.index,
            result.destination.index
        )
    }

    render() {
        const { newDescription } = this.props
        const sortedDescription = this.props.description.sort(
            (a, b) => Number(a.sort) - Number(b.sort)
        )

        return (
            <Grid className="description-preview" container>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}>
                                {sortedDescription.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={item.localId}
                                            draggableId={index}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="descriptionItem"
                                                    onClick={() =>
                                                        this.props.onClick(
                                                            item.localId
                                                        )
                                                    }
                                                    style={{
                                                        clear: 'both',
                                                        border:
                                                            '1px solid #f8fafc',
                                                    }}
                                                >
                                                    <DescriptionItemPreview
                                                        descriptionItemTypeId={
                                                            item.descriptionItemTypeId
                                                        }
                                                        header={item.header}
                                                        headerIcon={
                                                            item.headerIcon
                                                        }
                                                        text={item.text}
                                                        image1={(() => {
                                                            const image = this.props.images.find(
                                                                img =>
                                                                    img.id ===
                                                                    item.image1
                                                            )
                                                            return image
                                                                ? image.url
                                                                : null
                                                        })()}
                                                        image2={(() => {
                                                            const image = this.props.images.find(
                                                                img =>
                                                                    img.id ===
                                                                    item.image2
                                                            )
                                                            return image
                                                                ? image.url
                                                                : null
                                                        })()}
                                                        json={item.json}
                                                        staticBlock={(() => {
                                                            const staticBlock = this.props.staticBlocks.find(
                                                                i =>
                                                                    i.id ===
                                                                    item.staticBlockId
                                                            )
                                                            return staticBlock
                                                        })()}
                                                        staticBlockId={
                                                            item.staticBlockId
                                                        }
                                                        bundleItemId={
                                                            item.bundleItemId
                                                        }
                                                        bundleItem={(() => {
                                                            return this.props.bundleItems.find(
                                                                i =>
                                                                    i.id ===
                                                                    item.bundleItemId
                                                            )
                                                        })()}
                                                        showAllegro={
                                                            this.props
                                                                .showAllegro
                                                        }
                                                        youtubeLink={
                                                            item.youtubeLink
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <DescriptionItemPreview
                    descriptionItemTypeId={newDescription.descriptionItemTypeId}
                    header={newDescription.header}
                    headerIcon={newDescription.headerIcon}
                    text={newDescription.text}
                    image1={(() => {
                        const image = this.props.images.find(
                            img => img.id === newDescription.image1
                        )
                        return image ? image.url : null
                    })()}
                    image2={(() => {
                        const image = this.props.images.find(
                            img => img.id === newDescription.image2
                        )
                        return image ? image.url : null
                    })()}
                    json={newDescription.json}
                    staticBlock={(() => {
                        const staticBlock = this.props.staticBlocks.find(
                            i => i.id === newDescription.staticBlockId
                        )
                        return staticBlock
                    })()}
                    staticBlockId={newDescription.staticBlockId}
                    bundleItemId={newDescription.bundleItemId}
                    bundleItem={(() => {
                        return this.props.bundleItems.find(
                            i => i.id === newDescription.bundleItemId
                        )
                    })()}
                    showAllegro={this.props.showAllegro}
                />
            </Grid>
        )
    }
}

export default DescriptionPreview
