import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT
} from '../actions/types'

import {
    SET_USER_DATA,
    REMOVE_USER_DATA,
    USER_LOGGED_OUT,
} from '../../../auth/store/actions'

import Api from '../Api'
import { isLogged as loginCheck } from '../helpers'


export const login = (username, password) => dispatch => {
    Api.login(username, password)
        .then(response => {
            localStorage.setItem('token', response.data.token)

            dispatch({
                type: LOGIN_SUCCESS,
                token: response.data.token,
                data: response.data.data,
            })

            const user = {}

            if (response.data.data) {
                const { firstName, lastName, email, login } = response.data.data

                user.displayName = `${firstName} ${lastName}`
                user.photoURL = `https://hurton.pl/assets/avatar/${login}.png`
                user.email = email
            }

            localStorage.setItem('user', JSON.stringify(user))

            dispatch({
                type: SET_USER_DATA,
                payload: {
                    data: user
                },
            })
        })
        .catch(error => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            dispatch({
                type: LOGIN_FAILURE,
                error,
            })

            dispatch({
                type: REMOVE_USER_DATA,
            })
        })
}

export const logout = token => dispatch => {
    Api.logout(token)
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    dispatch({ type: LOGOUT })

    dispatch({
        type: USER_LOGGED_OUT,
    })
}

export const isLogged = () => dispatch => {
    if (loginCheck()) {
        return true
    } else {
        const error = 'token expired'

        dispatch({
            type: LOGIN_FAILURE,
            error
        })

        dispatch({
            type: REMOVE_USER_DATA,
            error
        })

        localStorage.removeItem('token')
        localStorage.removeItem('user')

        return false
    }
}
