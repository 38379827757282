import React from 'react'
import {
    Icon,
    Divider,
    Button,
    TextField,
    Grid,
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import Page from '../../../components/Page'


const styles = theme => ({
    grid: {
        flexGrow: 1,
    },

    control: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 1.5,
        marginRight: theme.spacing.unit,
    },

    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
})


class DeliveryForm extends React.Component {
    componentDidMount() {
        this.props.getDeliveryOptionTypes()

        const id = this.props.match.params.deliveryId

        if (id) {
            this.props.getDelivery(id)
        } else {
            this.props.clearDelivery()
        }
    }

    handleChange = (name, value) => {
        this.props.updateDelivery(name, value)
    }

    handleOptionChange = (id, value) => {
        this.props.updateDelivery('deliveryOptions', {
            id,
            data: value
        })
    }

    onBlur = (id, name, value, decimalPlaces) => {
        value = Number(value).toFixed(decimalPlaces)
        this.handleOptionChange(id, {
            [name]: value
        })
    }

    save = () => this.props.saveDelivery(this.props.data)

    onSubmit = (e) => {
        e.preventDefault()
        this.save()
    }

    render() {
        const { classes, fetching } = this.props
        const delivery = this.props.data
        const { name } = delivery

        const findOption = (id) => this.props.data.deliveryOptions[id]

        const options = this.props.deliveryOptionTypes.filter(({isActive}) => isActive).map((item, index) => {
            let firstPieceCost = -1.00
            let nextPiecesCost = -1.00
            let quantity = 0

            const values = findOption(item.id)

            if (values) {
                if (values.firstPieceCost !== undefined) {
                    firstPieceCost = values.firstPieceCost
                }

                if (values.nextPiecesCost !== undefined) {
                    nextPiecesCost = values.nextPiecesCost
                }

                if (values.quantity !== undefined) {
                    quantity = values.quantity
                }
            }

            return (
                <Grid container spacing={24} key={index} className={classes.grid}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Nazwa"
                            value={item.name}
                            readOnly
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            label="Pierwsza sztuka"
                            name="firstPieceCost"
                            value={firstPieceCost}
                            onChange={event => this.handleOptionChange(item.id, {
                                firstPieceCost,
                                nextPiecesCost,
                                quantity,
                                [event.target.name]: event.target.value

                            })}
                            onBlur={event => this.onBlur(item.id, event.target.name, event.target.value, 2)}
                            type="number"
                            step="0.01"
                            min="-1"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            label="Kolejna sztuka"
                            name="nextPiecesCost"
                            value={nextPiecesCost}
                            onChange={event => this.handleOptionChange(item.id, {
                                firstPieceCost,
                                nextPiecesCost,
                                quantity,
                                [event.target.name]: event.target.value

                            })}
                            onBlur={event => this.onBlur(item.id, event.target.name, event.target.value, 2)}
                            type="number"
                            step="0.01"
                            min="-1"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            label="Ilość"
                            name="quantity"
                            value={quantity}
                            onChange={event => this.handleOptionChange(item.id, {
                                firstPieceCost,
                                nextPiecesCost,
                                quantity,
                                [event.target.name]: event.target.value

                            })}
                            onBlur={event => this.onBlur(item.id, event.target.name, event.target.value, 2)}
                            type="number"
                            step="1"
                            min="-1"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            )
        })


        return (
            <Page
                loading={fetching}
                pageTitle={name}

                content={(
                    <>
                        <TextField
                            className={classes.control}
                            variant="outlined"
                            label="Nazwa"
                            name="name"
                            value={delivery.name}
                            onChange={event => this.handleChange(event.target.name, event.target.value)}
                            fullWidth
                        />
                        {options}
                        <Divider className={classes.divider} />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.props.saveDelivery(delivery)}
                        >
                            <Icon>save</Icon>
                            Zapisz
                        </Button>
                    </>
                )}
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(DeliveryForm)
