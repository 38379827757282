import {
    NEW_DESCRIPTION_CHANGE,
    NEW_DESCRIPTION_CLEAR,
} from './types'


export const changeNewDescription = (name, value) => dispatch => {
    dispatch({
        type: NEW_DESCRIPTION_CHANGE,
        name,
        value,
    })
}

export const clearNewDescription = () => dispatch => {
    dispatch({ type: NEW_DESCRIPTION_CLEAR })
}
