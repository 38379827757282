import React from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import BasicInfo from './AllegroBasicInformation'
import Categories from './Categories'
import Auctions from './Auctions'

import { changeTab } from '../../../actions/TabsActions'
import { ALLEGRO_TAB_CHANGE } from '../../../actions/types'

const styles = theme => ({
    tabs: {
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
})

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: '24px 8px' }}>
            {props.children}
        </Typography>
    )
}

class AllegroInformation extends React.Component {
    handleChange = event => {
        const { name, value } = event.target
        this.props.handleChange(name, value)
    }

    handleCheckboxChange = event => {
        const { name, checked: value } = event.target
        this.props.handleChange(name, value)
    }

    onTabChange = (event, value) => {
        this.props.changeTab(value)
    }

    render() {
        const {
            classes,
            product,
            deliveries,
            categories,
            tabIndex,
        } = this.props

        return (
            <div>
                <Tabs
                    className={classes.tabs}
                    value={tabIndex}
                    onChange={this.onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    fullWidth
                >
                    <Tab label="Dane podstawowe" />
                    <Tab label="Kategorie" />
                    <Tab label="Lista aukcji" />
                </Tabs>
                <Divider />
                <div className="pl-12 pr-12">
                    {tabIndex === 0 && (
                        <TabContainer>
                            <h3 className="mb-20">Dane podstawowe</h3>
                            <BasicInfo
                                product={product}
                                deliveries={deliveries}
                                handleChange={this.handleChange}
                                handleCheckboxChange={this.handleCheckboxChange}
                                handleTitleChange={this.props.handleTitleChange}
                                addTitle={this.props.addTitle}
                                removeTitle={this.props.removeTitle}
                            />
                        </TabContainer>
                    )}
                    {tabIndex === 1 && (
                        <TabContainer>
                            <Categories
                                categories={categories}
                                product={product}
                                searchItems={this.props.searchItems}
                                onSearch={this.props.searchCategories}
                                removeCategoryFromProduct={
                                    this.props.removeCategoryFromProduct
                                }
                                addCategoryToProduct={
                                    this.props.addCategoryToProduct
                                }
                                importCategory={this.props.importCategory}
                                updateAttribute={this.props.updateAttribute}
                                getAttribute={this.props.getAttribute}
                                attribute={this.props.attribute}
                                addAttributeMapping={
                                    this.props.addAttributeMapping
                                }
                                removeAttributeMapping={
                                    this.props.removeAttributeMapping
                                }
                            />
                        </TabContainer>
                    )}
                    {tabIndex === 2 && (
                        <TabContainer>
                            <h3 className="mb-20">Aukcje</h3>
                            <Auctions
                                product={this.props.product}
                                accounts={this.props.accounts}
                                saveAuctionToAllegro={
                                    this.props.saveAuctionToAllegro
                                }
                                stopAuction={this.props.stopAuction}
                                removeAuction={this.props.removeAuction}
                                activateAuction={this.props.activateAuction}
                                updateAuction={this.props.updateAuction}
                                saveAuction={this.props.saveAuction}
                                addAuction={this.props.addAuction}
                                addEmphasize={this.props.addEmphasize}
                                removeEmphasize={this.props.removeEmphasize}
                            />
                        </TabContainer>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tabIndex: state.presto.tabs.allegroTab,
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, {
        changeTab: index => changeTab(ALLEGRO_TAB_CHANGE, index),
    })(AllegroInformation)
)
