import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tree from '../../../../components/Tree'

const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})

const Categories = ({
    addCategory,
    categories,
    removeCategory,
    selectedCategories,
    title,
    classes,
}) => {
    const onCheck = categoryId => {
        if (
            selectedCategories
                .map(category => category.categoryId)
                .includes(categoryId)
        ) {
            removeCategory(categoryId)
        } else {
            const categoryExists = Object.prototype.hasOwnProperty.call(
                categories,
                categoryId
            )

            if (categoryExists) {
                addCategory(categories[categoryId])
            }
        }
    }

    const selected = selectedCategories.map(({ categoryId }) => categoryId)

    return (
        <div>
            <h3 className={classes.h}>{title}</h3>
            <div>
                <Tree
                    data={categories}
                    selected={selected}
                    onCheck={onCheck}
                    rootId={2}
                />
            </div>
        </div>
    )
}

Categories.propTypes = {
    addCategory: PropTypes.func.isRequired,
    categories: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        })
    ).isRequired,
    removeCategory: PropTypes.func.isRequired,
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.number,
            storeId: PropTypes.number,
        })
    ).isRequired,
    title: PropTypes.string,
}

Categories.defaultProp = {
    title: 'Lista kategorii',
}

export default withStyles(styles, { withTheme: true })(Categories)
