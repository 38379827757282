import {
    ATTRIBUTE_FETCHING,
    ATTRIBUTE_FETCHING_SUCCESS,
    ATTRIBUTE_FETCHING_FAILURE,
    ATTRIBUTE_ADD_MAPPING,
    ATTRIBUTE_REMOVE_MAPPING,
} from '../actions/types'


const INITIAL_STATE = {
    data: {
        id: null,
        vertoAttributes: []
    },
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    let vertoAttributes = state.data.vertoAttributes.slice()

    switch (action.type) {
        case ATTRIBUTE_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case ATTRIBUTE_FETCHING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false,
            }

        case ATTRIBUTE_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case ATTRIBUTE_ADD_MAPPING:
            if (!vertoAttributes.find(item => item.id === action.payload.id)) {
                vertoAttributes.push(action.payload)
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    vertoAttributes
                }
            }

        case ATTRIBUTE_REMOVE_MAPPING:
            return {
                ...state,
                data: {
                    ...state.data,
                    vertoAttributes: vertoAttributes.filter(item => item.id !== action.id)
                }
            }


        default:
            return state
    }
}


export default reducer
