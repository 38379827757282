import {
    STATIC_BLOCKS_FETCHING,
    STATIC_BLOCKS_FETCHING_SUCCESS,
    STATIC_BLOCKS_FETCHING_FAILURE
} from '../actions/types'


const INITIAL_STATE = {
    fetching: false,
    items: [],
    page: 0,
    pageSize: 10
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STATIC_BLOCKS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case STATIC_BLOCKS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case STATIC_BLOCKS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload.items),
                page: action.payload.page,
                pageSize: action.payload.pageSize
            }

        default:
            return state
    }
}

const prepareItems = (data) => {
    return data
}

export default reducer
