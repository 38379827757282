import {
    BUNDLE_ITEMS_FETCHING,
    BUNDLE_ITEMS_FETCHING_SUCCESS,
    BUNDLE_ITEMS_FETCHING_FAILURE,
} from '../actions/types'


const INITIAL_STATE = {
    items: [],
    page: 0,
    pageSize: 10,
    fetching: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BUNDLE_ITEMS_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case BUNDLE_ITEMS_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false,
            }

        case BUNDLE_ITEMS_FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: prepareItems(action.payload.items),
                page: action.payload.page,
                pageSize: action.payload.pageSize
            }
        default:
            return state
    }
}

const prepareItems = (data) => {
    return data
}

export default reducer
