import React from 'react'
import { SvgIcon } from '@material-ui/core'

const BaselinkerIcon = props => (
    <SvgIcon {...props}>
        <path d="M 6.6682522,0 A 2.1728842,2.1728842 0 0 0 4.4954038,2.1728483 2.1728842,2.1728842 0 0 0 6.6682522,4.3457889 2.1728842,2.1728842 0 0 0 7.4893561,4.1836719 L 8.597602,5.2919178 A 4.8745035,4.8745035 0 0 0 7.719937,8.0793714 4.8745035,4.8745035 0 0 0 8.4501559,10.639474 L 7.0253341,12.064295 A 3.7085289,3.7085289 0 0 0 5.2895653,11.632937 3.7085289,3.7085289 0 0 0 1.5810798,15.341422 3.7085289,3.7085289 0 0 0 5.2895653,19.05 3.7085289,3.7085289 0 0 0 8.9981428,15.341422 3.7085289,3.7085289 0 0 0 8.557834,13.588952 L 9.9661396,12.180739 A 4.8745035,4.8745035 0 0 0 12.594429,12.953863 4.8745035,4.8745035 0 0 0 17.46892,8.0793714 4.8745035,4.8745035 0 0 0 12.594429,3.2048799 4.8745035,4.8745035 0 0 0 9.7893517,4.0955547 L 8.6816593,2.9879548 A 2.1728842,2.1728842 0 0 0 8.841193,2.1728483 2.1728842,2.1728842 0 0 0 6.6682522,0 Z" />
    </SvgIcon>
)

export default BaselinkerIcon
