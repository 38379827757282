import React from 'react'
import { connect } from 'react-redux'
import { getCategoriesFromMagento } from '../../actions/MagentoCategoriesActions'
import {
    getCategoriesWithRealStock,
    saveRealStockInCategory,
} from '../../actions/RealStockInCategoriesActions'
import { withStyles } from '@material-ui/core/styles'
import Page from '../../components/Page'

import Categories from '../product/components/magentoCategories/CategoryList'

const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})

class CategoriesWithRealStock extends React.Component {
    componentDidMount() {
        const {
            getCategoriesWithRealStock,
            getCategoriesFromMagento,
        } = this.props

        getCategoriesFromMagento()
        getCategoriesWithRealStock()
    }

    onAddCategoryClick = category => {
        this.props.saveRealStockInCategory({
            id: category.id,
            realStock: true,
        })
    }

    onRemoveCategoryClick = categoryId => {
        this.props.saveRealStockInCategory({
            id: categoryId,
            realStock: false,
        })
    }

    render() {
        const {
            realStockInCategories,
            magentoCategories: categories,
        } = this.props

        const title = 'Realne stany magazynowe na aukcjach'

        const selectedCategories = Object.values(realStockInCategories.items)
            .filter(({ realStock }) => realStock === true)
            .map(({ id }) => id)

        return (
            <Page
                pageTitle={title}
                loading={realStockInCategories.loading}
                content={
                    <>
                        {Object.values(categories).length > 0 && (
                            <>
                                <Categories
                                    addCategory={this.onAddCategoryClick}
                                    removeCategory={this.onRemoveCategoryClick}
                                    selectedCategories={selectedCategories}
                                    categories={categories}
                                />
                            </>
                        )}
                    </>
                }
            ></Page>
        )
    }
}

const mapStateToProps = ({ presto: state }) => {
    const { realStockInCategories, magentoCategoriesForEltrox } = state

    return {
        realStockInCategories,
        magentoCategories: magentoCategoriesForEltrox,
    }
}

export default connect(mapStateToProps, {
    getCategoriesFromMagento,
    getCategoriesWithRealStock,
    saveRealStockInCategory,
})(CategoriesWithRealStock)
