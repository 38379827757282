import {
    PRODUCT_ADD_IMAGE,
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_REMOVE_IMAGE,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_SET_MAIN_IMAGE,
    TOGGLE_NOT_FOR_GALLERY,
    PRODUCT_SET_IMAGE_SORT,
    PRODUCT_SET_ALLEGRO_MINI,
} from '../../actions/types'

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    let images = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareImages(action.payload)

        case PRODUCT_REMOVE_IMAGE:
            if (action.imageId === null) {
                return state
            }

            return state.filter(image => image.id !== action.imageId)

        case PRODUCT_ADD_IMAGE:
            images.push(action.payload)
            return images

        case PRODUCT_SET_MAIN_IMAGE:
            images = state.map((item, index) => {
                if (action.imageId === item.id) {
                    return {
                        ...item,
                        isMain: true,
                    }
                } else if (action.imageId === null && index === action.index) {
                    return {
                        ...item,
                        isMain: true,
                    }
                } else {
                    return {
                        ...item,
                        isMain: false,
                    }
                }
            })

            return images

        case PRODUCT_SET_ALLEGRO_MINI:
            images = state.map((item, index) => {
                if (action.imageId === item.id) {
                    return {
                        ...item,
                        isAllegroMini: true,
                    }
                } else if (action.imageId === null && index === action.index) {
                    return {
                        ...item,
                        isAllegroMini: true,
                    }
                } else {
                    return {
                        ...item,
                        isAllegroMini: false,
                    }
                }
            })

            return images

        case TOGGLE_NOT_FOR_GALLERY:
            images = state.map((item, index) => {
                if (action.imageId === item.id) {
                    return {
                        ...item,
                        notForGallery: !item.notForGallery,
                    }
                } else if (action.imageId === null && index === action.index) {
                    return {
                        ...item,
                        notForGallery: !item.notForGallery,
                    }
                } else {
                    return item
                }
            })

            return images

        case PRODUCT_SET_IMAGE_SORT:
            images = state.map(item => {
                if (action.imageId === item.id) {
                    return {
                        ...item,
                        sort: action.sort,
                    }
                } else {
                    return item
                }
            })

            return images

        default:
            return state
    }
}

const prepareImages = ({ images }) => {
    if (!images) {
        return []
    }

    return images.map(
        ({ id, isMain, url, notForGallery, sort, isAllegroMini }) => ({
            id,
            isMain,
            url,
            notForGallery,
            sort,
            isAllegroMini,
        })
    )
}

export default reducer
