import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tree from '../../../../components/Tree'

const styles = theme => ({
    h: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
})

const Categories = ({
    updateCategories,
    categories,
    selectedCategories,
    title,
    classes,
}) => {
    const onCheck = categoryId => {
        if (
            selectedCategories
                .map(category => category.categoryId)
                .includes(categoryId)
        ) {
            const newCategories = selectedCategories.filter(
                category => category.categoryId !== categoryId
            )
            updateCategories(newCategories)
        } else {
            const categoryExists = Object.prototype.hasOwnProperty.call(
                categories,
                categoryId
            )

            if (categoryExists) {
                const newCategories = [
                    ...selectedCategories,
                    {
                        categoryId,
                    },
                ]

                updateCategories(newCategories)
            }
        }
    }

    const selected = selectedCategories.map(({ categoryId }) => categoryId)

    return (
        <div>
            <h3 className={classes.h}>{title}</h3>
            <div>
                <Tree
                    data={categories}
                    selected={selected}
                    onCheck={onCheck}
                    rootId={2}
                />
            </div>
        </div>
    )
}

Categories.propTypes = {
    updateCategories: PropTypes.func.isRequired,
    categories: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.number,
        })
    ).isRequired,
    title: PropTypes.string,
}

Categories.defaultProp = {
    title: 'Lista kategorii',
}

export default withStyles(styles, { withTheme: true })(Categories)
