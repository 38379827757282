import {
    WARRANTIES_FETCHING,
    WARRANTIES_FETCHING_FAILURE,
    WARRANTIES_FETCHING_SUCCESS,
} from '../actions/types'

const INITIAL_STATE = {
    fetching: false,
    items: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WARRANTIES_FETCHING:
            return {
                ...state,
                fetching: true,
            }

        case WARRANTIES_FETCHING_SUCCESS:
            return {
                ...state,
                items: action.payload,
                fetching: false
            }

        case WARRANTIES_FETCHING_FAILURE:
            return {
                ...state,
                fetching: false
            }

        default:
            return state
    }
}

export default reducer