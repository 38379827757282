import * as reduxModule from 'redux'
import {applyMiddleware, compose, createStore} from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createReducer from 'store/reducers'
import thunk from 'redux-thunk'
import history from './history'

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT'

const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose

const authMiddleware = store => next => action => {
    const result = next(action)
    const pathname = store.getState().router.location.pathname
    const isLogged = store.getState().presto.auth.isLogged

    if (pathname !== '/login' && !isLogged) {
        history.replace('/login')
    }

    return result
}

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(authMiddleware),
    applyMiddleware(thunk),
)

const store = createStore(
    createReducer({ router: connectRouter(history) }),
    enhancer
)

store.asyncReducers = {}


export const dispatch = store.dispatch

export const injectReducer = (key, reducer) => {
    if ( store.asyncReducers[key] )
    {
        return
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(createReducer(store.asyncReducers))
    return store
}

export default store
